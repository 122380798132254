import { useState } from 'react'

export const useToggleModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = (e: any) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  return {
    isOpen,
    toggleOpen,
  }
}
