import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { TooltipProvider } from '@/shared/ui/Tooltip'
import { BottomNavigationBar } from '@/widgets/BottomNavigationBar'
import { PlatformHeader } from '@/widgets/Headers'
import { useSidebar } from '@/widgets/Sidebar'
import { SidebarProvider } from '@/widgets/Sidebar/model/useSidebar'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export const PlatformLayoutContent = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')
  const { closeSidebar } = useSidebar()
  useEffect(() => {
    closeSidebar()
  }, [closeSidebar])

  if (isMobile)
    return (
      <div
        className="min-w-screen relative flex h-[100svh] flex-col items-stretch
          justify-start"
      >
        <div
          className="relative flex h-[100svh] w-screen flex-1 flex-col items-stretch
            justify-start"
        >
          <Outlet />
        </div>
        <BottomNavigationBar />
      </div>
    )

  return (
    <div className="flex min-h-screen flex-col">
      <PlatformHeader />
      <Outlet />
    </div>
  )
}

export const PlatformLayout = () => {
  return (
    <SidebarProvider>
      <TooltipProvider>
        <PlatformLayoutContent />
      </TooltipProvider>
    </SidebarProvider>
  )
}
