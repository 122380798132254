import { DocumentsToggleFavoritesPageButton } from '@/features/document'
import {
  DesktopRangePicker,
  DocumentStatusFilterMenuDesktop,
  SortModeFilterMenuDesktop,
} from '@/features/filters'
import { documentsApi } from '@/shared/api/documents'
import { useTypedSelector } from '@/shared/store'
import { Typography } from '@/shared/ui'
import { useLocation } from 'react-router-dom'

export const WidgetDocumentFilters = () => {
  const totalDocuments = useTypedSelector((state) => {
    return documentsApi.endpoints.getDocuments.select({})(state).data
      ?.meta.total
  })

  const location = useLocation()
  const isFavoriteRoute = location.pathname === '/documents/favorite'

  const formattedTotalDocuments = totalDocuments
    ? new Intl.NumberFormat('ru-RU').format(totalDocuments)
    : undefined
  return (
    <div className="shrink-0 grow self-stretch border-b border-[#F0F0F0]">
      <div className="flex flex-nowrap items-center justify-between gap-3 px-3 py-2">
        <Typography
          variant={'label'}
          className="font-semibold text-base-400"
        >
          {totalDocuments
            ? `Найдено документов: ${formattedTotalDocuments}`
            : ''}
        </Typography>
        <div className="inline-flex shrink-0 grow items-center justify-end gap-3">
          <DesktopRangePicker
            totalDocuments={formattedTotalDocuments}
          />
          <DocumentStatusFilterMenuDesktop
            totalDocuments={formattedTotalDocuments}
          />
          <SortModeFilterMenuDesktop
            totalDocuments={formattedTotalDocuments}
          />
          <DocumentsToggleFavoritesPageButton
            isFavoriteRoute={isFavoriteRoute}
          />
        </div>
      </div>
    </div>
  )
}
