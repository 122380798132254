import { Typography } from '@/shared/ui'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { cn } from '@/shared/utils/common'
import { Arrow } from '@radix-ui/react-menubar'

interface DocumentPanelLanguageMenuProps {
  languages: string[] | undefined
  currentLanguage: string
  handleChange: (value: string) => void
}

export const DocumentPanelLanguageMenu = ({
  languages,
  currentLanguage,
  handleChange,
}: DocumentPanelLanguageMenuProps) => {
  return (
    <Menubar>
      <MenubarMenu>
        <MenubarTrigger
          className="flex w-full items-center gap-3 self-stretch rounded-lg p-2
            hover:cursor-pointer hover:bg-slate-100"
        >
          <Typography>
            {currentLanguage === 'KK'
              ? 'KZ'
              : currentLanguage.toUpperCase()}
          </Typography>
          <Typography className="text-nowrap font-normal">
            Сменить язык
          </Typography>
        </MenubarTrigger>
        <MenubarContent
          side={'right'}
          collisionPadding={{ right: -20, top: 10, bottom: 15 }}
          className="max-w-[52px !important] shadow-shadow4-popover-libra
            pointer-events-auto z-50 z-[101] flex min-w-0 flex-col items-stretch
            rounded-[16px] bg-white p-2"
          align="start"
          alignOffset={-20}
          sideOffset={-5}
        >
          <Arrow fill="#fff" className="h-2 w-4" />
          {languages &&
            languages.map((language) => {
              return (
                <MenubarItem
                  key={language}
                  className="w-[40px] cursor-pointer rounded-lg hover:bg-base-100"
                  onClick={() => handleChange(language)}
                >
                  <Typography variant={'label'}>
                    {language === 'KK'
                      ? 'KZ'
                      : language.toUpperCase()}
                  </Typography>
                </MenubarItem>
              )
            })}
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}
