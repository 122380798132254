import Cookies from 'js-cookie'
import { useEffect } from 'react'

import { createSocketConnection } from './socketService'

interface Subscription {
  /** The event name to listen for. e.g. '.ai-assist.responded' */
  event: string
  /** Function to call whenever this event fires. */
  callback: (payload: any) => void
}

interface UseSocketOptions {
  /** The private channel name to join. e.g. 'chat.123' */
  channel: string
  /** An array of events (and handlers) to listen to on that channel. */
  subscriptions: Subscription[]
}

/**
 * A single hook for managing multiple real-time events on a particular channel.
 */
export const useSocket = ({
  channel,
  subscriptions,
}: UseSocketOptions) => {
  const token = Cookies.get('access_token')

  useEffect(() => {
    if (!token) return
    // Ensure the global `window.Echo` client is ready:
    createSocketConnection(token)

    // Join the given channel privately
    const echoChannel = window.Echo.private(channel)

    // Register all event listeners provided in `subscriptions`
    subscriptions.forEach(({ event, callback }) => {
      echoChannel.listen(event, (payload: any) => {
        callback(payload)
      })
    })

    // Cleanup: leave the channel when this component unmounts or deps change
    return () => {
      window.Echo.leaveChannel(channel)
    }
  }, [token, channel, subscriptions])
}
