// src/AppRoutes.tsx
import { AssistantLayout } from '@/layout/AssistantLayout'
import { CourtBaseLayout } from '@/layout/CourtBaseLayout'
import { DocumentsLayout } from '@/layout/DocumentsLayout'
import { PlatformLayout } from '@/layout/PlatformLayout'
import { ChatByIdPage } from '@/pages/ChatByIdPage'
import { ChatFavoritesPage } from '@/pages/ChatFavoritesPage'
import { CourtBaseByIdPage } from '@/pages/CourtBaseByIdPage'
import { CourtBasePage } from '@/pages/CourtBasePage'
import { DocumentByIdPage } from '@/pages/DocumentByIdPage'
import { DocumentByIdVersionsPage } from '@/pages/DocumentByIdVersionsPage'
import { DocumentsFavoritePage } from '@/pages/DocumentsFavoritePage'
import { DocumentsPage } from '@/pages/DocumentsPage'
import { HomePage } from '@/pages/HomePage'
import { ProfilePage } from '@/pages/ProfilePage'
import { PasswordRecoveryPage } from '@/pages/ResetPasswordPage'
import { SettingsPage } from '@/pages/SettingsPage'
import { SigninPage } from '@/pages/SigninPage'
import { SignupPage } from '@/pages/SignupPage'
import { userApi } from '@/shared/api/user'
import store from '@/shared/store'
import {
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  Route,
  RouterProvider,
} from 'react-router-dom'

import ProtectedRoute from './ProtectedRoute'

export const AppRoutes = () => {
  const authRedirectLoader = async () => {
    try {
      const result = await store
        .dispatch(userApi.endpoints.getUser.initiate())
        .unwrap()

      if (result.success) {
        return redirect('/')
      }
      return null
    } catch (error: any) {
      if (error?.status === 401) {
        return null
      }
      return null //TODO: Логика таких кейсов должна быть обсуждена
    }
  }

  const userLoader = async () => {
    try {
      const result = await store
        .dispatch(userApi.endpoints.getUser.initiate())
        .unwrap()

      if (result.success) {
        return { user: result.user }
      }
      return null
    } catch (error: any) {
      if (error?.status === 401) {
        return redirect('/signin')
      }
      return redirect('/signin') // TODO: Логика таких кейсов должна быть обсуждена
    }
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<PlatformLayout />}>
          <Route element={<AssistantLayout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
              loader={userLoader}
            />
            <Route
              path="chats/:chat_id"
              element={
                <ProtectedRoute>
                  <ChatByIdPage />
                </ProtectedRoute>
              }
              loader={userLoader}
            />
            <Route
              path="chats/favorites"
              element={
                <ProtectedRoute>
                  <ChatFavoritesPage />
                </ProtectedRoute>
              }
              loader={userLoader}
            />
          </Route>

          <Route
            path="/documents"
            element={
              <ProtectedRoute>
                <DocumentsLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<DocumentsPage />} />
            <Route
              path="favorite"
              element={<DocumentsFavoritePage />}
            />
            <Route path=":id">
              <Route index element={<DocumentByIdPage />} />
              <Route
                path="versions"
                element={<DocumentByIdVersionsPage />}
              />
            </Route>
          </Route>

          <Route path="/court-base" element={<CourtBaseLayout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <CourtBasePage />
                </ProtectedRoute>
              }
            />
            <Route path=":id" element={<CourtBaseByIdPage />} />
          </Route>
        </Route>

        {/* Other top-level routes */}
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route
          path="/signin"
          element={<SigninPage />}
          loader={authRedirectLoader}
        />
        <Route
          path="/signup"
          element={<SignupPage />}
          loader={authRedirectLoader}
        />
        <Route
          path="/password-recovery/:token"
          element={<PasswordRecoveryPage />}
        />
      </>
    )
  )

  // Use RouterProvider to provide the router to the app
  return <RouterProvider router={router} />
}
