import { IDocument } from '@/entities/document'
import { useFavoriteDocuments } from '@/entities/document/models/useFavoriteDocuments'
import { DocumentItemMobile } from '@/entities/document/ui/DocumentItemMobile'
import { DocumentItemMobileSkeleton } from '@/entities/document/ui/DocumentItemMobileSkeleton'
import IconNotFound from '@/shared/assets/icons/logo_not_found.svg?react'
import { Typography } from '@/shared/ui'

const ErrorState = () => (
  <div
    className="flex flex-col items-stretch justify-start rounded-b-3xl border-x-[1px]
      border-b-[1px] border-t-0 bg-transparent py-20"
    style={{
      boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
    }}
  >
    <div className="my-20 flex flex-col items-stretch justify-center gap-8">
      <IconNotFound className="self-center drop-shadow-lg" />
      <div className="flex flex-col items-center justify-center">
        <Typography>
          По запросу не найдено ни одного документа.
        </Typography>
        <Typography>
          Попробуйте написать запрос иначе и повторить поиск.
        </Typography>
      </div>
    </div>
  </div>
)

const LoadingState = () => (
  <div className="flex flex-col items-stretch justify-start gap-3 pb-12">
    {Array.from({ length: 3 }).map((_, index) => (
      <DocumentItemMobileSkeleton key={index} />
    ))}
  </div>
)

const EmptyState = () => (
  <div
    className="flex shrink-0 grow flex-col items-center justify-center self-stretch
      py-20"
  >
    <div className="flex flex-col items-stretch justify-center gap-8">
      <IconNotFound className="self-center drop-shadow-lg" />
      <div className="flex flex-col items-center justify-center">
        <Typography>
          По запросу не найдено ни одного документа.
        </Typography>
        <Typography>
          Попробуйте написать запрос иначе и повторить поиск.
        </Typography>
      </div>
    </div>
  </div>
)

interface DocumentsListProps {
  documents: IDocument[]
}

const DocumentsList = ({ documents }: DocumentsListProps) => (
  <div
    className="flex flex-col items-stretch justify-start gap-4 rounded-b-3xl
      border-b-[1px] border-t-0 bg-transparent pb-5"
    style={{ overflow: 'visible' }}
  >
    {documents.map((doc, index) => {
      const isLastItem = index === documents.length - 1
      return (
        <DocumentItemMobile
          isFavorite
          key={doc.id}
          documentItem={doc}
          isLastDocumentItem={isLastItem}
        />
      )
    })}
  </div>
)

export const DocumentBaseFavoritesContainerMobile = () => {
  const { data, error, isLoading } = useFavoriteDocuments({
    isMobile: true,
  })

  if (error) {
    return <ErrorState />
  }

  if (isLoading || !data) {
    return <LoadingState />
  }

  if (!data.documents || data.documents.length === 0) {
    // No documents found
    return (
      <div
        className="flex flex-col items-stretch justify-start gap-4 rounded-b-3xl
          border-b-[1px] border-t-0 bg-transparent"
        style={{ overflow: 'visible' }}
      >
        <EmptyState />
      </div>
    )
  }

  return <DocumentsList documents={data.documents} />
}
