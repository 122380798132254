import { DocumentContentsProvider } from '@/features/document/contexts/DocumentContentContext'
import { WidgetDocumentContents } from '@/widgets/DocumentByIdPage/WidgetDocumentContents'

export const DocumentByIdPage = () => {
  return (
    <DocumentContentsProvider>
      <WidgetDocumentContents />
    </DocumentContentsProvider>
  )
}
