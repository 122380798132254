import { DocumentPanelLanguageMenu } from '@/entities/document'
import { DocumentData } from '@/entities/document/types'
import IconFavourite from '@/shared/assets/icons/icon_bookmark_dark.svg?react'
import IconDownloadOutlined from '@/shared/assets/icons/icon_download_dark.svg?react'
import IconExport from '@/shared/assets/icons/icon_export.svg?react'
import IconFolderPlusMobile from '@/shared/assets/icons/icon_folder_plus_mobile.svg?react'
import IconInfoOutlined from '@/shared/assets/icons/icon_info_dark.svg?react'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { Arrow } from '@radix-ui/react-menubar'
import { ReactNode } from 'react'

interface ChatAiFileDrawerMenuProps {
  children: ReactNode
  onInfoClick: () => void
  onToggleFavoriteClick: () => void
  data: DocumentData | undefined
  handleChangePanelLanguages: (value: string) => void
}

export const ChatAiFileDrawerMenu = ({
  children,
  onInfoClick,
  onToggleFavoriteClick,
  data,
  handleChangePanelLanguages,
}: ChatAiFileDrawerMenuProps) => {
  return (
    <>
      <Menubar className="flex items-center justify-stretch self-stretch border-none">
        <MenubarMenu>
          <MenubarTrigger
            className="flex w-full items-center justify-start rounded-lg bg-white
              hover:cursor-pointer hover:bg-base-100 data-[state=open]:bg-base-100"
          >
            {children}
          </MenubarTrigger>
          <MenubarContent
            className="z-[100] flex flex-col items-stretch rounded-[20px] bg-white p-2
              shadow-shadow2"
            align="start"
            alignOffset={-10}
            sideOffset={-3}
          >
            <Arrow fill="#FCFDFD" className="h-2 w-5" />

            <MenubarItem
              inset={true}
              className="p-0"
              onClick={onInfoClick}
            >
              <div
                className="flex w-full items-center gap-3 rounded-lg p-2 hover:cursor-pointer
                  hover:bg-slate-100"
              >
                <IconInfoOutlined className="h-5 w-5" />
                <Typography className="text-nowrap font-normal">
                  О документе
                </Typography>
              </div>
            </MenubarItem>
            <MenubarItem
              inset={true}
              className="p-0"
              onClick={onInfoClick}
            >
              <div
                className="flex w-full items-center gap-3 rounded-lg p-2 hover:cursor-pointer
                  hover:bg-slate-100"
              >
                <IconExport className="h-5 w-5" />
                <Typography className="text-nowrap font-normal">
                  В новой вкладке
                </Typography>
              </div>
            </MenubarItem>

            <DocumentPanelLanguageMenu
              handleChange={handleChangePanelLanguages}
              languages={data?.locale.available}
              currentLanguage="ru"
            />

            <MenubarItem
              onClick={onToggleFavoriteClick}
              inset={true}
              asChild
              className="p-0"
            >
              <div
                className="flex items-center gap-3 self-stretch rounded-lg p-2
                  hover:cursor-pointer hover:bg-slate-100"
              >
                <IconFavourite className="h-5 w-5" />
                <Typography className="text-nowrap font-normal">
                  В избранное
                </Typography>
              </div>
            </MenubarItem>

            <MenubarItem inset={true} asChild className="p-0">
              <div
                className="flex items-center gap-3 self-stretch rounded-lg p-2 pb-3
                  hover:cursor-pointer hover:bg-slate-100"
              >
                <IconDownloadOutlined className="h-5 w-5" />
                <Typography className="text-nowrap font-normal">
                  Скачать
                </Typography>
              </div>
            </MenubarItem>

            <MenubarItem className="p-0" asChild>
              <div
                className="botder-base-100 flex items-center gap-3 self-stretch rounded-lg
                  border-t-[1px] p-2 pt-3 hover:cursor-pointer hover:bg-slate-100"
              >
                <IconFolderPlusMobile />
                <Typography variant={'body'}>В папку</Typography>
              </div>
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </>
  )
}
