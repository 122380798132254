import { ButtonCopyAiResponse } from '@/features/chat/ui/ButtonCopyAiResponse'
import { ButtonToggleFavoriteMessage } from '@/features/chat/ui/ButtonToggleFavoriteMessage'
import IconCodexGray from '@/shared/assets/icons/icon_codex_gray.svg?react'
import IconLess from '@/shared/assets/icons/icon_screen_less.svg?react'
import IconMore from '@/shared/assets/icons/icon_screen_more.svg?react'
import { Button } from '@/shared/ui'
import { IconButton } from '@/shared/ui/IconButton'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { HTMLAttributes, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'

import { IChatMessage } from '../../types'
import { MobileChatAiFile } from '../MobileChatAiFile'

interface MobileChatAiResponseProps
  extends HTMLAttributes<HTMLDivElement> {
  response: IChatMessage
  isLastResponse: boolean
}

export const MobileChatAiResponse = ({
  response,
  isLastResponse,
  ...props
}: MobileChatAiResponseProps) => {
  const { chat_id } = useParams()
  const [showAllDocuments, setShowAllDocuments] = useState(false)

  return (
    <div
      className="group flex flex-col items-stretch justify-start self-stretch
        rounded-lg"
      {...props}
    >
      {/* Ai message */}
      <div className="flex items-start justify-start gap-4">
        <IconButton
          className="flex items-center justify-center rounded-full border-[1px]
            border-base-200 bg-white p-[6px]"
        >
          <IconCodexGray />
        </IconButton>
        <Typography className="whitespace-normal break-words">
          <ReactMarkdown className={'markdown -mt-[18px]'}>
            {response.message ?? ''}
          </ReactMarkdown>{' '}
        </Typography>
      </div>
      {/* Ai files */}
      <div className="flex flex-col items-stretch justify-start gap-4">
        {response.documents && (
          <div className="flex flex-col items-stretch justify-start gap-4">
            {response.documents.length > 0 && (
              <>
                {/* Show either all documents if there are 5 or fewer, or the first 5 if more */}
                {response.documents
                  .slice(
                    0,
                    showAllDocuments ? response.documents.length : 5
                  )
                  .map((file) => {
                    const fileKey = `${file.id + response.id}`
                    return (
                      <MobileChatAiFile file={file} key={fileKey} />
                    )
                  })}
              </>
            )}
          </div>
        )}
        <div
          className={cn(
            'flex items-center justify-start gap-4 self-stretch'
          )}
        >
          {response.documents && response.documents.length > 5 && (
            <Button
              onClick={() => setShowAllDocuments(!showAllDocuments)}
              className={cn(
                `flex h-[32px] items-center gap-2 rounded-3xl border-[1px]
                border-base-200 py-[6px] pl-4 pr-6 text-base-950 shadow-shadow8
                hover:shadow-shadow8`,
                showAllDocuments ? 'bg-base-100' : 'bg-white'
              )}
            >
              <IconButton>
                {showAllDocuments ? <IconLess /> : <IconMore />}
              </IconButton>
              <Typography variant={'label'}>
                Показать {showAllDocuments ? 'меньше' : 'больше'}
              </Typography>
            </Button>
          )}
          <div className="flex gap-1">
            <ButtonCopyAiResponse
              text={response.message ? response.message : ''}
            />
            <ButtonToggleFavoriteMessage
              chatId={chat_id ? chat_id : response.chat?.id}
              isFavorite={response.is_favorite}
              messageId={String(response.id)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
