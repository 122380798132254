// src/components/Signup.tsx
import {
  SignupCompany,
  SignUpCompanyOTP,
  SignupPersonal,
} from '@/entities/signup'
import Logo from '@/shared/assets/icons/logo.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { resetSignupCompanySteps } from '@/shared/store/slices/auth/signupCompanySlice'
import { resetSignupPersonalSteps } from '@/shared/store/slices/auth/signupPersonalSlice'
import { Card, CardHeader, CardTitle } from '@/shared/ui/Card'
import { Tabs, TabsList, TabsTrigger } from '@/shared/ui/Tabs'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const WidgetSignup = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialSignType = searchParams.get('type') || 'personal'
  const [signType, setSignType] = useState(initialSignType)
  const isTablet = useMediaQuery('(max-width: 768px)')
  const step = useTypedSelector((state) => state.signupCompany.step)
  const dispatch = useAppDispatch()

  const renderStep = () => {
    if (signType === 'personal') {
      return <SignupPersonal isMobile={isTablet} />
    } else if (signType === 'company') {
      if (step === 1) {
        return <SignupCompany isTablet={isTablet} />
      } else if (step === 2) {
        return <SignUpCompanyOTP />
      }
    }
    return null
  }

  return (
    <div
      className="flex flex-col items-center justify-start gap-4 overflow-y-scroll py-6
        xl:gap-8"
    >
      <div
        className={cn(
          'flex h-[50px] w-[360px] flex-col sm:w-[440px]',
          signType === 'company'
            ? 'md:w-[728px] lg:w-[736px] xl:w-[800px]'
            : 'md:w-[450px] xl:w-[544px]'
        )}
      >
        <Logo className="self-start xl:self-center" />
      </div>

      <Card
        className={cn(
          `relative flex w-[360px] flex-col gap-8 rounded-[40px] px-4 py-8
          shadow-shadow2 sm:w-[440px] sm:p-10 lg:p-8`,
          signType === 'company' && step === 1
            ? 'md:w-[728px] lg:w-[736px] xl:w-[800px]'
            : 'md:w-[450px] xl:w-[544px]'
        )}
      >
        <CardHeader
          className={cn(
            'flex flex-col items-center justify-start gap-6',
            step === 2 && signType === 'company' && 'mt-6'
          )}
        >
          <CardTitle className="flex w-full items-center justify-between">
            <Typography
              variant="heading5"
              className="grow text-center"
            >
              Создание аккаунта
            </Typography>
          </CardTitle>
          <Tabs
            value={signType}
            onValueChange={(value) => {
              setSignType(value)
              dispatch(resetSignupPersonalSteps())
              dispatch(resetSignupCompanySteps())

              // Update the searchParams
              searchParams.set('type', value)
              setSearchParams(searchParams)
            }}
          >
            <TabsList className="w-full bg-base-100">
              <TabsTrigger value="personal">
                Физическое лицо
              </TabsTrigger>
              <TabsTrigger value="company">
                Юридическое лицо
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </CardHeader>
        {renderStep()}
      </Card>
    </div>
  )
}
