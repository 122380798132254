import { useGetDocumentChaptersQuery } from '@/shared/api/documents'
import { toast } from '@/shared/ui/Toast/useToast'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export const useDocumentChaptersAction = () => {
  const { id: documentId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [isDocumentChaptersOpen, setIsDocumentContentsOpen] =
    useState(false)

  const toggleDocumentChaptersOpen = () => {
    setIsDocumentContentsOpen(!isDocumentChaptersOpen)
  }

  const {
    data: chaptersData,
    error: chaptersError,
    isFetching: isFetchingChapters,
    isLoading: isLoadingChapters,
  } = useGetDocumentChaptersQuery({ id: Number(documentId) })

  const handleDocumentChapterClick = (chapterId: number) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('anchorId', chapterId.toString())
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  if (chaptersError) {
    toast({
      variant: 'error',
      title: 'Что-то пошло не так',
      description:
        'Не удалось загрузить содержание документа. Попробуйте обновить страницу.',
    })
  }

  return {
    isDocumentChaptersOpen,
    toggleDocumentChaptersOpen,
    handleDocumentChapterClick,
    chaptersData,
    chaptersError,
    isFetchingChapters,
    isLoadingChapters,
  }
}
