import IconCodexGray from '@/shared/assets/icons/icon_codex_gray.svg?react'
import IconErrorRed from '@/shared/assets/icons/icon_error_red.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

interface ChatAiErrorProps {
  message?: string
  description?: React.ReactNode
  actionLabel?: string
  actionHref?: string
  icon?: React.ReactNode
  onActionClick?: () => void
  className?: string
}

export const ChatAiError = ({
  message = 'Произошла ошибка',
  description = (
    <>
      Проверьте свое подключение к интернету и повторите запрос. Если
      ошибка сохраняется, свяжитесь с нами через наш центр поддержки
      клиентов по адресу{' '}
      <a
        className="text-additional-light-blue-2"
        href="https://help.oxyma.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        help.oxyma.com
      </a>
    </>
  ),
  icon = <IconErrorRed />,
  actionLabel,
  actionHref,
  onActionClick,
  className,
}: ChatAiErrorProps) => {
  return (
    <div className={cn('group flex items-stretch gap-4', className)}>
      <div className="max-w-8">
        <IconButton
          className="flex items-center justify-center rounded-full border-[1px]
            border-base-200 bg-white p-[6px]"
        >
          <IconCodexGray />
        </IconButton>
      </div>
      <div
        className="relative flex basis-[560px] items-stretch justify-start gap-3
          rounded-[16px] border border-[#FBC3C3] p-4 pl-[46px]"
      >
        <div className="absolute left-[14px] top-[14px]">{icon}</div>
        <div className="flex flex-col items-stretch justify-start gap-1">
          <Typography variant="body" className="font-semibold">
            {message}
          </Typography>
          <Typography
            variant="body"
            className="whitespace-normal break-words font-normal"
          >
            {description}
          </Typography>
          {actionLabel && (
            <a
              href={actionHref}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onActionClick}
              className="font-medium text-additional-light-blue-2"
            >
              {actionLabel}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
