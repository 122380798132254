// src/components/SigninPersonal.tsx
import { Button } from '@/shared/ui/Button'
import { CardContent, CardFooter } from '@/shared/ui/Card'
import { Input } from '@/shared/ui/Input'
import { Typography } from '@/shared/ui/Typography'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { Link } from 'react-router-dom'

import { useSignin } from '../../context/SigninContext'

export const SigninPersonal = ({
  isMobile,
}: {
  isMobile: boolean
}) => {
  const {
    individualData,
    setPersonalData,
    handlePersonalSignin,
    handleSendOtp,
    codeSent,
    loading,
    canResend,
    timeLeft,
  } = useSignin()

  const methods = useForm({
    defaultValues: individualData,
  })

  const phone = methods.watch('phone') || ''

  const handleCodeSent = async (e: React.FormEvent) => {
    e.preventDefault()
    handleSendOtp(phone)
  }

  const onSubmit = methods.handleSubmit(async (data) => {
    setPersonalData(data)
    await handlePersonalSignin(data)
  })

  return (
    <FormProvider {...methods}>
      {!codeSent ? (
        <form onSubmit={handleCodeSent} className="w-full">
          <CardContent className="flex flex-col gap-4">
            <Controller
              name="phone"
              control={methods.control}
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <InputMask
                  {...field}
                  mask="(999) 999-99-99"
                  prefix="+7"
                  placeholder="(___) ___-__-__"
                >
                  <Input
                    type="tel"
                    label="Номер телефона"
                    error={methods.formState.errors.phone?.message}
                  />
                </InputMask>
              )}
            />
          </CardContent>
          <CardFooter className="mt-8 flex flex-col items-center justify-start gap-5 lg:gap-8">
            <Button
              variant="primary"
              className="w-full"
              type="submit"
              disabled={
                !phone ||
                phone.replace(/[\s()-/_]/g, '').length !== 10 ||
                loading
              }
            >
              {isMobile
                ? 'Отправить SMS-код'
                : 'Отправить SMS-код подтверждения'}
            </Button>
            <Typography className="text-center text-xs text-gray-500">
              У вас нет аккаунта?{' '}
              <Link
                to="/signup"
                className="font-medium text-base-900 underline"
              >
                Создать новый аккаунт
              </Link>
            </Typography>
            <Typography
              variant={'subtitle'}
              className="self-stretch text-center font-normal text-[#9EA2AA]"
            >
              Используя CODEX, Вы соглашаетесь с{' '}
              <span className={'underline'}>
                Политикой конфиденциальности
              </span>
            </Typography>
          </CardFooter>
        </form>
      ) : (
        <form onSubmit={onSubmit} className="w-full">
          <CardContent className="flex flex-col items-stretch justify-start gap-4">
            {/* Phone and OTP Code Inputs */}
            <Controller
              name="phone"
              control={methods.control}
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <InputMask
                  {...field}
                  mask="(999) 999-99-99"
                  prefix="+7"
                  placeholder="(___) ___-__-__"
                >
                  <Input
                    type="tel"
                    label="Номер телефона"
                    error={methods.formState.errors.phone?.message}
                  />
                </InputMask>
              )}
            />
            <Controller
              name="verification_code"
              control={methods.control}
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <Input
                  {...field}
                  maxLength={4}
                  label="Код из SMS"
                  error={
                    methods.formState.errors.verification_code
                      ?.message
                  }
                  placeholder="----"
                />
              )}
            />
            {canResend ? (
              <button
                type="button"
                className="self-end text-base-900 underline"
                onClick={handleCodeSent}
              >
                <Typography variant="subtitle">
                  Отправить код еще раз
                </Typography>
              </button>
            ) : (
              <Typography variant="subtitle" className="self-end">
                Код не пришел? Отправить еще раз можно через{' '}
                {timeLeft} секунд
              </Typography>
            )}
          </CardContent>
          <CardFooter className="mt-8 flex flex-col items-center justify-start gap-5 lg:gap-8">
            <Button
              variant="primary"
              className="-mb-4 w-full"
              type="submit"
              disabled={loading}
            >
              Войти
            </Button>
            <Typography className="text-center text-xs text-gray-500">
              У вас нет аккаунта?{' '}
              <Link
                to="/signup"
                className="font-medium text-base-900 underline"
              >
                Создать новый аккаунт
              </Link>
            </Typography>
            <Typography
              variant={'subtitle'}
              className="self-stretch text-center font-normal text-[#9EA2AA]"
            >
              Используя CODEX, Вы соглашаетесь с{' '}
              <span className={'underline'}>
                Политикой конфиденциальности
              </span>
            </Typography>
          </CardFooter>
        </form>
      )}
    </FormProvider>
  )
}
