import IconCheck from '@/shared/assets/icons/icon_check_dark.svg?react'
import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Typography,
} from '@/shared/ui'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui/Dialog'
import { cn } from '@/shared/utils/common'

import { useDialogDocumentVersionSelect } from '../../models/useDialogDocumentVersionSelect'
import { Version } from '../../types'

interface DialogDocumentVersionSelectProps {
  isOpen: boolean
  toggleClose: () => void
  versions: Version[]
}

export const DialogDocumentVersionSelect = ({
  isOpen,
  toggleClose,
  versions,
}: DialogDocumentVersionSelectProps) => {
  const {
    submitFilters,
    selectSecondVersion,
    selectFirstVersion,
    firstVersionSelect,
    secondVersionSelect,
    selectedVersions,
  } = useDialogDocumentVersionSelect({ versions, toggleClose })

  return (
    <Dialog open={isOpen}>
      <DialogContent
        onClose={toggleClose}
        className="z-[90] w-full max-w-[530px] gap-6 overflow-hidden rounded-[20px]
          bg-white p-8 shadow-shadow5 lg:rounded-[20px]"
      >
        <DialogHeader>
          <DialogTitle className="text-start md:text-center">
            <Typography
              variant="heading5"
              className="text-lg md:text-[24px]"
            >
              Сравнение редакций
            </Typography>
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="flex flex-col items-stretch justify-start gap-8 overflow-x-auto">
          <div className="flex gap-8">
            <Select onValueChange={selectFirstVersion}>
              <SelectTrigger
                className={cn(
                  `h-[40px] w-[217px] rounded-lg px-3 py-2 outline-[3px] ring-[2px]
                  ring-transparent focus:ring-0 lg:h-[40px] xl:h-[40px]`,
                  selectedVersions[0].id &&
                    'border-[3px] border-additional-blue-2 outline-[0] ring-0',
                  `border-[3px] data-[state=open]:border-stroke-light-blue-1
                  data-[state=open]:outline-[0] data-[state=open]:ring-0`
                )}
              >
                {/* <SelectValue placeholder="Выберите редакцию" /> */}
                {selectedVersions[0].id
                  ? selectedVersions[0].published_at
                  : 'Выберите редакцию'}
              </SelectTrigger>
              <SelectContent className="z-[100] max-h-[180px] w-[217px] rounded-lg">
                {firstVersionSelect.map((ver) => {
                  const isSelected = ver.id === selectedVersions[0].id
                  return (
                    <SelectItem
                      value={ver.id.toString()}
                      className={cn(isSelected && 'bg-base-100')}
                      key={ver.id}
                    >
                      <div className="flex w-full items-center justify-between pr-[3px]">
                        <Typography>{ver.published_at}</Typography>
                        {isSelected && <IconCheck />}
                      </div>
                    </SelectItem>
                  )
                })}
              </SelectContent>
            </Select>

            <Select onValueChange={selectSecondVersion}>
              <SelectTrigger
                className={cn(
                  `h-[40px] w-[217px] rounded-lg px-3 py-2 outline-[3px] ring-[2px]
                  ring-transparent focus:ring-0 lg:h-[40px] xl:h-[40px]`,
                  selectedVersions[1].id &&
                    'border-[3px] border-additional-blue-2 outline-[0] ring-0',
                  `border-[3px] data-[state=open]:border-stroke-light-blue-1
                  data-[state=open]:outline-[0] data-[state=open]:ring-0`
                )}
              >
                {selectedVersions[1].id
                  ? selectedVersions[1].published_at
                  : 'Выберите редакцию'}
              </SelectTrigger>
              <SelectContent className="z-[100] max-h-[180px] w-[217px] rounded-lg">
                {secondVersionSelect.map((ver) => {
                  const isSelected = ver.id === selectedVersions[1].id
                  return (
                    <SelectItem
                      value={ver.id.toString()}
                      className={cn(isSelected && 'bg-base-100')}
                      key={ver.id}
                    >
                      <div className="flex w-full items-center justify-between pr-[3px]">
                        <Typography>{ver.published_at}</Typography>
                        {isSelected && <IconCheck />}
                      </div>
                    </SelectItem>
                  )
                })}
              </SelectContent>
            </Select>
          </div>
          <Button
            onClick={submitFilters}
            disabled={selectedVersions.some((ver) => ver.id === 0)}
            className={cn(
              'h-[40px] [&_span]:text-[14px] [&_span]:leading-[20px]',
              selectedVersions.some((ver) => ver.id === 0) &&
                `hover:shadow-none hover:shadow-[none] hover:outline-none
                hover:outline-0 hover:ring-0`
            )}
          >
            Сравнить выбранные редакции
          </Button>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}
