import IconContentDark from '@/shared/assets/icons/icon_content_dark.svg?react'
import { IconButton } from '@/shared/ui'
import { WidgetDocumentChapters } from '@/widgets/DocumentByIdPage/WidgetDocumentChapters'

import { useDocumentContentsAction } from '../../hooks/useDocumentContents'

export const ActionDocumentContents = () => {
  const {
    toggleDocumentContentsOpen,
    isDocumentContentsOpen,
    chaptersData,
    chaptersError,
    isFetchingChapters,
    isLoadingChapters,
  } = useDocumentContentsAction()

  if (
    isFetchingChapters ||
    isLoadingChapters ||
    !chaptersData ||
    chaptersError
  ) {
    return (
      <IconButton
        className="h-10 w-10 p-2 hover:bg-base-100"
        disabled
      >
        <IconContentDark className="h-6 w-6" />
      </IconButton>
    )
  }

  return (
    <>
      <WidgetDocumentChapters
        isOpen={isDocumentContentsOpen}
        chapters={chaptersData?.chapters}
        toggleDocumentContentsOpen={toggleDocumentContentsOpen}
      />
      <IconButton
        className="h-10 w-10 p-2 hover:bg-base-100"
        onClick={toggleDocumentContentsOpen}
      >
        <IconContentDark className="h-6 w-6" />
      </IconButton>
    </>
  )
}
