import { useGetDocumentsQuery } from '@/shared/api/documents'
import { containerScrollToTop } from '@/shared/utils/containerScrollToTop'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DocumentQueryParams, ISearchMode } from '../types'

/** Constants for scroll behavior **/
const SCROLL_POSITION_KEY = 'mobile-documents-scroll-position'
const SCROLL_UP_THRESHOLD = 250
const RESTORE_ANIMATION_DURATION = 1000
const RESTORE_ANIMATION_DELAY = 500

export const useDocumentsMobile = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryParams = Object.fromEntries(searchParams.entries())
  const { search_mode, search_query, page: pageParam } = queryParams

  const queryOptions: Partial<DocumentQueryParams> = {
    ...queryParams,
  }

  if (search_mode && search_query) {
    queryOptions.search_mode = search_mode as ISearchMode
    queryOptions.search_query = search_query
  } else if (search_mode && !search_query) {
    delete queryOptions.search_mode
    delete queryOptions.search_query
  } else if (!search_mode && search_query) {
    queryOptions.search_mode = 'IN_TITLE' as ISearchMode
    queryOptions.search_query = search_query
  }

  const [currentPage, setCurrentPage] = useState(
    pageParam ? parseInt(pageParam, 10) : 1
  )
  const handleScrollToTop = useCallback(() => {
    containerScrollToTop(containerRef)
  }, [])

  useEffect(() => {
    const newPage = pageParam ? parseInt(pageParam, 10) : 1
    if (newPage !== currentPage) {
      setCurrentPage(newPage)
    }
    handleScrollToTop()
  }, [pageParam, currentPage, handleScrollToTop])

  const { data, error, isFetching, isLoading } = useGetDocumentsQuery(
    {
      ...queryOptions,
      page: currentPage,
    }
  )

  /**
   *  Documents mobile page scroll-up behavior,
   *
   **/
  const containerRef = useRef<HTMLElement | null>(null)
  const lastDocumentRef = useRef<HTMLDivElement | null>(null) // kept as requested
  const didRestoreScroll = useRef(false)
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false)

  useEffect(() => {
    // Scroll container
    const documentsContainer = document.getElementById(
      'documents_container'
    )
    if (!documentsContainer) return

    containerRef.current = documentsContainer as HTMLElement

    const scrollHandler = () => {
      const container = containerRef.current
      if (!container) return

      // By default, show the button if we've scrolled past threshold
      let showScrollUp = container.scrollTop > SCROLL_UP_THRESHOLD

      // Check if the lastDocumentRef is fully in the viewport
      // We'll compare the container’s visible bottom vs. the last doc’s bottom.
      if (lastDocumentRef.current) {
        const { offsetTop, offsetHeight } = lastDocumentRef.current
        const containerScrollTop = container.scrollTop
        const containerClientHeight = container.clientHeight
        const containerVisibleBottom =
          containerScrollTop + containerClientHeight

        const lastDocBottom = offsetTop + offsetHeight
        // If the container's visible bottom is at or past the last doc's bottom,
        // then the last item is fully in view => hide the scroll-up button.
        if (containerVisibleBottom >= lastDocBottom) {
          showScrollUp = false
        }
      }

      setIsScrollUpVisible(showScrollUp)

      // Save scroll position
      sessionStorage.setItem(
        SCROLL_POSITION_KEY,
        container.scrollTop.toString()
      )
    }

    documentsContainer.addEventListener('scroll', scrollHandler)
    return () => {
      documentsContainer.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  // Restore scroll position once after data loads (optional)
  useEffect(() => {
    if (didRestoreScroll.current || isLoading || !data) return

    const savedPosition = sessionStorage.getItem(SCROLL_POSITION_KEY)
    if (savedPosition) {
      const targetPosition = parseInt(savedPosition, 10)
      const container = containerRef.current
      if (container) {
        let startTimestamp: number | null = null

        const animateScroll = (timestamp: number) => {
          if (startTimestamp === null) startTimestamp = timestamp
          const elapsed = timestamp - startTimestamp
          const progress = Math.min(
            elapsed / RESTORE_ANIMATION_DURATION,
            1
          )

          container.scrollTop = targetPosition * progress
          if (progress < 1) {
            requestAnimationFrame(animateScroll)
          }
        }

        setTimeout(() => {
          requestAnimationFrame(animateScroll)
        }, RESTORE_ANIMATION_DELAY)
      }
    }

    didRestoreScroll.current = true
  }, [data, isLoading])

  return {
    data,
    error,
    isLoading,
    isFetching,
    currentPage,
    setCurrentPage,
    handleScrollToTop,
    lastDocumentRef,
    isScrollUpVisible,
  }
}
