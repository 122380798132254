// src/components/SigninCompany.tsx
import { Button } from '@/shared/ui/Button'
import { CardContent, CardFooter } from '@/shared/ui/Card'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Input } from '@/shared/ui/Input'
import { Typography } from '@/shared/ui/Typography'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { useSignin } from '../../context/SigninContext'

export const SigninCompany = ({
  handlePasswordRecovery,
  isMobile,
}: {
  handlePasswordRecovery: () => void
  isMobile: unknown
}) => {
  const {
    judicialData,
    setCompanyData,
    handleCompanySignin,
    loading,
  } = useSignin()

  const methods = useForm({
    defaultValues: judicialData,
  })

  const onSubmit = methods.handleSubmit(async (data) => {
    setCompanyData(data) // Update judicial data in context
    await handleCompanySignin(data)
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <CardContent className="flex flex-col gap-4">
          <Controller
            name="email"
            control={methods.control}
            rules={{ required: 'Обязательное поле' }}
            render={({ field }) => (
              <Input
                {...field}
                label="Email"
                error={methods.formState.errors.email?.message}
                placeholder="email@gmail.com"
              />
            )}
          />
          <Controller
            name="password"
            control={methods.control}
            rules={{ required: 'Обязательное поле' }}
            render={({ field }) => (
              <Input
                {...field}
                label="Пароль"
                error={methods.formState.errors.password?.message}
                type="password"
                placeholder="••••••••"
              />
            )}
          />
          <Typography
            variant="subtitle"
            as="button"
            onClick={handlePasswordRecovery}
            className="z-20 -mt-2 self-end text-base-900 underline"
          >
            Забыли пароль?
          </Typography>
        </CardContent>
        <div className="mt-8 flex flex-row items-center justify-start gap-2">
          <Checkbox className="h-6 w-6 rounded-full bg-[#E3E3E4]" />
          <Typography variant="subtitle">
            Запомнить пароль для входа
          </Typography>
        </div>
        <CardFooter className="mt-8 flex flex-col items-center gap-5 lg:gap-8">
          <Button
            disabled={
              !methods.watch('email') ||
              !methods.watch('password') ||
              loading
            }
            variant="primary"
            className="w-full"
            type="submit"
          >
            Войти
          </Button>
          <Typography className="text-center text-xs text-gray-500">
            У вас нет аккаунта?{' '}
            <Link
              to="/signup"
              className="font-medium text-base-900 underline"
            >
              Создать новый аккаунт
            </Link>
          </Typography>
          <Typography
            variant={'subtitle'}
            className="self-stretch text-center font-normal text-[#9EA2AA]"
          >
            Используя CODEX, Вы соглашаетесь с{' '}
            <span className={'underline'}>
              Политикой конфиденциальности
            </span>
          </Typography>
        </CardFooter>
      </form>
    </FormProvider>
  )
}
