import LandingFrame1 from '@/shared/assets/icons/landing_1.svg?react'
import LandingFrame2 from '@/shared/assets/icons/landing_2.svg?react'
import LandingFrame3 from '@/shared/assets/icons/landing_3.svg?react'
import LandingFrame4 from '@/shared/assets/icons/landing_4.svg?react'
import { Typography } from '@/shared/ui'
import { motion } from 'framer-motion'

export const HomePageWelcomeContainer = () => {
  return (
    <div className="flex shrink-0 grow flex-col items-stretch justify-center pb-16">
      {/* Header */}
      <div className="flex flex-col items-stretch justify-start gap-2">
        <Typography className="text-base-960 pb-8 text-center text-[32px] font-bold leading-[54px]">
          Добро пожаловать в{' '}
          <span className="text-fill-transparent text-[32px] font-bold leading-[54px]">
            AI-ассистент!
          </span>
        </Typography>
        <Typography
          className="text-center font-normal text-base-800"
          variant={'title'}
        >
          Нажмите на карточку, чтобы посмотреть пример вопроса
        </Typography>
      </div>
      {/* Icons */}
      <div className="mt-8 flex items-stretch justify-center gap-8">
        <div
          className="relative flex h-[136px] w-[183px] flex-col gap-[9px] overflow-hidden
            rounded-[20px] border border-base-100 bg-base-50 p-2"
        >
          <motion.div
            key={'1'}
            whileHover={{ scale: 1.1 }}
            className="h-[83px] w-[83px] object-cover"
          >
            <LandingFrame1 />
          </motion.div>
          <Typography
            variant={'body'}
            className="pl-1 font-medium text-base-800"
          >
            Гражданское право
          </Typography>
        </div>
        <div
          className="relative flex h-[136px] w-[183px] flex-col gap-[9px] overflow-hidden
            rounded-[20px] border border-base-100 bg-base-50 p-2"
        >
          <motion.div
            key={'2'}
            whileHover={{ scale: 1.1 }}
            className="h-[83px] w-[83px] object-cover"
          >
            <LandingFrame2 />
          </motion.div>
          <Typography
            variant={'body'}
            className="px-1 font-medium text-base-800"
          >
            Уголовное право
          </Typography>
        </div>
        <div
          className="relative flex h-[136px] w-[183px] flex-col gap-[9px] overflow-hidden
            rounded-[20px] border border-base-100 bg-base-50 p-2"
        >
          <motion.div
            key={'3'}
            whileHover={{ scale: 1.1 }}
            className="h-[83px] w-[83px] object-cover"
          >
            <LandingFrame3 />
          </motion.div>
          <Typography
            variant={'body'}
            className="px-1 font-medium text-base-800"
          >
            Финансовое право
          </Typography>
        </div>
        <div
          className="relative flex h-[136px] w-[183px] flex-col gap-[9px] overflow-hidden
            rounded-[20px] border border-base-100 bg-base-50 p-2"
        >
          <motion.div
            key={'4'}
            whileHover={{ scale: 1.1 }}
            className="h-[83px] w-[83px] object-cover"
          >
            <LandingFrame4 />
          </motion.div>
          <Typography
            variant={'body'}
            className="px-1 font-medium text-base-800"
          >
            Трудовое право
          </Typography>
        </div>
      </div>
    </div>
  )
}
