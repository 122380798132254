import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'

interface SidebarContextProps {
  isSidebarOpen: boolean
  toggleSidebar: () => void
  closeSidebar: () => void
}

interface SidebarProviderProps {
  children: ReactNode
}

const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined
)

export const SidebarProvider: React.FC<SidebarProviderProps> = ({
  children,
}) => {
  // Set the initial value to `true` instead of `false`
  const [isSidebarOpen, setSidebarOpen] = useState(true)

  const toggleSidebar = useMemo(
    () => () => {
      setSidebarOpen((prevState) => !prevState)
    },
    []
  )

  const closeSidebar = useMemo(
    () => () => {
      setSidebarOpen(false)
    },
    []
  )

  const value = useMemo(
    () => ({ isSidebarOpen, toggleSidebar, closeSidebar }),
    [isSidebarOpen, toggleSidebar, closeSidebar]
  )

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = (): SidebarContextProps => {
  const context = useContext(SidebarContext)
  if (context === undefined) {
    throw new Error(
      'useSidebar must be used within a SidebarProvider'
    )
  }
  return context
}
