import { usePostToggleFavoriteDocumentMutation } from '@/shared/api/documents'
import { useDownloadDocument } from '@/shared/hooks/useDownloadDocument'
import { toast } from '@/shared/ui/Toast/useToast'

export const useDocumentActions = (documentId: number) => {
  const { handleDownload } = useDownloadDocument(documentId)
  const [toggleFavoriteDocument] =
    usePostToggleFavoriteDocumentMutation()

  const handleToggleFavorite = async () => {
    try {
      await toggleFavoriteDocument({ document_id: documentId })
    } catch (error) {
      toast({
        title: 'Ошибка!',
        description: 'Не удалось добавить документ в избранное.',
        variant: 'error',
      })
    }
  }

  return {
    handleDownload,
    handleToggleFavorite,
  }
}
