import { CategoriesPage } from '@/entities/filter'
import { categoriesConfig } from '@/entities/filter/consts/categoriesConfig'
import { useFilters } from '@/entities/filter/models/useFilters'
import { ECategoryType } from '@/entities/filter/types'
import { FilterMenuMobile } from '@/features/filters/FilterAccordion'
import IconChevronDown from '@/shared/assets/icons/icon_chevron_down.svg?react'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { Typography } from '@/shared/ui/Typography'
import { useRef } from 'react'

type WidgetCategoriesMobileProps = {
  pageTitle: CategoriesPage
  categoryType: ECategoryType
}

type FilterMenuMobileRef = {
  handleApply: () => void
  handleReset: () => void
}

export const WidgetCategoriesMobile = ({
  pageTitle,
  categoryType,
}: WidgetCategoriesMobileProps) => {
  const { handleMenuToggle, isMenuOpen } = useFilters({
    categoriesPage: pageTitle,
  })
  const filterMenuRef = useRef<FilterMenuMobileRef>(null)

  return (
    <Drawer
      direction="bottom"
      open={isMenuOpen}
      onOpenChange={handleMenuToggle}
    >
      <DrawerTrigger
        className={`flex h-8 items-center justify-between gap-2 rounded-lg border
          border-[#E3E5E8] bg-white px-4 py-[6px]`}
      >
        <>
          <Typography className="font-Inter whitespace-nowrap text-sm font-medium leading-[20px]">
            {categoriesConfig[pageTitle][categoryType].category.name}
          </Typography>
          <IconChevronDown />
        </>
      </DrawerTrigger>

      <DrawerContent className="z-[70] h-[92svh]">
        <DrawerClose className="absolute right-6 top-10">
          <IconClose className="h-6 w-6" />
        </DrawerClose>

        <DrawerHeader className="flex flex-col items-stretch justify-start gap-8 p-0">
          <DrawerTitle className="hidden" />
          <Typography className="self-start" variant="heading4">
            {categoriesConfig[pageTitle][categoryType].category.name}
          </Typography>
        </DrawerHeader>

        <DrawerDescription className="mt-8 flex flex-col items-stretch justify-start overflow-y-scroll">
          <FilterMenuMobile
            ref={filterMenuRef}
            categoryType={categoryType}
            searchParam={
              categoriesConfig[pageTitle][categoryType].category
                .searchParam
            }
          />
        </DrawerDescription>

        <DrawerFooter className="flex flex-row flex-nowrap justify-between gap-5 p-0 pb-5">
          <DrawerClose asChild className="shrink-0 grow">
            <Button
              variant={'primary'}
              className="w-full"
              onClick={() => filterMenuRef?.current?.handleApply()}
            >
              Применить
            </Button>
          </DrawerClose>

          <DrawerClose asChild className="shrink-0 grow">
            <Button
              variant={'outlined'}
              className="w-full"
              onClick={() => filterMenuRef?.current?.handleReset()}
            >
              Сбросить
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
