import { useAppDispatch } from '@/shared/store'
import {
  setDocumentsAllVersions,
  setDocumentsVersions,
} from '@/shared/store/slices/documents/documentsSlice'
import { useState } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { Version } from '../types'

interface useDialogDocumentVersionSelectProps {
  versions: Version[]
  toggleClose: () => void
}

export const useDialogDocumentVersionSelect = ({
  versions,
  toggleClose,
}: useDialogDocumentVersionSelectProps) => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const language = searchParams.get('language') || 'ru'
  const { id: documentId } = useParams()

  const [firstVersionSelect, setFirstVersionSelect] =
    useState(versions)
  const [secondVersionSelect, setSecondVersionSelect] =
    useState(versions)
  const [selectedVersions, setSelectedVersions] = useState<
    [Version, Version]
  >([
    {
      id: 0,
      published_at: '',
    },
    {
      id: 0,
      published_at: '',
    },
  ])

  const selectFirstVersion = (value: string) => {
    const updatedSecondVersion = versions.filter(
      (v) => v.id !== Number(value)
    )
    setSecondVersionSelect(updatedSecondVersion)

    const foundVersion = versions.find((v) => v.id === Number(value))
    if (foundVersion) {
      const updatedVersions: [Version, Version] = [
        foundVersion,
        selectedVersions[1],
      ]
      setSelectedVersions(updatedVersions)
    }
  }

  const selectSecondVersion = (value: string) => {
    const updatedSecondVersion = versions.filter(
      (v) => v.id !== Number(value)
    )
    setFirstVersionSelect(updatedSecondVersion)

    const foundVersion = versions.find((v) => v.id == Number(value))
    if (foundVersion) {
      const updatedVersions: [Version, Version] = [
        selectedVersions[0],
        foundVersion,
      ]
      setSelectedVersions(updatedVersions)
    }
  }
  const submitFilters = () => {
    if (selectedVersions.every((ver) => ver.id !== 0)) {
      dispatch(setDocumentsVersions(selectedVersions))
      dispatch(setDocumentsAllVersions(versions))
      if (documentId) {
        const versionIds = selectedVersions
          .map((ver) => ver.id)
          .join(',')
        navigate(
          `/documents/${documentId}/versions?language=${language}&ids=${versionIds}&languages=RU,RU`,
          { replace: true }
        )
        toggleClose()
      }
    }
  }

  return {
    submitFilters,
    selectSecondVersion,
    selectFirstVersion,
    firstVersionSelect,
    secondVersionSelect,
    selectedVersions,
  }
}
