// FiltersSearchInput Component
import { SearchModeFilterMenuDesktop } from '@/features/filters/SearchModeFilter'
import IconSearch from '@/shared/assets/icons/icon_search_grey.svg?react'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import React, { ForwardedRef, InputHTMLAttributes } from 'react'

import { useDocumentsSearchInput } from '../../models/useDocumentsSearchInput'

interface DocumentsSearchInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputType?: string
  onValueChange?: (value: string) => void
  innerRef?: ForwardedRef<HTMLInputElement>
}

export const DocumentsSearchInput: React.FC<
  DocumentsSearchInputProps
> = ({
  inputType = 'text',
  onValueChange,
  innerRef,
  className,
  ...props
}) => {
  const { value, handleChange, handleKeyDown, handleSubmit } =
    useDocumentsSearchInput(onValueChange)

  return (
    <div className="relative flex w-full items-start gap-2 rounded-t-3xl px-1">
      <div
        className="absolute left-2 top-1 flex min-w-[216px] flex-col items-start
          gap-[3px]"
      >
        <SearchModeFilterMenuDesktop />
      </div>
      <input
        type={inputType}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={innerRef}
        placeholder="Поиск по названию документа..."
        className={cn(
          `border-input placeholder:font-inter flex h-[40px] w-full
          rounded-[20px] border bg-[#F1F2F4] pl-[219px] pr-6 text-sm shadow-sm
          transition-colors file:border-0 file:bg-transparent file:text-sm
          file:font-medium placeholder:text-sm placeholder:font-medium
          placeholder:leading-5 placeholder:text-[#67707E]
          focus-visible:outline-none focus-visible:ring-0
          focus-visible:ring-base-900 disabled:cursor-not-allowed
          disabled:opacity-50`,
          className
        )}
        {...props}
      />
      <IconButton
        onClick={handleSubmit}
        className="absolute right-3 h-8 w-8 translate-y-[5px] rounded-[16px]
          hover:scale-100"
      >
        <IconSearch className="h-5 w-5" />
      </IconButton>
    </div>
  )
}
