import IconDownloadDark from '@/shared/assets/icons/icon_download_dark.svg?react'
import { useDownloadDocument } from '@/shared/hooks/useDownloadDocument'
import { IconButton } from '@/shared/ui'
import { useParams } from 'react-router-dom'

export const ActionDocumentDownload = () => {
  const { id: documentId } = useParams()
  const { handleDownload } = useDownloadDocument(documentId)

  return (
    <IconButton
      className="h-10 w-10 p-2 hover:bg-base-100"
      onClick={handleDownload}
    >
      <IconDownloadDark className="h-6 w-6" />
    </IconButton>
  )
}
