import {
  DialogDocumentVersionSelect,
  Version,
} from '@/entities/document'
import IconRowDark from '@/shared/assets/icons/icon_row_dark.svg?react'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

import { useDocumentOpenVersionsSelectAction } from '../../hooks/useDocumentOpenVersionsSelectAction'

interface ActionDocumentOpenVersionsSelectProps {
  versions: Version[]
}

export const ActionDocumentOpenVersionsSelect = ({
  versions,
}: ActionDocumentOpenVersionsSelectProps) => {
  const {
    isOpenVersionSelect,
    toggleCloseVersionsSelect,
    toggleOpenVersionsSelect,
  } = useDocumentOpenVersionsSelectAction()
  return (
    <>
      <DialogDocumentVersionSelect
        isOpen={isOpenVersionSelect}
        toggleClose={toggleCloseVersionsSelect}
        versions={versions}
      />
      <IconButton
        className={cn(
          'h-10 w-10 p-2 hover:bg-base-100',
          isOpenVersionSelect && 'bg-base-100',
          'disabled:'
        )}
        onClick={toggleOpenVersionsSelect}
        disabled={!versions.length}
      >
        <IconRowDark className="h-6 w-6" />
      </IconButton>
    </>
  )
}
