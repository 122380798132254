import { useGetFavoritesQuery } from '@/shared/api/documents'
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import { FavoriteDocumentQueryParams, ISearchMode } from '../types'

type Props = {
  isMobile: boolean
}

export const useFavoriteDocuments = ({ isMobile = false }: Props) => {
  const [searchParams] = useSearchParams()
  const queryParams = Object.fromEntries(searchParams.entries())

  const {
    search_mode,
    search_query,
    status,
    created_at,
    created_at_from,
    created_at_until,
    page,
  } = queryParams

  const queryOptions = useMemo<Partial<FavoriteDocumentQueryParams>>(
    () => ({
      search_mode: search_mode as ISearchMode,
      search_query,
      status:
        status === 'ACTIVE' || status === 'PENDING'
          ? status
          : undefined,
      created_at,
      created_at_from,
      created_at_until,
      page: page ? Number(page) : 1,
    }),
    [
      search_mode,
      search_query,
      status,
      created_at,
      created_at_from,
      created_at_until,
      page,
    ]
  )

  const currentPage = queryOptions.page ?? 1
  const [localPage, setLocalPage] = useState(currentPage)

  const { data, error, isFetching, isLoading } = useGetFavoritesQuery(
    { ...queryOptions, page: localPage },
    { skip: false }
  )

  const observer = useRef<IntersectionObserver | null>(null)
  const lastFavoriteRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!isMobile || isFetching) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && data?.meta?.next_page) {
          setLocalPage((prevPage) => prevPage + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [isMobile, isFetching, data?.meta?.next_page]
  )

  const prevQueryOptionsRef = useRef<string>(
    JSON.stringify(queryOptions)
  )
  useEffect(() => {
    const currentQueryOptions = JSON.stringify(queryOptions)
    if (prevQueryOptionsRef.current !== currentQueryOptions) {
      prevQueryOptionsRef.current = currentQueryOptions
      setLocalPage(1)
    }
  }, [queryOptions])

  return {
    data,
    error,
    currentPage: localPage,
    isLoading,
    isFetching,
    lastFavoriteRef,
  }
}
