import { ISignupPersonalDTO } from '@/entities/signup/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SignupPersonalState {
  data: ISignupPersonalDTO
  codeSent: boolean
  canResend: boolean
}

const initialState: SignupPersonalState = {
  data: {
    name: '',
    user_id: null,
    phone: '',
    verification_code: '',
  },
  codeSent: false,
  canResend: false,
}

const signupPersonalSlice = createSlice({
  name: 'signupPersonal',
  initialState,
  reducers: {
    resetSignupPersonalSteps: (state) => {
      state.data = initialState.data
      state.codeSent = false
      state.canResend = false
    },
    updateSignupData: (
      state,
      action: PayloadAction<Partial<ISignupPersonalDTO>>
    ) => {
      state.data = { ...state.data, ...action.payload }
    },

    setCodeSent: (state, action: PayloadAction<boolean>) => {
      state.codeSent = action.payload
    },
    setCanResend: (state, action: PayloadAction<boolean>) => {
      state.canResend = action.payload
    },
  },
})

export const {
  resetSignupPersonalSteps,
  updateSignupData,
  setCodeSent,
  setCanResend,
} = signupPersonalSlice.actions

export default signupPersonalSlice.reducer
