import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  PersistConfig,
  Persistor,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

import { broadcastAuthApi } from '../api/broadcast'
import { chatApi } from '../api/chat'
import { documentsApi } from '../api/documents'
import { signinPersonalApi } from '../api/signinPersonal'
import { signupCompanyApi } from '../api/signupCompany'
import { signupPersonalApi } from '../api/signupPersonal'
import { userApi } from '../api/user'
import { rtkQueryErrorLogger } from './middleware'
import signinCompanyReducer from './slices/auth/signinCompanySlice'
import signinPersonalReducer from './slices/auth/signinPersonalSlice'
import signupCompanyReducer from './slices/auth/signupCompanySlice'
import signupPersonalReducer from './slices/auth/signupPersonalSlice'
import userReducer from './slices/auth/userSlice'
import chatReducer from './slices/chat/chatSlice'
import documentPreviewReducer from './slices/chat/documentPreviewSlice'
import documentsReducer from './slices/documents/documentsSlice'
import DocumentMetaReducer from './slices/meta/documentMeta'

const documentsApiPersistConfig: PersistConfig<any> = {
  key: 'documentsApi',
  storage: storageSession,
  whitelist: ['queries', 'mutations'], // Persist queries and mutations
}

const persistedDocumentsApiReducer = persistReducer(
  documentsApiPersistConfig,
  documentsApi.reducer
)

const rootReducer = combineReducers({
  signupCompany: signupCompanyReducer,
  signupPersonal: signupPersonalReducer,
  signinPersonal: signinPersonalReducer,
  signinCompany: signinCompanyReducer,
  user: userReducer,
  chats: chatReducer,
  documents: documentsReducer,
  documentMeta: DocumentMetaReducer,
  documentPreview: documentPreviewReducer,
  [signinPersonalApi.reducerPath]: signinPersonalApi.reducer,
  [signupPersonalApi.reducerPath]: signupPersonalApi.reducer,
  [signupCompanyApi.reducerPath]: signupCompanyApi.reducer,
  [broadcastAuthApi.reducerPath]: broadcastAuthApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  // Use the persisted reducer for documentsApi
  [documentsApi.reducerPath]: persistedDocumentsApiReducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Needed for redux-persist to work with RTK Query
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          // Add any other actions that cause serialization errors
        ],
      },
    }).concat(
      documentsApi.middleware,
      signinPersonalApi.middleware,
      signupPersonalApi.middleware,
      signupCompanyApi.middleware,
      broadcastAuthApi.middleware,
      chatApi.middleware,
      userApi.middleware,
      rtkQueryErrorLogger
    ),
})

export const persistor: Persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useTypedSelector: TypedUseSelectorHook<RootState> =
  useSelector

export default store
