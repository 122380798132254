import { Button } from '@/shared/ui/Button'
import { CardContent, CardFooter } from '@/shared/ui/Card'
import { Input } from '@/shared/ui/Input'
import { Typography } from '@/shared/ui/Typography'
import { unformatPhone } from '@/shared/utils/unformatPhone'
import { Controller, FormProvider } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { Link } from 'react-router-dom'

import { useSignupPersonal } from '../../models/useSignupPersonal'

export const SignupPersonal = ({
  isMobile,
}: {
  isMobile: boolean
}) => {
  const {
    methods,
    errors,
    timeLeft,
    codeSent,
    canResend,
    onSubmit,
    resetField,
    handleCodeSent,
  } = useSignupPersonal()

  const phone = methods.watch('phone') ?? ''
  const verificationCode = methods.watch('verification_code') ?? ''
  const name = methods.watch('name') ?? ''

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <CardContent className="flex flex-col gap-4">
          <Controller
            name="name"
            control={methods.control}
            rules={{ required: 'Обязательное поле' }}
            render={({ field }) => (
              <Input
                {...field}
                label="ФИО"
                resetField={resetField}
                error={errors.name?.message}
                placeholder="Иван Васильевич"
              />
            )}
          />
          <Controller
            name="phone"
            control={methods.control}
            defaultValue=""
            rules={{ required: 'Обязательное поле' }}
            render={({ field }) => (
              <InputMask
                {...field}
                mask="(999) 999-99-99"
                prefix="+7"
                placeholder="(___) ___-__-__"
              >
                <Input
                  type="tel"
                  label="Номер телефона"
                  resetField={resetField}
                  error={errors.phone?.message}
                />
              </InputMask>
            )}
          />

          {codeSent && (
            <>
              <Controller
                name="verification_code"
                control={methods.control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    {...field}
                    maxLength={4}
                    label="Код из SMS"
                    resetField={resetField}
                    error={errors.verification_code?.message}
                    placeholder="----"
                  />
                )}
              />
              {canResend ? (
                <button
                  disabled={
                    !phone ||
                    phone.replace(/[\s()-/_]/g, '').length !== 10
                  }
                  type="button"
                  className="-mt-2 self-end text-base-900 underline"
                  onClick={handleCodeSent}
                >
                  <Typography variant={'subtitle'}>
                    Отправить еще раз
                  </Typography>
                </button>
              ) : (
                <Typography
                  variant={'subtitle'}
                  className="-mt-2 self-end"
                >
                  Код не пришел? Отправить еще раз можно через{' '}
                  {timeLeft} секунд
                </Typography>
              )}
            </>
          )}
        </CardContent>

        <CardFooter className="mt-8 flex flex-col items-center justify-start gap-5 lg:gap-8">
          <div className="flex flex-col items-stretch justify-center gap-4 self-stretch">
            {codeSent ? (
              <Button
                variant={'primary'}
                className="w-full"
                disabled={
                  !phone ||
                  phone.replace(/[\s()-/_]/g, '').length !== 10 ||
                  !verificationCode ||
                  !name
                }
                type="submit"
              >
                Войти
              </Button>
            ) : (
              <Button
                variant={'primary'}
                className="w-full"
                disabled={
                  !phone ||
                  phone.replace(/[\s()-/_]/g, '').length !== 10 ||
                  !name
                }
                type="button"
                onClick={handleCodeSent}
              >
                {isMobile
                  ? 'Отправить SMS-код'
                  : 'Отправить SMS-код подтверждения'}
              </Button>
            )}
            <Typography className="text-center text-xs text-gray-500">
              Уже есть аккаунт?{' '}
              <Link
                to={'/signin'}
                className="font-medium text-base-900 underline"
              >
                Войти
              </Link>
            </Typography>
          </div>

          <Typography
            variant={'subtitle'}
            className="text-center font-normal text-[#9EA2AA]"
          >
            Используя CODEX, Вы соглашаетесь с{' '}
            <span className={'underline'}>
              Политикой конфиденциальности
            </span>
          </Typography>
        </CardFooter>
      </form>
    </FormProvider>
  )
}
