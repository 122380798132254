import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'

interface InputCounterProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  value: number // Ensure value is controlled
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void // Ensure onChange is handled
}

export const InputCounter = forwardRef<
  HTMLInputElement,
  InputCounterProps
>(({ label, className, value, onChange, ...props }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => inputRef.current!)

  const handleDecrement = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.stepDown()
      inputRef.current.dispatchEvent(
        new Event('input', { bubbles: true })
      )
    }
  }, [])

  const handleIncrement = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.stepUp()
      inputRef.current.dispatchEvent(
        new Event('input', { bubbles: true })
      )
    }
  }, [])

  return (
    <div
      className={cn(
        'flex flex-col items-start justify-between gap-2 self-stretch',
        className
      )}
    >
      {label && (
        <Typography variant="label" className="whitespace-nowrap">
          {label}
        </Typography>
      )}

      <div
        className={cn(
          'relative flex h-[40px] w-full items-center justify-between gap-[18px]',
          'rounded-full bg-[#F0F0F0] p-1'
        )}
      >
        <button
          type="button"
          onClick={handleDecrement}
          className="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center
            rounded-full bg-white shadow-lg disabled:cursor-not-allowed"
        >
          <svg
            className="h-2.5 w-2.5 text-gray-900 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 2"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h16"
            />
          </svg>
        </button>
        <input
          ref={inputRef}
          type="number"
          min={1}
          max={1000}
          value={value} // Controlled value
          onChange={onChange} // Controlled onChange
          className={cn(
            `[&::-moz-appearance]:textfield max-h-8 w-10 shrink grow-0 border-none
            bg-transparent text-center text-sm font-normal text-gray-900
            dark:text-white [&::-webkit-inner-spin-button]:appearance-none
            [&::-webkit-outer-spin-button]:appearance-none`
          )}
          {...props}
        />
        <button
          type="button"
          onClick={handleIncrement}
          className="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center
            rounded-full bg-white shadow-lg focus:ring-2 focus:ring-gray-100"
        >
          <svg
            className="h-2.5 w-2.5 text-gray-900 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 1v16M1 9h16"
            />
          </svg>
        </button>
      </div>
    </div>
  )
})

InputCounter.displayName = 'InputCounter'
