// DocumentsLayout.tsx
import { useFilters } from '@/entities/filter/models/useFilters'
import { CategoriesPage } from '@/entities/filter/types'
import {
  DocumentsSearchInput,
  MobileDocumentsSearchInput,
} from '@/features/filters/DocumentsSearchInput'
import IconChevronLeft from '@/shared/assets/icons/icon_chevron_left_dark.svg?react'
import IconDots from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useNavigateBack } from '@/shared/hooks/useNavigateBack'
import { useAppDispatch } from '@/shared/store'
import { setDocumentsReturnUrl } from '@/shared/store/slices/documents/documentsSlice'
import { cn } from '@/shared/utils/common'
import { DocumentFiltersMobile } from '@/widgets/DocumentsBase/DocumentFiltersMobile'
import { WidgetCategories } from '@/widgets/WidgetCategories'
import { WidgetDocumentFilters } from '@/widgets/WidgetDocumentFilters'
import { useEffect, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

export const DocumentsLayout = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')
  const location = useLocation()
  const dispatch = useAppDispatch()
  const pathParts = location.pathname.split('/').filter(Boolean)
  const widgetFiltersRef = useRef<HTMLDivElement>(null)
  const isDocumentsPage =
    pathParts[0] === 'documents' &&
    (pathParts.length === 1 || pathParts[1] === 'favorite')

  const isDocByIdPage = Boolean(
    location.pathname.startsWith('/documents/') &&
      location.pathname.split('/documents/')[1]
  )

  const handleGoBack = useNavigateBack('/documents')
  const { handleMenuToggle, isMenuOpen, categoriesData } = useFilters(
    { categoriesPage: 'document' as CategoriesPage }
  )

  useEffect(() => {
    const currentUrl = location.pathname + location.search
    dispatch(setDocumentsReturnUrl({ url: currentUrl }))
  }, [location, dispatch])

  useEffect(() => {
    const resizeWidgetFilters = () => {
      const innerWidth = window.innerWidth
      if (widgetFiltersRef.current) {
        const { width } =
          widgetFiltersRef.current.getBoundingClientRect()
        const updatedWidth = isMenuOpen
          ? innerWidth - 347
          : innerWidth - 116
        widgetFiltersRef.current.style.width = `${updatedWidth}px`
      }
    }
    resizeWidgetFilters()

    window.addEventListener('resize', resizeWidgetFilters)

    return () => {
      window.removeEventListener('resize', resizeWidgetFilters)
    }
  }, [isMenuOpen])

  if (isMobile)
    return (
      <div
        id="documents_container"
        className={cn(
          `flex flex-col items-stretch justify-start overflow-x-hidden
          overflow-y-scroll pb-[88px]`,
          !isDocumentsPage ? 'px-0 pt-4' : 'px-5 pt-[46px]'
        )}
      >
        {isDocumentsPage ? (
          <>
            <div className="mb-5 flex justify-center self-stretch">
              <MobileDocumentsSearchInput />
            </div>
            <DocumentFiltersMobile />
            <div className="-mx-5 mb-2 h-2 shrink grow-0 bg-[#F0F0F0]" />
          </>
        ) : (
          <div className="flex w-full items-center justify-between px-2 py-4">
            <IconChevronLeft
              width={'24px'}
              height={'24px'}
              onClick={handleGoBack}
            />

            <IconDots />
          </div>
        )}

        {/* Body container */}
        <Outlet context={{ isMobile: isMobile }} />
      </div>
    )

  return (
    <>
      {/* Categories */}
      {!isDocByIdPage && (
        <WidgetCategories
          categoriesData={categoriesData}
          handleMenuToggle={handleMenuToggle}
          isMenuOpen={isMenuOpen}
          pageTitle="document"
        />
      )}
      {/* Filters */}
      {isDocumentsPage && (
        <div
          ref={widgetFiltersRef}
          className={cn(
            `fixed right-6 top-20 z-50 -mt-20 self-start bg-[#FAFAFA] pt-[72px]
            transition-all duration-300 ease-in-out`,
            isMenuOpen ? 'left-[307px]' : 'left-[76px]'
          )}
          // style={{ backdropFilter: 'blur(6px)' }}
        >
          <div className="rounded-t-3xl border-x-[1px] border-t-[1px] bg-white pt-1">
            <DocumentsSearchInput />
            <WidgetDocumentFilters />
          </div>
        </div>
      )}
      {/* Documents */}
      <div
        className={cn(
          `relative flex w-full flex-1 items-stretch justify-start gap-3
          bg-transparent px-6 pt-[72px]`
        )}
      >
        {/* Empty div for controlling the animations */}
        <div
          className={cn(
            'overflow-hidden transition-all duration-300 ease-in-out',
            isMenuOpen ? 'w-[271px]' : 'w-10'
          )}
        />
        <div
          className={cn(
            'menu_boundary relative flex-1 rounded-t-3xl bg-transparent',
            isDocByIdPage ? 'pt-0' : 'pt-[94px] shadow-shadow10'
          )}
        >
          <Outlet context={{ isMobile: isMobile }} />
        </div>
      </div>
    </>
  )
}
