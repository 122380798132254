import IconCopyGray from '@/shared/assets/icons/icon_copy_mobile.svg?react'
import { useCopyToClipboard } from '@/shared/hooks/useCopyToClipboard'
import { IconButton } from '@/shared/ui'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui/Tooltip'

interface ButtonCopyAiResponseProps {
  text: string
}

export const ButtonCopyAiResponse = ({
  text,
}: ButtonCopyAiResponseProps) => {
  const { copyToClipboard } = useCopyToClipboard()
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <IconButton
          onClick={(
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
          ) => {
            e.stopPropagation()
            copyToClipboard(text)
          }}
          className="p-[6px] hover:scale-100 hover:bg-base-200"
        >
          <IconCopyGray
            height={18}
            width={18}
            className="[&>g>path]:stroke-[#67707E]"
          />
        </IconButton>
      </TooltipTrigger>
      <TooltipContent side="bottom" align="center">
        Копировать текст
      </TooltipContent>
    </Tooltip>
  )
}
