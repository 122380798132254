import { ButtonResendMessage } from '@/features/chat/ui/ButtonResendMessage'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useTypedSelector } from '@/shared/store'
import { Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'

import { IChatMessage } from '../../types'
import { ChatAiError } from '../ChatAiError'
import { ChatAiResponse } from '../ChatAiResponse'
import { ChatResponseLoader } from '../ChatResponseLoader'
import { ChatUserRequest } from '../ChatUserRequest'
import { MobileChatAiResponse } from '../MobileChatAiResponse'

interface ChatMessagesProps {
  messages?: IChatMessage[]
  typedText?: string
  isAwaitingResponse: boolean
}
// TODO: Move ChatMessages to widgets
export const ChatMessages = ({
  messages,
  typedText,
  isAwaitingResponse,
}: ChatMessagesProps) => {
  const isMobile = useMediaQuery('(max-width: 960px)')
  const { chat_id } = useParams<{ chat_id: string }>()
  const { chatsData } = useTypedSelector((state) => state.chats)
  const chatData = chatsData[chat_id!]
  const isAiResponding = useTypedSelector(
    (state) => state.chats.chatsData[chat_id!]?.isResponding ?? false
  )

  const isChatOpen = useTypedSelector(
    (state) => state.documentPreview.isChatOpen
  )

  if (!messages) return null

  return (
    <div
      className={cn(
        'flex w-full flex-col justify-end gap-8 pb-12',
        !isChatOpen ? 'min-h-full' : ''
      )}
    >
      {messages.map((item, index) => {
        const isLastMessage = index === messages.length - 1
        const messageId = isLastMessage ? 'last-message' : undefined

        if (item.from === 'ASSISTANT') {
          const ResponseComponent = isMobile
            ? MobileChatAiResponse
            : ChatAiResponse
          return (
            <div key={item.id + index} id={messageId}>
              <ResponseComponent
                response={item}
                isLastResponse={isLastMessage}
              />
            </div>
          )
        }

        if (item.from === 'USER') {
          return (
            <ChatUserRequest
              key={item.id + index}
              chatMessage={item}
              id={messageId}
            />
          )
        }

        return null
      })}

      {chatData?.error ? (
        <>
          <ChatAiError />
          <ButtonResendMessage
            chatId={chat_id!}
            messages={messages}
          />
        </>
      ) : null}
      {isAwaitingResponse ? <ChatResponseLoader /> : null}
      {isAiResponding ? (
        <Typography
          key={'response-typed-text'}
          className="whitespace-normal break-words"
        >
          <ReactMarkdown className={'markdown -mt-[18px]'}>
            {typedText ?? ''}
          </ReactMarkdown>
        </Typography>
      ) : null}
    </div>
  )
}
