import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { AppRoutes } from './app/router'
import { ThemeProvider } from './shared/providers/ThemeProvider'
import store, { persistor } from './shared/store'
import { Toaster } from './shared/ui/Toast/toaster'

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider defaultTheme="light" storageKey="oxima-theme">
        <PersistGate loading={null} persistor={persistor}>
          <Toaster />
          <AppRoutes />
        </PersistGate>
      </ThemeProvider>
    </Provider>
  )
}
export default App
