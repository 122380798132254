import IconContentDark from '@/shared/assets/icons/icon_content_dark.svg?react'
import { IconButton } from '@/shared/ui'
import { WidgetDocumentChapters } from '@/widgets/DocumentByIdPage/WidgetDocumentChapters'

import { useDocumentChaptersAction } from '../../hooks/useDocumentChapters'

export const ActionDocumentChapters = () => {
  const {
    toggleDocumentChaptersOpen,
    isDocumentChaptersOpen,
    chaptersData,
    chaptersError,
    handleDocumentChapterClick,
    isFetchingChapters,
    isLoadingChapters,
  } = useDocumentChaptersAction()

  if (
    isFetchingChapters ||
    isLoadingChapters ||
    !chaptersData ||
    chaptersError
  ) {
    return (
      <IconButton
        className="h-10 w-10 p-2 hover:bg-base-100"
        disabled
      >
        <IconContentDark className="h-6 w-6" />
      </IconButton>
    )
  }

  return (
    <>
      <WidgetDocumentChapters
        isOpen={isDocumentChaptersOpen}
        chapters={chaptersData?.chapters}
        handleDocumentChapterClick={handleDocumentChapterClick}
        toggleDocumentChaptersOpen={toggleDocumentChaptersOpen}
      />
      <IconButton
        className="h-10 w-10 p-2 hover:bg-base-100"
        onClick={toggleDocumentChaptersOpen}
      >
        <IconContentDark className="h-6 w-6" />
      </IconButton>
    </>
  )
}
