import { useGetDocumentChaptersQuery } from '@/shared/api/documents'
import { toast } from '@/shared/ui/Toast/useToast'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

export const useDocumentContentsAction = () => {
  const { id: documentId } = useParams()
  const [isDocumentContentsOpen, setIsDocumentContentsOpen] =
    useState(false)

  const toggleDocumentContentsOpen = () => {
    setIsDocumentContentsOpen(!isDocumentContentsOpen)
  }
  const {
    data: chaptersData,
    error: chaptersError,
    isFetching: isFetchingChapters,
    isLoading: isLoadingChapters,
  } = useGetDocumentChaptersQuery({ id: Number(documentId) })

  if (chaptersError) {
    toast({
      variant: 'error',
      title: 'Что-то пошло не так',
      description:
        'Не удалось загрузить содержание документа. Попробуйте обновить страницу.',
    })
  }
  return {
    isDocumentContentsOpen,
    toggleDocumentContentsOpen,
    chaptersData,
    chaptersError,
    isFetchingChapters,
    isLoadingChapters,
  }
}
