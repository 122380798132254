import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useTypedSelector } from '@/shared/store'
import { cn } from '@/shared/utils/common'
import { MobilePlatformHeader } from '@/widgets/Headers'
import { Sidebar } from '@/widgets/Sidebar'
import {
  SidebarProvider,
  useSidebar,
} from '@/widgets/Sidebar/model/useSidebar'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const AssistantLayoutContent = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')
  const { isSidebarOpen, closeSidebar } = useSidebar()

  const { documents } = useTypedSelector(
    (state) => state.documentPreview
  )

  useEffect(() => {
    closeSidebar()
  }, [closeSidebar])

  if (isMobile)
    return (
      <>
        <MobilePlatformHeader />
        <Outlet />
      </>
    )

  return (
    <>
      <div
        className="relative flex h-[100svh] w-screen flex-col items-stretch justify-start
          px-8"
      >
        <div
          className={cn(
            'relative flex items-stretch',
            isSidebarOpen ? 'gap-8' : ''
          )}
          style={{ height: 'calc(100svh)' }}
        >
          <Sidebar />
          {/* Spacer Div */}
          <motion.div
            animate={{
              width: isSidebarOpen ? 272 : 0,
              opacity: isSidebarOpen ? 1 : 0,
            }}
            transition={{
              width: { type: 'spring', stiffness: 400, damping: 30 }, // Smooth width animation
              opacity: { duration: 0.1, ease: 'easeInOut' }, // Faster opacity fade
            }}
            className={cn(
              'relative shrink-0 bg-transparent',
              documents.length !== 0 ? 'absolute' : 'relative'
            )}
          />
          {/* Content */}
          <div
            className="flex shrink grow-0 basis-full items-stretch justify-center
              overflow-hidden"
          >
            <Outlet />
          </div>
        </div>
        {/* Sidebar */}
      </div>
    </>
  )
}

export const AssistantLayout = () => {
  return (
    <SidebarProvider>
      <AssistantLayoutContent />
    </SidebarProvider>
  )
}
