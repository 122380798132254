import { ISignupCompanyDTO } from '@/entities/signup/types'
import {
  useFetchCompanyTypesQuery,
  useRegisterCompanyMutation,
  useSendMailOtpMutation,
} from '@/shared/api/signupCompany'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  incrementStep,
  setCanResend,
  setCodeSent,
  setCompanyTypes,
  updateSignupData,
} from '@/shared/store/slices/auth/signupCompanySlice'
import { useToast } from '@/shared/ui/Toast/useToast'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTimer } from 'react-timer-hook'

export const useSignupCompany = () => {
  const dispatch = useAppDispatch()
  const { data, codeSent, canResend } = useTypedSelector(
    (state) => state.signupCompany
  )

  const methods = useForm<ISignupCompanyDTO>({
    defaultValues: data ?? {},
  })

  const [sendMailOtp, { isLoading: isOtpLoading }] =
    useSendMailOtpMutation()
  const [registerCompany, { isLoading: isRegisterLoading }] =
    useRegisterCompanyMutation()
  // TODO: Business logic of companyTypes will change
  const {
    data: companyTypesResponse,
    isLoading: isCompanyTypesLoading,
  } = useFetchCompanyTypesQuery()

  const { seconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      dispatch(setCanResend(true))
    },
  })

  const handleCodeSent = useCallback(async () => {
    const phone = methods.watch('phone')
    if (phone) {
      try {
        await sendMailOtp(phone).unwrap()

        dispatch(setCodeSent(true))
        dispatch(setCanResend(false))
      } catch (error: any) {
        console.error('OTP error')
      } finally {
        const newExpiryTimestamp = new Date()
        newExpiryTimestamp.setSeconds(
          newExpiryTimestamp.getSeconds() + 30
        )
        restart(newExpiryTimestamp, true)
      }
    }
  }, [dispatch, restart, methods])

  const onSubmitOTP = methods.handleSubmit(async (formData) => {
    dispatch(
      updateSignupData({
        ...formData,
      })
    )
    try {
      await handleCodeSent()
    } catch (error) {
      console.error(error)
    }
  })

  return {
    methods,
    onSubmitOTP,
    resetField: methods.resetField,
    companyTypes: companyTypesResponse?.data,
    isCompanyTypesLoading,
    errors: methods.formState.errors,
    timeLeft: seconds,
    codeSent,
    canResend,
    handleCodeSent,
    isLoading: isOtpLoading || isRegisterLoading,
  }
}
