import { useAppDispatch } from '@/shared/store'
import {
  clearChatReturnUrl,
  setChatReturnUrl,
} from '@/shared/store/slices/chat/chatSlice'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface UseChatOptions {
  shouldCacheReturnUrl?: boolean
}

export const useChat = ({
  shouldCacheReturnUrl = true,
}: UseChatOptions = {}) => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (shouldCacheReturnUrl) {
      const currentUrl = location.pathname + location.search
      dispatch(setChatReturnUrl({ url: currentUrl }))
    } else {
      dispatch(clearChatReturnUrl())
    }
  }, [location, dispatch, shouldCacheReturnUrl])
}
