import { useDocumentsMobile } from '@/entities/document/models/useDocumentsMobile'
import { DocumentItemMobile } from '@/entities/document/ui/DocumentItemMobile'
import { DocumentItemMobileSkeleton } from '@/entities/document/ui/DocumentItemMobileSkeleton'
import IconArrowScrollUp from '@/shared/assets/icons/icon_arrow_scroll_up.svg?react'
import IconNotFound from '@/shared/assets/icons/logo_not_found.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import { CustomPaginationMobile } from '@/widgets/CustomPaginationMobile'

export const DocumentBaseContainerMobile = () => {
  const {
    data,
    error,
    isFetching,
    currentPage,
    setCurrentPage,
    lastDocumentRef,
    isScrollUpVisible,
    handleScrollToTop,
  } = useDocumentsMobile()

  const hasData = data && data?.documents?.length > 0

  if (error && !hasData) {
    return (
      <div
        className="flex flex-col items-stretch justify-start rounded-b-3xl border-x-[1px]
          border-b-[1px] border-t-0 bg-transparent"
        style={{ boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)' }}
      >
        <div className="my-20 flex flex-col items-stretch justify-center gap-8">
          <IconNotFound className="self-center drop-shadow-lg" />
          <div className="flex flex-col items-center justify-center gap-2">
            <Typography className="text-center" variant="title">
              По запросу не найдено ни одного документа.
            </Typography>
            <Typography className="text-center" variant="label">
              Попробуйте написать запрос иначе и повторить поиск.
            </Typography>
          </div>
        </div>
      </div>
    )
  }

  if (isFetching || !data) {
    return (
      <div className="flex flex-col items-stretch justify-start gap-3 pb-12">
        {Array.from({ length: 4 }).map((_, index) => (
          <DocumentItemMobileSkeleton key={index} />
        ))}
      </div>
    )
  }

  return (
    <div className="flex flex-col items-stretch justify-start gap-4 bg-transparent">
      {hasData ? (
        data.documents.map((doc, index) => {
          const isLastItem = index === data.documents.length - 1
          return (
            <DocumentItemMobile
              key={doc.id}
              documentItem={doc}
              isLastDocumentItem={isLastItem}
              ref={isLastItem ? lastDocumentRef : undefined}
            />
          )
        })
      ) : (
        <div
          className="flex shrink-0 grow flex-col items-center justify-center self-stretch
            py-20"
        >
          <div className="flex flex-col items-stretch justify-center gap-8">
            <IconNotFound className="self-center drop-shadow-lg" />
            <div className="flex flex-col items-center justify-center">
              <Typography>
                По запросу не найдено ни одного документа.
              </Typography>
              <Typography>
                Попробуйте написать запрос иначе и повторить поиск.
              </Typography>
            </div>
          </div>
        </div>
      )}
      <CustomPaginationMobile
        currentPage={currentPage}
        totalPages={data.meta.last_page}
        perPage={data.meta.per_page}
        totalItems={data.meta.total}
      />

      {isScrollUpVisible && !isFetching && (
        <IconButton
          onClick={handleScrollToTop}
          className="fixed bottom-[103px] left-1/2 -translate-x-1/2 rounded-full border
            border-base-200 bg-white p-1"
          style={{
            boxShadow:
              '0px 18px 5px 0px rgba(0, 0, 0, 0.00), 0px 12px 5px 0px rgba(0, 0, 0, 0.00), 0px 7px 4px 0px rgba(0, 0, 0, 0.02), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)',
          }}
        >
          <IconArrowScrollUp />
        </IconButton>
      )}
    </div>
  )
}
