import { Skeleton } from '@/shared/ui/Skeleton'
import { cn } from '@/shared/utils/common'

type Props = {
  isLastItem?: boolean
}

const DocumentVersionsMenuSkeleton = ({ isLastItem }: Props) => (
  <Skeleton
    className={cn(
      `flex w-full items-stretch justify-start gap-4 rounded-[12px]
      border-b-[1px] border-stroke-light-gray-1 bg-bg-light-white-1 px-4
      py-3 shadow-shadow3`
    )}
  >
    <div
      className="flex grow flex-col items-stretch justify-start gap-3 self-stretch
        bg-white"
    >
      <Skeleton className="h-5 w-full bg-base-200" />

      <div className="flex items-center justify-between gap-[100px]">
        <Skeleton className="h-5 w-3/4 bg-base-200" />
        <Skeleton className="h-5 w-3/4 bg-base-200" />
      </div>
    </div>
  </Skeleton>
)

export { DocumentVersionsMenuSkeleton }
