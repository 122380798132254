import IconCheck from '@/shared/assets/icons/icon_check_dark.svg?react'
import { useToast } from '@/shared/ui/Toast/useToast'

import {
  ToastAction,
  ToastActionElement,
  ToastProps,
} from '../ui/Toast/toast'

interface useCopyToClipboardProps {
  withToast?: boolean
  toastOptions?: ToastProps & {
    title?: React.ReactNode
    icon?: React.ReactNode
    description?: React.ReactNode
    action?: ToastActionElement
  }
}

export const useCopyToClipboard = ({
  withToast = true,
  toastOptions,
}: useCopyToClipboardProps = {}) => {
  const { toast } = useToast()

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)

      if (withToast) {
        toast(
          toastOptions ?? {
            icon: (
              <IconCheck className="[&_path]:fill-additional-green-2" />
            ),
            title: 'Успешно!',
            description: 'Текст скопирован в буфер обмена',
            variant: 'success',
          }
        )
      }
    } catch (error) {
      toast(
        toastOptions ?? {
          title: 'Ошибка копирования',
          description: 'Не удалось скопировать текст в буфер обмена',
          variant: 'error',
        }
      )
    }
  }

  return { copyToClipboard }
}
