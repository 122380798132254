import { IChatMessage } from '@/entities/chat/types'
import IconRetry from '@/shared/assets/icons/icon_retry.svg?react'
import { Button } from '@/shared/ui'

import { useResendChatMessage } from '../../models/useResendChatMessage'

interface ButtonResendMessageProps {
  chatId: string
  messages: IChatMessage[] | undefined
}

export const ButtonResendMessage = ({
  chatId,
  messages,
}: ButtonResendMessageProps) => {
  const { handleRetry } = useResendChatMessage({ chatId, messages })
  return (
    <Button
      variant="outlined"
      leftIcon={<IconRetry />}
      className="absolute bottom-28 left-1/2 z-50 h-[36px] -translate-x-1/2 border
        border-stroke-light-gray-1 pl-4 text-[14px] font-medium leading-[20px]
        shadow-shadow8"
      onClick={handleRetry}
    >
      Отправить запрос повторно
    </Button>
  )
}
