import { DocumentItemStatus } from '@/entities/document/ui/DocumentItemStatus'
import { usePostToggleFavoriteDocumentMutation } from '@/shared/api/documents'
import IconBookmarkBlack from '@/shared/assets/icons/icon_bookmark_dark.svg?react'
import IconExport from '@/shared/assets/icons/icon_export.svg?react'
import IconFolderPlus from '@/shared/assets/icons/icon_folder_plus.svg?react'
import IconFullscreen from '@/shared/assets/icons/icon_fullscreen.svg?react'
import IconDots from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  addDocument,
  closeChatPanel,
  toggleFirstDocumentPanel,
  toggleThrirdDocumentsPanel,
} from '@/shared/store/slices/chat/documentPreviewSlice'
import { IconButton } from '@/shared/ui'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { useBoundary } from '@/shared/utils/useBoundary'
import { useSidebar } from '@/widgets/Sidebar'
import { Arrow } from '@radix-ui/react-menubar'
import { useEffect, useRef } from 'react'

import { IChatAiFile } from '../../types'

type ChatAiFileProps = {
  file: IChatAiFile
}

export const ChatAiFile = ({ file }: ChatAiFileProps) => {
  const dispatch = useAppDispatch()
  const { documents } = useTypedSelector(
    (state) => state.documentPreview
  )
  const { isSidebarOpen, closeSidebar } = useSidebar()
  const [toggleFavoriteDocument] =
    usePostToggleFavoriteDocumentMutation({})
  const boundaryElements = useBoundary('menu_boundary')

  const timeoutRef = useRef<number | null>(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const handleOpenDocument = () => {
    if (isSidebarOpen) {
      closeSidebar()
    }

    if (documents.length === 1) {
      dispatch(toggleFirstDocumentPanel(true))

      // Store the timeout ID in the ref
      timeoutRef.current = window.setTimeout(() => {
        dispatch(
          addDocument({
            id: file.id,
            name: file.name,
            chunks: file.chunks,
            status: file.status,
            requisites: file.requisites,
          })
        )
      }, 300)
    } else if (documents.length === 2) {
      dispatch(toggleThrirdDocumentsPanel(true))
      dispatch(
        addDocument({
          id: file.id,
          name: file.name,
          chunks: file.chunks,
          status: file.status,
          requisites: file.requisites,
        })
      )
    } else {
      dispatch(
        addDocument({
          id: file.id,
          name: file.name,
          chunks: file.chunks,
          status: file.status,
          requisites: file.requisites,
        })
      )
    }

    dispatch(closeChatPanel())
  }
  return (
    <Menubar
      key={file.id}
      className="flex items-center justify-stretch self-stretch"
    >
      <MenubarMenu>
        <div
          className="flex w-full flex-col items-stretch justify-start gap-1 rounded-[16px]
            border border-stroke-light-gray-2 bg-white p-4 shadow-shadow7"
        >
          <div className="flex items-center justify-between">
            <DocumentItemStatus
              status={file.status.code}
              label={file.status.name}
            />
            <MenubarTrigger
              asChild
              className="data-[state=open]:bg-base-100"
            >
              <IconButton
                className="h-6 w-6 -translate-y-[8px] translate-x-[8px] cursor-pointer rounded-lg
                  hover:scale-100 hover:bg-base-200"
              >
                <IconDots />
              </IconButton>
            </MenubarTrigger>
          </div>
          <div className="flex items-start justify-between">
            <Typography
              variant={'body'}
              className="mt-1 break-words text-start font-medium leading-[24px] text-black"
            >
              {file.name}
            </Typography>
          </div>

          {file.requisites ? (
            <ul
              className="list-disc flex-col items-stretch justify-start gap-1 pl-4
                text-base-700 marker:text-base-700"
            >
              <li>
                <div className="inline-flex w-full items-start justify-start">
                  <Typography
                    variant={'label'}
                    className="text-start font-medium"
                  >
                    {file.requisites}
                  </Typography>
                </div>
              </li>
            </ul>
          ) : null}
        </div>
        <MenubarContent
          collisionPadding={{ right: -20, top: 10, bottom: 15 }}
          className="pointer-events-auto visible z-50 flex min-w-[184px] max-w-[184px]
            flex-col items-stretch rounded-[16px] bg-white p-2 shadow-shadow2"
          align="end"
          alignOffset={-16}
          sideOffset={-3}
        >
          <Arrow fill="#fff" className="h-2 w-4" />

          <MenubarItem
            className="flex items-center justify-start gap-2 rounded-lg p-2
              hover:cursor-pointer hover:bg-base-100"
            onClick={handleOpenDocument}
            inset={true}
          >
            <IconFullscreen className="h-5 w-5" />
            <Typography
              variant="label"
              className="whitespace-nowrap font-normal text-black"
            >
              Открыть здесь
            </Typography>
          </MenubarItem>

          <MenubarItem
            asChild
            onClick={() => {
              const newTab = window.open('', '_blank')
              if (newTab) {
                newTab.document.write(`
            <!DOCTYPE html>
            <html lang="en">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>${file.name}</title>
                <style>
                  body {
                    font-family: 'Inter', sans-serif;
                    margin: 0;
                    padding: 20px;
                    color: #000;
                  }
                  h1 {
                    text-align: center;
                    font-size: 2em;
                    font-weight: bold;
                    margin-bottom: 20px;
                  }
                  p {
                    font-size: 1em;
                    line-height: 1.5;
                  }
                </style>
              </head>
              <body>
                <h1>${file.name}</h1>
              </body>
            </html>
          `)
                newTab.document.close()
              }
            }}
            className="gap-2 rounded-lg p-2 hover:cursor-pointer hover:bg-base-100"
          >
            <div
              className="flex items-center justify-start gap-2 rounded-lg p-2
                hover:cursor-pointer hover:bg-base-100"
            >
              <IconExport className="h-5 w-5" />

              <Typography
                className="font-normal text-black"
                variant="label"
              >
                В новом окне
              </Typography>
            </div>
          </MenubarItem>
          <MenubarItem
            disabled
            className="mb-1 flex items-center justify-start gap-2 rounded-lg p-2
              hover:cursor-pointer hover:bg-additional-light-blue"
            onClick={() =>
              toggleFavoriteDocument({ document_id: file.id })
            }
            inset={true}
          >
            <IconBookmarkBlack className="h-5 w-5" />
            <Typography
              variant="label"
              className="whitespace-nowrap font-normal text-black"
            >
              В избранное
            </Typography>
          </MenubarItem>
          <MenubarItem
            disabled
            className="bprder-stroke-light-gray-1 mt-1 flex items-center justify-start gap-3
              rounded-b-lg border-t-[1px] p-2 hover:cursor-pointer hover:bg-base-100"
            onClick={handleOpenDocument}
            inset={true}
          >
            <IconFolderPlus className="h-5 w-5" />
            <Typography
              variant="label"
              className="whitespace-nowrap font-normal text-black"
            >
              В папку
            </Typography>
          </MenubarItem>
          {/* {chats.documents && chats.documents.length >= 1 ? (
            <MenubarItem
              className="flex items-center justify-start gap-2 rounded-lg p-2
                hover:cursor-pointer hover:bg-base-100"
              onClick={handleOpenDocument}
              inset={true}
            >
              <IconRowVertical className="h-5 w-5" />
              <Typography
                
                className="whitespace-nowrap font-normal text-black"
              >
                Сравнить
              </Typography>
            </MenubarItem>
          ) : null} */}
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}
