import React, { useState } from 'react'

export const useDocumentInfoAction = () => {
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false)
  const handleInfoClick = () => {
    setIsInfoDialogOpen(true)
  }

  const handleInfoClose = () => {
    setIsInfoDialogOpen(false)
  }
  return {
    isInfoDialogOpen,
    handleInfoClick,
    handleInfoClose,
  }
}
