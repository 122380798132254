import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  removeAllDocuments,
  toggleDocumentPanel,
  toggleSecondDocumentPanel,
  toggleThrirdDocumentsPanel,
  updateHash,
} from '@/shared/store/slices/chat/documentPreviewSlice'
import { useSidebar } from '@/widgets/Sidebar'
import { useEffect, useRef, useState } from 'react'
import { ImperativePanelGroupHandle } from 'react-resizable-panels'
import { useLocation } from 'react-router-dom'

export const useDocumentPrevew = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const panelRef = useRef<ImperativePanelGroupHandle>(null)

  const { isSidebarOpen } = useSidebar()

  // Redux state
  const {
    documents,
    isChatOpen,
    isOpenDocumentsPanel,
    hash,
    isChangedFirstPanel,
    isChangedSecondPanel,
    isChangedThreePanels,
  } = useTypedSelector((state) => state.documentPreview)

  // Local state
  const [isDragging, setIsDragging] = useState(false)
  const [isVisibleSecondDocument, setIsVisibleSecondDocument] =
    useState(true)
  const [isVisibleThreePanel, setIsVisibleThreePanel] = useState(true)

  // We keep track of the old documents array for comparison
  const [oldDocuments, setOldDocuments] = useState(documents)

  // If any of these states means a panel is open
  const isAnyPanelOpen =
    isChatOpen ||
    documents.length === 2 ||
    isChangedFirstPanel ||
    isChangedSecondPanel

  /**
   * Toggles resizing, triggered by Panel onResize
   */
  const toggleResizePanel = () => {
    if (documents.length !== 1) {
      toggleDragging(true)
    }
  }

  /**
   * Toggles dragging state
   */
  const toggleDragging = (isDrag: boolean) => {
    setIsDragging(isDrag)
  }

  /**
   * Layout / Panel grouping logic
   * If the second panel changed or we have new documents, reset layout sizes
   */
  useEffect(() => {
    if (!panelRef.current) return

    // If the second panel was changed
    if (isChangedSecondPanel) {
      panelRef.current.setLayout([50, 50])
    }

    // If the first panel was changed, reset layout & stop dragging
    if (isChangedFirstPanel) {
      panelRef.current.setLayout([50, 50])
      toggleDragging(false)
      return
    }

    // If we previously had 2 documents and still have 2 documents but IDs changed
    if (documents.length === 2 && oldDocuments.length === 2) {
      const [docA, docB] = documents
      const [oldDocA, oldDocB] = oldDocuments
      if (docA.id !== oldDocA.id || docB.id !== oldDocB.id) {
        panelRef.current.setLayout([50, 50])
      }
      setOldDocuments(documents)
      toggleDragging(false)
    }

    // If the sidebar is closed, chat is open, but we only have 1 doc (or 0),
    // reset layout
    if (!isSidebarOpen && isChatOpen && documents.length !== 2) {
      panelRef.current.setLayout([50, 50])
      toggleDragging(false)
    }
  }, [
    panelRef,
    isChangedSecondPanel,
    isChangedFirstPanel,
    documents,
    oldDocuments,
    isSidebarOpen,
    isChatOpen,
  ])

  /**
   * If the second panel changed, temporarily hide it for 200ms,
   * then re-show it and toggle the redux flag
   */
  useEffect(() => {
    if (isChangedSecondPanel) {
      setIsVisibleSecondDocument(false)

      const timer = setTimeout(() => {
        setIsVisibleSecondDocument(true)
        dispatch(toggleSecondDocumentPanel(false))
      }, 200)

      return () => clearTimeout(timer)
    }
  }, [isChangedSecondPanel, dispatch])

  /**
   * If the third panel changed, temporarily hide it for 600ms,
   * then re-show it and toggle the redux flag
   */
  useEffect(() => {
    if (isChangedThreePanels) {
      setIsVisibleThreePanel(false)

      const timer = setTimeout(() => {
        setIsVisibleThreePanel(true)
        dispatch(toggleThrirdDocumentsPanel(false))
      }, 600)

      return () => clearTimeout(timer)
    }
  }, [isChangedThreePanels, dispatch])

  /**
   * If we have documents, open the document panel. Otherwise, close it.
   */
  useEffect(() => {
    dispatch(toggleDocumentPanel(documents.length > 0))
  }, [documents, dispatch])

  /**
   * Listen for changes in the URL path's last segment (hashId).
   * If it differs from the stored hash, remove all documents,
   * and update the redux store with the new hash.
   */
  useEffect(() => {
    const pathSegments = location.pathname.split('/')
    const hashId = pathSegments[pathSegments.length - 1]

    if (hashId !== hash) {
      dispatch(removeAllDocuments())
    }
    if (hashId) {
      dispatch(updateHash(hashId))
    }
  }, [location, hash, dispatch])

  return {
    documents,
    isChatOpen,
    isAnyPanelOpen,
    isOpenDocumentsPanel,
    isVisibleSecondDocument,
    isVisibleThreePanel,
    panelRef,
    isDragging,
    toggleDragging,
    toggleResizePanel,
  }
}
