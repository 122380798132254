import { IDocument } from '@/entities/document'

import { useAppDispatch } from '../store'
import { setCitationsMap } from '../store/slices/documents/documentsSlice'

export const useSetDocumentsCitations = (
  documents: IDocument[] | undefined
) => {
  const dispatch = useAppDispatch()
  if (documents) {
    dispatch(setCitationsMap(documents))
  }
}
