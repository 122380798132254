import IconChevronDuoLeft from '@/shared/assets/icons/icon_chevron_duo_left.svg?react'
import IconChevronDuoRight from '@/shared/assets/icons/icon_chevron_duo_right.svg?react'
import { usePaginationMobile } from '@/shared/hooks/usePaginationMobile'
import {
  IconButton,
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  Typography,
} from '@/shared/ui'
import { cn } from '@/shared/utils/common'

interface CustomPaginationMobileProps {
  currentPage: number
  totalPages: number
  perPage: number
  totalItems: number
}

export const CustomPaginationMobile = ({
  currentPage,
  totalPages,
  perPage,
  totalItems,
}: CustomPaginationMobileProps) => {
  const { visiblePages, handlePageChange, startItem, endItem } =
    usePaginationMobile({
      currentPage,
      totalPages,
      perPage,
      totalItems,
    })

  return (
    <div
      className="my-8 inline-flex w-full flex-col items-stretch justify-start gap-4
        self-center"
    >
      <div className="flex flex-col items-center justify-start gap-2">
        <Pagination>
          <PaginationContent className="flex items-center justify-center gap-3">
            {/* LEFT button: Go to FIRST page */}
            <PaginationItem className="group h-5 w-5">
              <IconButton
                disabled={currentPage === 1}
                className="group-hover:bg-base-100"
                onClick={(e) => {
                  e.preventDefault()
                  handlePageChange(1)
                }}
              >
                <IconChevronDuoLeft className="group-hover:bg-base-100" />
              </IconButton>
            </PaginationItem>

            <div className="flex items-center gap-[6px]">
              {/* 4 visible pages in the middle */}
              {visiblePages.map((pageNumber) => (
                <PaginationItem key={pageNumber}>
                  <PaginationLink
                    isActive={pageNumber === currentPage}
                    disabled={pageNumber === currentPage}
                    className="flex h-[40px] min-w-[40px] items-center justify-center rounded-xl p-3
                      text-center"
                    onClick={(e) => {
                      e.preventDefault()
                      handlePageChange(pageNumber)
                    }}
                  >
                    <Typography
                      variant="label"
                      className={cn(
                        currentPage === pageNumber
                          ? 'font-semibold text-white'
                          : 'font-normal'
                      )}
                    >
                      {pageNumber}
                    </Typography>
                  </PaginationLink>
                </PaginationItem>
              ))}
            </div>

            {/* RIGHT button: Go to LAST page */}
            <PaginationItem className="group h-5 w-5">
              <IconButton
                disabled={currentPage === totalPages}
                className="group-hover:bg-base-100"
                onClick={(e) => {
                  e.preventDefault()
                  handlePageChange(totalPages)
                }}
              >
                <IconChevronDuoRight className="group-hover:bg-base-100" />
              </IconButton>
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>

      <div className="flex flex-col items-stretch justify-center gap-2">
        <Typography
          variant="subtitle"
          className="text-center text-[12px] font-medium leading-[16px] text-base-500"
        >
          Показано{' '}
          {Intl.NumberFormat('ru-RU').format(
            currentPage * perPage - perPage + 1
          )}
          -
          {Intl.NumberFormat('ru-RU').format(
            Math.min(currentPage * perPage, totalItems)
          )}{' '}
          из {Intl.NumberFormat('ru-RU').format(totalItems)}
        </Typography>
      </div>
    </div>
  )
}
