import IconErrorOrange from '@/shared/assets/icons/icon_error_orange.svg?react'
import { Button, Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

type ErrorNotificationProps = {
  onActionClick: () => void
  message?: string
  description?: string
  position?: 'top' | 'bottom'
  offset?: number
  actionLabel?: string
  className?: string
}

export const ErrorNotification = ({
  onActionClick,
  message = 'Ошибка',
  description = 'Что-то пошло не так. Попробуйте еще раз.',
  position = 'bottom',
  offset = 28,
  actionLabel = 'Повторить',
  className,
}: ErrorNotificationProps) => {
  const positionClass =
    position === 'top' ? `top-${offset}` : `bottom-${offset}`

  return (
    <div
      className={cn(
        `absolute z-[120] flex w-[560px] items-center justify-end gap-1
        rounded-[16px] border border-base-200 bg-white py-3 pl-[46px] pr-4
        shadow-shadow8`,
        positionClass,
        className
      )}
    >
      <IconErrorOrange className="absolute left-[14px] top-[14px]" />
      <div className="flex flex-1 flex-col items-start justify-start gap-1">
        <Typography variant="label" className="font-semibold">
          {message}
        </Typography>
        <Typography variant="label" className="font-normal">
          {description}
        </Typography>
      </div>
      <Button
        variant="outlined"
        onClick={onActionClick}
        className="h-8 rounded-[8px] border-base-200 px-3 text-[14px] font-medium
          leading-[20px] text-base-950"
      >
        {actionLabel}
      </Button>
    </div>
  )
}
