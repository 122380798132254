import { DocumentBaseContainer } from '@/widgets/DocumentsBase/DocumentBaseContainer'
import { DocumentBaseFavoritesContainer } from '@/widgets/DocumentsBase/DocumentBaseFavoritesContainer'
import { DocumentBaseFavoritesContainerMobile } from '@/widgets/DocumentsBase/DocumentBaseFavoritesContainerMobile'
import { useOutletContext } from 'react-router-dom'

export const DocumentsFavoritePage = () => {
  const { isMobile } = useOutletContext<{
    isMobile: boolean
  }>()

  if (isMobile) {
    return <DocumentBaseFavoritesContainerMobile />
  }

  return <DocumentBaseFavoritesContainer />
}
