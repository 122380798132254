// useChatHistoryBookmark.ts
import { useState } from 'react'

export const useChatHistoryBookmark = () => {
  const [isBookmarkHovered, setIsBookmarkHovered] = useState(false)

  const handleMouseEnter = () => setIsBookmarkHovered(true)
  const handleMouseLeave = () => setIsBookmarkHovered(false)
  const handleClick = () => setIsBookmarkHovered(false)

  return {
    isBookmarkHovered,
    handleMouseEnter,
    handleMouseLeave,
    handleClick,
  }
}
