import { useEffect, useState } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { useToggleModal } from './useToggleModal'

export const useDocumentMenuActions = () => {
  const { isOpen, toggleOpen } = useToggleModal()
  const [titleHeight, setTitleHeight] = useState(0)
  const [searchParams] = useSearchParams()
  const { id } = useParams()
  const navigate = useNavigate()
  const language = searchParams.get('language')

  const handleChangeMainDocumentLanguage = (value: string) => {
    navigate(`/documents/${id}?language=${value.toUpperCase()}`)
  }

  useEffect(() => {
    const docTitleElement = document.getElementById(
      'document-id-title'
    )
    if (docTitleElement) {
      const heightToTop = docTitleElement.getBoundingClientRect().top

      if (heightToTop) {
        setTitleHeight(heightToTop)
      }
    }
  }, [])

  return {
    isOpen,
    toggleOpen,
    handleChangeMainDocumentLanguage,
    language,
  }
}
