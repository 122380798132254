import { useFilters } from '@/entities/filter/models/useFilters'
import { CategoriesPage } from '@/entities/filter/types'
import { MobileDocumentsSearchInput } from '@/features/filters'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { cn } from '@/shared/utils/common'
import { CourtBaseBodyContainer } from '@/widgets/CourtBase/CourtBaseBodyContainer'
import { CourtBaseBodyContainerMobile } from '@/widgets/CourtBase/CourtBaseBodyContainerMobile'
import { WidgetCourtBaseFiltersMobile } from '@/widgets/CourtBase/WidgetCourtBaseFiltersMobile'
import { WidgetCourtBaseSearchInput } from '@/widgets/CourtBase/WidgetCourtBaseSearchInput'
import { WidgetCategories } from '@/widgets/WidgetCategories'
import { WidgetCourtBaseFilters } from '@/widgets/WidgetCourtBaseFilters'

export const CourtBasePage = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  const { handleMenuToggle, isMenuOpen, categoriesData } = useFilters(
    {
      categoriesPage: 'court-base' as CategoriesPage,
    }
  )

  if (isMobile) {
    return (
      <div
        className={cn(
          `flex h-full flex-col items-stretch justify-start overflow-y-auto
          overflow-x-hidden px-5 pt-[64px]`
        )}
      >
        <div className="mb-[10px] flex justify-center self-stretch">
          <MobileDocumentsSearchInput />
        </div>
        <WidgetCourtBaseFiltersMobile />
        {/* Divider */}
        <div className="-mx-5 mb-2 h-2 shrink grow-0 bg-[#F0F0F0]" />
        {/* Body container */}
        <CourtBaseBodyContainerMobile />
      </div>
    )
  }

  return (
    <>
      {/* Categories */}
      <WidgetCategories
        categoriesData={categoriesData}
        handleMenuToggle={handleMenuToggle}
        isMenuOpen={isMenuOpen}
        pageTitle="court-base"
      />

      {/* Filters */}
      <div
        className={cn(
          `fixed right-6 top-20 z-50 -mt-4 bg-custom-light pt-4 transition-all
          duration-300 ease-in-out`,
          isMenuOpen ? 'left-[336px]' : 'left-[88px]'
        )}
        style={{ backdropFilter: 'blur(6px)' }}
      >
        <div className="rounded-t-3xl border-x-[1px] border-t-[1px] bg-white pt-1">
          <WidgetCourtBaseSearchInput />
          <WidgetCourtBaseFilters totalDocuments={856000} />
        </div>
      </div>

      {/* Main Layout */}
      <div
        className={cn(
          `relative flex w-full flex-1 items-stretch justify-start gap-6
          bg-transparent px-6 pt-20`
        )}
      >
        {/* Empty div for controlling animations */}
        <div
          className={cn(
            'overflow-hidden transition-all duration-300 ease-in-out',
            isMenuOpen ? 'w-[288px]' : 'w-10'
          )}
        />
        <div
          className={cn(
            `menu_boundary relative flex h-full flex-1 flex-col overflow-auto
            rounded-t-3xl bg-transparent pt-[104px]`
          )}
        >
          <CourtBaseBodyContainer isMobile={isMobile} />
        </div>
      </div>
    </>
  )
}
