import {
  DocumentData,
  DocumentLanguages,
} from '@/entities/document/types'
import { DocumentLanguageMenu } from '@/features/document/ui/DocumentLanguageMenu'
import IconBookmarkBlack from '@/shared/assets/icons/icon_bookmark_back.svg?react'
import IconCommentsDark from '@/shared/assets/icons/icon_comments_dark.svg?react'
import IconContentDark from '@/shared/assets/icons/icon_content_dark.svg?react'
import IconDownloadDark from '@/shared/assets/icons/icon_download_dark.svg?react'
import IconEyeDarkSmallClosed from '@/shared/assets/icons/icon_eye_small_closed.svg?react'
import IconEyeDarkSmall from '@/shared/assets/icons/icon_eye_small_dark.svg?react'
import IconInfoItallicDark from '@/shared/assets/icons/icon_info_itallic_dark.svg?react'
import IconRowDark from '@/shared/assets/icons/icon_row_dark.svg?react'
import IconSearch from '@/shared/assets/icons/icon_search_grey.svg?react'
import IconSmallcapsDarkMedium from '@/shared/assets/icons/icon_smallcaps_dark_medium.svg?react'
import { IconButton } from '@/shared/ui'
import { MenubarItem } from '@/shared/ui/Menubar'
import { cn } from '@/shared/utils/common'
import { Arrow } from '@radix-ui/react-menubar'

import { useDocumentVersionMenu } from '../../hooks/useDocumentVersionMenu'

interface DocumentVersionMenuProps {
  toggleMenu: () => void
  documentId: number
  openVersionSelect: () => void
  isSecond: boolean
  dataLanguages: DocumentLanguages[] | undefined
}

export const DocumentVersionMenu = ({
  toggleMenu,
  documentId,
  openVersionSelect,
  isSecond,
  dataLanguages,
}: DocumentVersionMenuProps) => {
  const {
    handleDownload,
    toggleShowDocumentVersionsChunks,
    isShownDocumentVersionsChunks,
    toggleIsDocumentContentsOpen,
    isOpenMaindDocumentVersionSelect,
    languages,
    handleChangeLanguageVersions,
  } = useDocumentVersionMenu(documentId.toString(), isSecond)

  return (
    <div className="flex w-[440px] flex-col items-stretch gap-3">
      <Arrow fill="#fff" className="h-2 w-4" />
      <div
        className="flex w-[100%] flex-col gap-3 overflow-hidden rounded-[16px]
          border-[1px] border-base-100 bg-white p-3"
      >
        <div className="relative">
          <input
            placeholder="Поиск по  документу..."
            className={cn(
              `border-input placeholder:font-inter flex h-[32px] w-full
              rounded-[20px] border bg-[#F1F2F4] pl-4 pr-2 text-sm shadow-sm
              transition-colors file:border-0 file:bg-transparent file:text-sm
              file:font-medium placeholder:text-sm placeholder:font-medium
              placeholder:leading-5 placeholder:text-[#67707E]
              focus-visible:outline-none focus-visible:ring-0
              focus-visible:ring-base-900 disabled:cursor-not-allowed
              disabled:opacity-50`
            )}
          />
          <IconButton className="absolute right-2 top-0 h-8 w-8 rounded-[16px] hover:scale-100">
            <IconSearch className="h-5 w-5" />
          </IconButton>
        </div>
        <div className="flex max-w-[416px] items-center gap-[12px]">
          <MenubarItem className="p-0">
            <IconButton
              className="h-10 w-10 px-2 py-2 hover:bg-base-100"
              onClick={toggleIsDocumentContentsOpen}
              disabled
            >
              <IconContentDark className="h-6 w-6" />
            </IconButton>
          </MenubarItem>

          <IconButton
            className={cn(
              'relative h-10 w-10 px-2 py-2 hover:bg-base-100',
              isShownDocumentVersionsChunks && 'bg-base-100'
            )}
            onClick={toggleShowDocumentVersionsChunks}
          >
            <IconCommentsDark className="h-6 w-6" />
            {isShownDocumentVersionsChunks ? (
              <IconEyeDarkSmallClosed className="absolute left-[26px] top-[26px]" />
            ) : (
              <IconEyeDarkSmall className="absolute left-[26px] top-[26px]" />
            )}
          </IconButton>

          {dataLanguages && dataLanguages.length && (
            <DocumentLanguageMenu
              currentLanguage={isSecond ? languages[1] : languages[0]}
              languages={dataLanguages}
              handleChange={handleChangeLanguageVersions}
              isVersions
            />
          )}
          <MenubarItem className="p-0">
            <IconButton
              className="h-10 w-10 px-2 py-2 hover:bg-base-100"
              disabled
            >
              <IconSmallcapsDarkMedium className="h-6 w-6" />
            </IconButton>
          </MenubarItem>

          <MenubarItem className="p-0">
            <IconButton
              className={cn(
                'h-10 w-10 px-2 py-2 hover:bg-base-100',
                isOpenMaindDocumentVersionSelect && 'bg-base-100'
              )}
              onClick={openVersionSelect}
            >
              <IconRowDark className="h-6 w-6" />
            </IconButton>
          </MenubarItem>

          <MenubarItem className="p-0">
            <IconButton
              className="h-10 w-10 px-2 py-2 hover:bg-base-100"
              disabled
            >
              <IconInfoItallicDark className="h-6 w-6" />
            </IconButton>
          </MenubarItem>

          <MenubarItem className="p-0">
            <IconButton
              className="h-10 w-10 px-2 py-2 hover:bg-base-100"
              disabled
            >
              <IconBookmarkBlack className="h-6 w-6" />
            </IconButton>
          </MenubarItem>

          <MenubarItem className="p-0">
            <IconButton
              className="h-10 w-10 px-2 py-2 hover:bg-base-100"
              onClick={handleDownload}
            >
              <IconDownloadDark className="h-6 w-6" />
            </IconButton>
          </MenubarItem>
        </div>
      </div>
    </div>
  )
}
