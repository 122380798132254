import { DocumentLanguages } from '@/entities/document/types'
import {
  useGetDocumentByIdQuery,
  usePostToggleFavoriteDocumentMutation,
} from '@/shared/api/documents'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  closeChatPanel,
  removeDocument,
  toggleDocumentPanel,
  toggleSecondDocumentPanel,
  toggleThrirdDocumentsPanel,
} from '@/shared/store/slices/chat/documentPreviewSlice'
import { setPanelsLanguages } from '@/shared/store/slices/documents/documentsSlice'
import { useToast } from '@/shared/ui/Toast/useToast'
import { useEffect, useRef, useState } from 'react'

export const useDocumentPanel = (
  documentId: number,
  isVisibleThreePanel: boolean,
  isDragging: boolean,
  index: number
) => {
  const dispatch = useAppDispatch()
  const { documents } = useTypedSelector(
    (state) => state.documentPreview
  )

  const { panelLanguages } = useTypedSelector(
    (state) => state.documents
  )

  const isFirstDocument = index === 0
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const savedScrollPosition = useRef<number>(0)
  const [toggleFavoriteDocument] =
    usePostToggleFavoriteDocumentMutation({})
  const [isInfoDialogOpen, setIsInfoDialogOpen] =
    useState<boolean>(false)
  const { toast } = useToast()
  const rightLanguage = isFirstDocument
    ? panelLanguages[0]
    : panelLanguages[1]
  // Fetch the document data here
  const { data, error, isLoading, isFetching } =
    useGetDocumentByIdQuery({
      id: Number(documentId),
      language: rightLanguage as DocumentLanguages,
    })

  // Handle error state with a toast notification
  useEffect(() => {
    if (error) {
      toast({
        title: 'Ошибка при загрузке документа',
        description:
          'Не удалось загрузить документ. Попробуйте позже.',
        variant: 'error',
      })
    }
  }, [error, toast])

  useEffect(() => {
    if (scrollContainerRef.current && !isDragging) {
      scrollContainerRef.current.scrollTop =
        savedScrollPosition.current
    }
  }, [isDragging])

  const handleInfoClick = () => {
    setIsInfoDialogOpen((prev) => !prev)
  }

  const handleRemoveDocument = (id: number, index: number) => {
    if (documents.length === 1) {
      dispatch(toggleDocumentPanel(false))
      setTimeout(() => dispatch(removeDocument(id)), 150)
    } else {
      if (index === 0) {
        dispatch(toggleThrirdDocumentsPanel(true))
      }
      dispatch(toggleSecondDocumentPanel(true))
      dispatch(closeChatPanel())
      setTimeout(() => {
        dispatch(removeDocument(id))
      }, 300)
    }
  }

  const handleChangePanelLanguage = (value: string) => {
    const updatedLanguages: [string, string] = isFirstDocument
      ? [value, panelLanguages[1]]
      : [panelLanguages[0], value]
    dispatch(setPanelsLanguages(updatedLanguages))
  }

  const handleScroll = () => {
    if (scrollContainerRef.current && !isDragging) {
      savedScrollPosition.current =
        scrollContainerRef.current.scrollTop
    }
  }

  const handleToggleFavoriteClick = (docId: number) => {
    toggleFavoriteDocument({ document_id: docId })
  }

  return {
    data,
    error,
    isLoading,
    isFetching,
    handleScroll,
    handleInfoClick,
    scrollContainerRef,
    handleRemoveDocument,
    handleToggleFavoriteClick,
    isInfoDialogOpen,
    handleChangePanelLanguage,
  }
}
