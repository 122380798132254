import { IDocument, Version } from '@/entities/document'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface DocumentsState {
  returnUrl: string | null
  versions: [Version, Version]
  allVersions: Version[]
  citationsMap: Record<number, string[]>
  panelLanguages: [string, string]
  mobileLanguage: string
  avialableMobileLanguages: string[]
}

const initialState: DocumentsState = {
  returnUrl: null,
  versions: [
    {
      id: 0,
      published_at: '',
    },
    {
      id: 0,
      published_at: '',
    },
  ],
  allVersions: [],
  citationsMap: {},
  panelLanguages: ['ru', 'ru'],
  mobileLanguage: 'ru',
  avialableMobileLanguages: [],
}

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocumentsReturnUrl: (
      state,
      action: PayloadAction<{ url: string }>
    ) => {
      state.returnUrl = action.payload.url
    },
    clearDocumentsReturnUrl: (state) => {
      state.returnUrl = null
    },
    setDocumentsVersions: (
      state,
      action: PayloadAction<[Version, Version]>
    ) => {
      state.versions = action.payload
    },
    resetDocumentsVersions: (state) => {
      state.versions = [
        {
          id: 0,
          published_at: '',
        },
        {
          id: 0,
          published_at: '',
        },
      ]
    },
    setDocumentsAllVersions: (
      state,
      action: PayloadAction<Version[]>
    ) => {
      state.allVersions = action.payload
    },
    clearDocumentsAllVersions: (state) => {
      state.allVersions = []
    },
    setCitationsMap: (state, action: PayloadAction<IDocument[]>) => {
      state.citationsMap = action.payload.reduce(
        (map, document) => {
          if (document.citation_paragraphs) {
            map[document.id] = document.citation_paragraphs
          }
          return map
        },
        {} as Record<number, string[]>
      )
    },
    clearCitationsMap: (state) => {
      state.citationsMap = {}
    },

    setPanelsLanguages: (
      state,
      action: PayloadAction<[string, string]>
    ) => {
      state.panelLanguages = action.payload
    },

    setMobilesLanguage: (state, action: PayloadAction<string>) => {
      state.mobileLanguage = action.payload
    },

    setAvialableMobileLanguages: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.avialableMobileLanguages = action.payload
    },
  },
})

export const {
  setDocumentsReturnUrl,
  clearDocumentsReturnUrl,
  resetDocumentsVersions,
  setDocumentsVersions,
  setDocumentsAllVersions,
  clearDocumentsAllVersions,
  setCitationsMap,
  clearCitationsMap,
  setPanelsLanguages,
  setMobilesLanguage,
  setAvialableMobileLanguages,
} = documentsSlice.actions

export default documentsSlice.reducer
