import { DocumentChapter } from '@/entities/document'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import IconExitLeft from '@/shared/assets/icons/icon_exit_left.svg?react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  IconButton,
  Typography,
} from '@/shared/ui'

interface WidgetDocumentChaptersProps {
  isOpen: boolean
  chapters?: DocumentChapter[]
  toggleDocumentContentsOpen: () => void
}
export const WidgetDocumentChapters = ({
  isOpen,
  chapters,
  toggleDocumentContentsOpen,
}: WidgetDocumentChaptersProps) => {
  if (!isOpen) {
    return null
  }

  return (
    <div
      className="bg- fixed left-6 top-[148px] flex max-h-[600px] w-[326px] flex-col
        items-center justify-start gap-4 rounded-xl bg-bg-light-white-1"
      style={{
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
      }}
    >
      {/* header */}
      <div
        className="flex w-full items-center justify-between border-b
          border-b-stroke-light-gray-1 bg-white px-4 py-3"
      >
        <Typography
          variant="subtitle"
          className="font-semibold text-text-light-black-4"
        >
          Содержание
        </Typography>
        <IconButton onClick={toggleDocumentContentsOpen}>
          <IconExitLeft />
        </IconButton>
      </div>
      {/* body */}
      <div className="w-full p-4">
        {chapters?.map((chapter, index) => (
          <div
            key={chapter.paragraph_id + index}
            className="flex items-center"
          >
            <Typography variant={'body'}>{chapter.title}</Typography>
          </div>
        ))}
      </div>
      {/* <Accordion type="single" collapsible className="w-full p-4">
        {chapters.map((chapter, index) => (
          <AccordionItem
            key={chapter.paragraph_id + index}
            value={index.toString()}
          >
            <AccordionTrigger
              iconPosition="left"
              iconSvg={<IconChevronRight />}
            >
              {chapter.title}
            </AccordionTrigger>
            <AccordionContent>
              <Typography variant={'body'}>
                {chapter.title}
              </Typography>
            </AccordionContent>
          </AccordionItem>
        ))}
       </Accordion> */}
    </div>
  )
}
