import { useDownloadDocument } from '@/shared/hooks/useDownloadDocument'
import { useEffect, useState } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { useDocumentContents } from './useDocumentContent'
import { useToggleModal } from './useToggleModal'

export const useDocumentVersionMenu = (
  documentId: string | undefined,
  isSecond: boolean
) => {
  const { isOpen, toggleOpen } = useToggleModal()
  const navigate = useNavigate()
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const language = searchParams.get('language')
  const languagesParam = searchParams.get('languages')
  const versionIds = searchParams.get('ids')
  const highlightParagraphs = searchParams.get('highlight_paragraphs')

  const languages = languagesParam ? languagesParam.split(',') : []

  const { handleDownload } = useDownloadDocument(documentId)
  const [
    isOpenMaindDocumentVersionSelect,
    setIsOpenMaindDocumentVersionSelect,
  ] = useState(false)

  const {
    isOpen: isDocumentContentsOpen,
    toggleIsOpen: toggleIsDocumentContentsOpen,
    isShowFirstVersionChunks,
    isShowSecondVersionChunks,
    toggleFirstVersionChunks,
    toggleSecondVersionChunks,
  } = useDocumentContents()

  const handleChangeLanguageVersions = (value: string) => {
    const searchParams = new URLSearchParams()
    const updatedValue = value === 'KZ' ? 'KK' : value
    searchParams.set('language', language || 'RU')
    if (versionIds) {
      searchParams.set('ids', versionIds)
    }

    const updatedLanguages = isSecond
      ? [languages[0], updatedValue]
      : [updatedValue, languages[1]]

    searchParams.set('languages', updatedLanguages.join(','))
    if (highlightParagraphs) {
      searchParams.set('highlight_paragraphs', highlightParagraphs)
    }

    navigate(`/documents/${id}/versions?${searchParams.toString()}`)
  }

  const isShownDocumentVersionsChunks = isSecond
    ? isShowSecondVersionChunks
    : isShowFirstVersionChunks
  const toggleShowDocumentVersionsChunks = isSecond
    ? toggleSecondVersionChunks
    : toggleFirstVersionChunks
  return {
    isOpen,
    toggleOpen,
    handleDownload,
    toggleShowDocumentVersionsChunks,
    isShownDocumentVersionsChunks,
    toggleIsDocumentContentsOpen,
    isOpenMaindDocumentVersionSelect,
    handleChangeLanguageVersions,
    language,
    languages,
  }
}
