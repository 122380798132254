import { useDocuments } from '@/entities/document/models/useDocuments'
import { CustomFilterInput } from '@/entities/filter'
import { Subtypes } from '@/entities/filter/types'
import IconChevronDown from '@/shared/assets/icons/icon_chevron_down.svg?react'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'
import { forwardRef, useImperativeHandle } from 'react'

import { useSubtypesFilterMobile } from '../../hooks/useSubtypesFilterMobile'
import { FilterAccordion } from '../FilterAccordion'
import { FilterAccordionMobile } from '../FilterAccordionMobile'

type FilterMenuMobileRef = {
  handleApply: () => void
  handleReset: () => void
}

export const FilterMenuMobile = forwardRef<
  FilterMenuMobileRef,
  Subtypes
>(({ categoryType, searchParam }, ref) => {
  const {
    subtypes,
    isFiltered,
    allSelected,
    setSubtypes,
    handleApply,
    handleReset,
    setIsFiltered,
    handleSelectAll,
    selectedIndices,
    handleCheckboxChange,
    setTotalSubtypesCount,
  } = useSubtypesFilterMobile({ categoryType, searchParam })
  const { data } = useDocuments()

  useImperativeHandle(ref, () => ({
    handleApply,
    handleReset,
  }))
  return (
    <div className="relative flex flex-col overflow-auto">
      <div
        className="flex flex-col gap-8"
        onClick={(e) => e.preventDefault()}
      >
        <CustomFilterInput
          setIsFiltered={setIsFiltered}
          setTotalCount={setTotalSubtypesCount}
          setFilterItems={setSubtypes}
          categoryType={categoryType}
        />
        <div className="flex items-center justify-start gap-3 py-[10px] pr-3">
          <Checkbox
            variant={'secondary'}
            size={'large'}
            checked={allSelected}
            onCheckedChange={handleSelectAll}
            id="select-all-checkbox"
          />
          <Typography variant={'body'} className="flex-1">
            Все{' '}
            <span className="text-sm font-normal leading-5 text-[#747474]">
              {data?.meta?.total ? `(${data.meta.total})` : ''}
            </span>
          </Typography>
        </div>
      </div>
      <FilterAccordionMobile
        parentId={-1}
        level={0}
        subtypes={subtypes}
        isFiltered={isFiltered}
        selectedIndices={selectedIndices}
        handleCheckboxChange={handleCheckboxChange}
      />
    </div>
  )
})
// TODO: Finish transitioning components to mobile
