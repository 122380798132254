import { IChatHistoryItem } from '@/entities/chat/types'
import { useGetChatsQuery } from '@/shared/api/chat'
import { useTypedSelector } from '@/shared/store'
import { ToastAction } from '@/shared/ui/Toast/toast'
import { useToast } from '@/shared/ui/Toast/useToast'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  isFavoritesPage: boolean
}
function parseChatTimestamp(timestamp: string): Date {
  const isoLikeString = timestamp.replace(' ', 'T')
  return new Date(isoLikeString)
}

export const useChatHistory = ({ isFavoritesPage }: Props) => {
  const navigate = useNavigate()
  const { toast } = useToast()

  const { isLoading, data, error, isError } = useGetChatsQuery({
    page: 1,
    onlyFavorite: isFavoritesPage,
  })

  const { documents } = useTypedSelector(
    (state) => state.documentPreview
  )

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Что-то пошло не так',
        description:
          'Перезагрузите страницу, чтобы все заработало. Если ошибка повторяется, обратитесь в поддержку.',
        variant: 'error',
        action: (
          <ToastAction
            altText="Нотификация об ошибке"
            className="cursor-pointer"
            onClick={() => window.location.reload()}
          >
            Перезагрузить
          </ToastAction>
        ),
      })
    }
  }, [isError, toast])

  const chats = data?.chats || []

  const handleFavoritesToggled = () => {
    if (isFavoritesPage) {
      navigate('/')
    } else {
      navigate('/chats/favorites')
    }
  }

  const today = new Date().toISOString().split('T')[0]
  const yesterday = new Date(Date.now() - 86400000)
    .toISOString()
    .split('T')[0]

  // 2. Sort "today" chats
  const todayChats = chats
    .filter((chat) => chat.last_used_at.startsWith(today))
    .sort((a, b) => {
      return (
        parseChatTimestamp(b.last_used_at).getTime() -
        parseChatTimestamp(a.last_used_at).getTime()
      )
    })

  // 3. Sort "yesterday" chats
  const yesterdayChats = chats
    .filter((chat) => chat.last_used_at.startsWith(yesterday))
    .sort((a, b) => {
      return (
        parseChatTimestamp(b.last_used_at).getTime() -
        parseChatTimestamp(a.last_used_at).getTime()
      )
    })

  // 4. Sort "others" by date group
  const otherChats = chats.filter(
    (chat) =>
      !chat.last_used_at.startsWith(today) &&
      !chat.last_used_at.startsWith(yesterday)
  )
  const otherChatsByDate = otherChats.reduce(
    (acc, chat) => {
      const date = chat.last_used_at.split(' ')[0]
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(chat)
      return acc
    },
    {} as Record<string, IChatHistoryItem[]>
  )

  // 5. Sort each group descending by timestamp
  Object.keys(otherChatsByDate).forEach((date) => {
    otherChatsByDate[date].sort((a, b) => {
      return (
        parseChatTimestamp(b.last_used_at).getTime() -
        parseChatTimestamp(a.last_used_at).getTime()
      )
    })
  })

  return {
    isLoading,
    todayChats,
    yesterdayChats,
    otherChatsByDate,
    isFavoritesPage,
    handleFavoritesToggled,
    documents,
  }
}
