import { useTypedSelector } from '@/shared/store'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

import { initialSegmentedItems } from '../../consts'
import { EPlatformPage, SegmentedItem } from '../../types'

interface SegmentedProps extends HTMLAttributes<HTMLDivElement> {
  items?: SegmentedItem[]
  value?: EPlatformPage
  handleChange?: (value: EPlatformPage) => void
}

export const SegmentedNavbar = forwardRef<
  HTMLDivElement,
  SegmentedProps
>(
  (
    {
      items = initialSegmentedItems,
      value: controlledValue,
      handleChange,
      className,
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState<EPlatformPage>(
      controlledValue ?? items[0]?.value ?? EPlatformPage.Assistant
    )

    const currentValue = controlledValue ?? internalValue

    const navigate = useNavigate()
    const chatReturnUrl = useTypedSelector(
      (state) => state.chats.returnUrl
    )
    const documentsReturnUrl = useTypedSelector(
      (state) => state.documents.returnUrl
    )

    const handleClick = (itemValue: EPlatformPage) => {
      if (controlledValue === undefined) {
        setInternalValue(itemValue)
      }
      handleChange?.(itemValue)

      // Navigation logic based on the selected segment
      if (itemValue === EPlatformPage.Assistant) {
        // AI Assistant segment
        if (chatReturnUrl) {
          navigate(chatReturnUrl)
          return
        } else {
          navigate('/')
          return
        }
      }

      if (itemValue === EPlatformPage.Documents) {
        // Documents segment
        if (documentsReturnUrl) {
          navigate(documentsReturnUrl)
          return
        } else {
          navigate('/documents')
          return
        }
      }

      if (itemValue === EPlatformPage.CourtBase) {
        // Court-base segment
        // If you have a returnUrl for court-base, implement similarly.
        // Otherwise, just go to the default route.
        navigate('/court-base')
        return
      }
    }

    return (
      <div
        className={cn(
          `inline-flex gap-4 self-center rounded-full border-[1px]
          border-[#2e323808] bg-base-100 p-1`,
          className
        )}
        ref={ref}
        {...props}
      >
        {items.map((item) => (
          <button
            key={item.value}
            type="button"
            disabled={item.disabled || item.value === currentValue}
            onClick={() => handleClick(item.value)}
            className={cn(
              `flex items-center justify-start gap-[6px] whitespace-nowrap
              rounded-full py-1 pl-[9px] pr-3 transition-colors duration-200`,
              {
                'bg-base-20 shadow-shadow3':
                  item.value === currentValue,
                'bg-transparent shadow-none':
                  item.value !== currentValue,
              }
            )}
          >
            {item.icon && item.iconActive
              ? item.value === currentValue
                ? item.iconActive
                : item.icon
              : null}
            <Typography
              variant={'label'}
              className={cn('font-medium', {
                'text-base-950': item.value === currentValue,
                'text-gray-500': item.value !== currentValue,
              })}
            >
              {item.label}
            </Typography>
          </button>
        ))}
      </div>
    )
  }
)

SegmentedNavbar.displayName = 'SegmentedNavbar'
