import IconBackArrowGray from '@/shared/assets/icons/icon_back_arrow_gray.svg?react'
import { useAppDispatch } from '@/shared/store'
import {
  resetSignupCompanySteps,
  updateSignupData,
} from '@/shared/store/slices/auth/signupCompanySlice'
import { Button } from '@/shared/ui/Button'
import { CardContent, CardFooter } from '@/shared/ui/Card'
import { IconButton } from '@/shared/ui/IconButton'
import { Input } from '@/shared/ui/Input'
import { Typography } from '@/shared/ui/Typography'
import { Controller, FormProvider } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { useSignupCompanyOTP } from '../../models/useSignupCompanyOTP'

export const SignUpCompanyOTP = () => {
  const dispatch = useAppDispatch()
  const {
    methods,
    canResend,
    errors,
    handleResendCode,
    onSubmit,
    email,
    timeLeft,
  } = useSignupCompanyOTP()

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <IconButton
          className="absolute left-4 top-4"
          onClick={() => dispatch(resetSignupCompanySteps())}
        >
          <IconBackArrowGray />
        </IconButton>
        <CardContent className="flex flex-col gap-4">
          <Controller
            name="email"
            control={methods.control}
            defaultValue={email}
            rules={{ required: 'Обязательное поле' }}
            render={({ field }) => (
              <Input
                {...field}
                label="Email"
                error={errors.email?.message}
                placeholder="email@gmail.com"
              />
            )}
          />
          {email && (
            <>
              <Controller
                name="password"
                control={methods.control}
                defaultValue=""
                rules={{ required: 'Обязательное поле' }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Пароль из почты"
                    type="password"
                    error={errors.password?.message}
                    placeholder="••••••••"
                  />
                )}
              />
              {canResend ? (
                <Typography
                  variant={'subtitle'}
                  className="-mt-2 cursor-pointer self-end text-base-900 underline"
                  onClick={() => handleResendCode(email)}
                >
                  Отправить еще раз
                </Typography>
              ) : (
                <Typography
                  variant={'subtitle'}
                  className="-mt-2 self-end"
                >
                  Отправить еще раз можно через {timeLeft} секунд
                </Typography>
              )}
            </>
          )}
        </CardContent>
        <CardFooter className="mt-8 flex flex-col items-center gap-5 lg:gap-8">
          <Button
            variant={'primary'}
            className="w-full"
            disabled={!email || !methods.watch('password')}
            type="submit"
          >
            Создать аккаунт
          </Button>
          <Typography className="text-center text-xs text-gray-500">
            Уже есть аккаунт?{' '}
            <Link
              to={'/signin'}
              className="font-medium text-base-900 underline"
            >
              Войти
            </Link>
          </Typography>
          <Typography
            variant={'subtitle'}
            className="self-stretch text-center font-normal text-[#9EA2AA]"
          >
            Используя CODEX, Вы соглашаетесь с{' '}
            <span className={'underline'}>
              Политикой конфиденциальности
            </span>
          </Typography>
        </CardFooter>
      </form>
    </FormProvider>
  )
}
