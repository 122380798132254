import { DocumentBaseContainer } from '@/widgets/DocumentsBase/DocumentBaseContainer'
import { DocumentBaseContainerMobile } from '@/widgets/DocumentsBase/DocumentBaseContainerMobile'
import { useOutletContext } from 'react-router-dom'

export const DocumentsPage = () => {
  const { isMobile } = useOutletContext<{
    isMobile: boolean
  }>()

  if (isMobile) {
    return <DocumentBaseContainerMobile />
  }

  return <DocumentBaseContainer />
}
