import { ChatDocumentSkeleton } from '@/entities/chat/ui/ChatDocumentSkeleton'
import { useGetDocumentByIdQuery } from '@/shared/api/documents'
import { useEffect, useMemo, useRef, useState } from 'react'

import './styles.css'

interface MobileRawHtmlRendererProps {
  documentId: number
}

export const MobileRawHtmlRenderer = ({
  documentId,
}: MobileRawHtmlRendererProps) => {
  const { data, error, isLoading, isFetching } =
    useGetDocumentByIdQuery({
      id: Number(documentId),
    })
  const rawRef = useRef<HTMLDivElement>(null)
  const [tableWidth, setTableWidth] = useState(0)
  const processedHtml = useMemo(() => {
    if (data) {
      return data.document.content
        .replace(/<i\s*\/?>/gi, '')
        .replace(
          /Статья \d+/g,
          (match) => `<strong>${match}</strong>`
        )
        .replace(/Р\sА\sЗ\sД\sЕ\sЛ/g, 'РАЗДЕЛ')
        .replace(/Сноска\./g, '')
    }
  }, [data])

  useEffect(() => {
    if (rawRef.current) {
      const isThereATable =
        rawRef.current.querySelectorAll('.table-wrapper')
      if (isThereATable.length) {
        const { width } = rawRef.current.getBoundingClientRect()
        setTableWidth(Number(width.toFixed(0)))
        isThereATable.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.width = `${tableWidth - 30}px`
          }
        })
      }
    }
  }, [rawRef.current])

  return (
    <>
      {!isLoading && !isFetching && processedHtml ? (
        <div
          ref={rawRef}
          className="w-full"
          dangerouslySetInnerHTML={{ __html: processedHtml }}
        />
      ) : (
        <ChatDocumentSkeleton />
      )}
    </>
  )
}
