import {
  DocumentVersionContainer,
  DocumentVersionsMenu,
} from '@/features/document'
import { useCompareTwoVersions } from '@/shared/hooks/useCompareTwoVersions'
import { Typography } from '@/shared/ui'

export const WidgetDocumentVersionsContents = () => {
  const {
    firstVerError,
    secondVerError,
    data,
    firstVerData,
    isFirstVerLoading,
    isFirstVerFetching,
    secondVerData,
    isSecondVerLoading,
    isSecondVerFetching,
    isLoading,
    isFetching,
  } = useCompareTwoVersions()

  if (firstVerError || secondVerError) {
    return <Typography>Error...</Typography>
  }

  return (
    <div
      className="relative mx-auto flex h-[calc(100svh-96px)] w-full max-w-[1480px]
        flex-col items-stretch justify-start gap-3 xs:px-0 md:px-0"
    >
      <DocumentVersionsMenu
        data={data?.document}
        firstVersionData={firstVerData?.document}
        secondVersionData={secondVerData?.document}
        isLoading={isLoading}
        isFetching={isFetching}
      />

      <div className="flex gap-3">
        <DocumentVersionContainer
          data={firstVerData?.document}
          index={1}
          isLoading={isFirstVerLoading}
          isFetching={isFirstVerFetching}
        />
        <DocumentVersionContainer
          data={secondVerData?.document}
          index={2}
          isLoading={isSecondVerLoading}
          isFetching={isSecondVerFetching}
        />
      </div>
    </div>
  )
}
