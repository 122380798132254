import { ECategoryType } from '@/entities/filter'
import { DocumentsToggleFavoritesPageButton } from '@/features/document'
import {
  DocumentStatusFilterDrawer,
  MobileRatificationDateDrawer,
  SearchModeFilterDrawer,
  SortModeFilterDrawer,
} from '@/features/filters'
import { documentsApi } from '@/shared/api/documents'
import { useTypedSelector } from '@/shared/store'
import { Typography } from '@/shared/ui/Typography'
import { useLocation } from 'react-router-dom'

import { WidgetCategoriesMobile } from '../../WidgetCategoriesMobile'

export const DocumentFiltersMobile = () => {
  const totalDocuments = useTypedSelector((state) => {
    return documentsApi.endpoints.getDocuments.select({})(state).data
      ?.meta.total
  })
  const location = useLocation()
  const isFavoriteRoute = location.pathname === '/documents/favorite'

  return (
    <>
      <div className="flex items-center justify-between border-t py-3">
        <div className="flex flex-col items-stretch justify-start gap-1">
          {isFavoriteRoute ? (
            <>
              <Typography variant={'label'} className="font-semibold">
                Избранные
              </Typography>
              <Typography
                variant={'subtitle'}
                className="text-[#67707E]"
              >
                {totalDocuments
                  ? `${new Intl.NumberFormat('ru-RU').format(totalDocuments)} документов`
                  : ''}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant={'label'} className="font-semibold">
                Все документы
              </Typography>
              <Typography
                variant={'subtitle'}
                className="text-[#67707E]"
              >
                {totalDocuments
                  ? `${new Intl.NumberFormat('ru-RU').format(totalDocuments)} документов`
                  : null}
              </Typography>
            </>
          )}
        </div>
        <div className="flex gap-4">
          <DocumentsToggleFavoritesPageButton
            isFavoriteRoute={isFavoriteRoute}
          />
          <SortModeFilterDrawer />
          <SearchModeFilterDrawer />
        </div>
      </div>

      {/* Filters */}
      {!isFavoriteRoute ? (
        <div className="-mx-5 self-stretch overflow-visible">
          <div
            className="flex flex-nowrap items-center justify-start gap-3 overflow-x-scroll
              py-3 pl-5 pr-3"
          >
            <MobileRatificationDateDrawer />
            <DocumentStatusFilterDrawer />
            <WidgetCategoriesMobile
              categoryType={ECategoryType.GROUPS}
              pageTitle={'document'}
            />
            <WidgetCategoriesMobile
              categoryType={ECategoryType.TYPES}
              pageTitle={'document'}
            />
            <WidgetCategoriesMobile
              categoryType={ECategoryType.DEVELOPING_ORGANIZATIONS}
              pageTitle={'document'}
            />
            <WidgetCategoriesMobile
              categoryType={ECategoryType.APPROVING_ORGANIZATIONS}
              pageTitle={'document'}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}
