import { useChat } from '@/entities/chat/models/useChat'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { HomePageChatInput } from '@/widgets/HomePage/HomePageChatInput'
import { HomePageWelcomeContainer } from '@/widgets/HomePage/HomePageWelcomeContainer'
import { HomePageWelcomeContainerMobile } from '@/widgets/HomePage/HomePageWelcomeContainerMobile'
import { useEffect, useRef } from 'react'

export const HomePage = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const isMobile = useMediaQuery('(max-width: 960px)')
  useChat({ shouldCacheReturnUrl: false })

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  if (isMobile)
    return (
      <div
        className="relative mx-5 mb-[88px] mt-16 flex flex-1 flex-col items-stretch
          justify-start"
      >
        <HomePageWelcomeContainerMobile />
        <HomePageChatInput ref={inputRef} />
      </div>
    )
  return (
    <div
      className={`relative flex h-full w-full shrink-0 grow flex-col items-stretch
        justify-center pt-16`}
    >
      <HomePageWelcomeContainer />
      <HomePageChatInput ref={inputRef} />
    </div>
  )
}
