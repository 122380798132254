// DocumentContentsContext.tsx
import { createContext, ReactNode, useContext, useState } from 'react'

interface DocumentContentsContextProps {
  isOpen: boolean
  toggleIsOpen: () => void
  isShownChunks: boolean
  toggleShowChunks: () => void
  isShowFirstVersionChunks: boolean
  toggleFirstVersionChunks: () => void
  isShowSecondVersionChunks: boolean
  toggleSecondVersionChunks: () => void
}

const initialValue: DocumentContentsContextProps = {
  isOpen: false,
  toggleIsOpen: () => {},
  isShownChunks: false,
  toggleShowChunks: () => {},
  isShowFirstVersionChunks: false,
  toggleFirstVersionChunks: () => {},
  isShowSecondVersionChunks: false,
  toggleSecondVersionChunks: () => {},
}

export const DocumentContentsContext =
  createContext<DocumentContentsContextProps>(initialValue)

export const DocumentContentsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [isOpen, setIsOpen] = useState(initialValue.isOpen)
  const [isShownChunks, setsShownChunks] = useState(
    initialValue.isShownChunks
  )

  const toggleShowChunks = () => setsShownChunks(!isShownChunks)
  const [isShowFirstVersionChunks, setsIsShowFirstVersionChunks] =
    useState(initialValue.isShowFirstVersionChunks)

  const toggleFirstVersionChunks = () =>
    setsIsShowFirstVersionChunks(!isShowFirstVersionChunks)
  const [isShowSecondVersionChunks, setsIsShowSecondVersionChunks] =
    useState(initialValue.isShowSecondVersionChunks)

  const toggleSecondVersionChunks = () =>
    setsIsShowSecondVersionChunks(!isShowSecondVersionChunks)
  const toggleIsOpen = () => setIsOpen(!isOpen)

  return (
    <DocumentContentsContext.Provider
      value={{
        isOpen,
        toggleIsOpen,
        isShownChunks,
        toggleShowChunks,
        isShowFirstVersionChunks,
        toggleFirstVersionChunks,
        isShowSecondVersionChunks,
        toggleSecondVersionChunks,
      }}
    >
      {children}
    </DocumentContentsContext.Provider>
  )
}
