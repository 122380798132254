import { useLazyGetDownloadDocumentByIdQuery } from '../api/documents'
import { useToast } from '../ui/Toast/useToast'

export const useDownloadDocument = (
  documentId: string | undefined | number
) => {
  const [triggerDownload] = useLazyGetDownloadDocumentByIdQuery()
  const { toast } = useToast()

  const handleDownload = async () => {
    if (!documentId) return

    try {
      const result = await triggerDownload({
        id: Number(documentId),
      }).unwrap()
      if (result) {
        const url = URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = url
        link.download = `document_${documentId}.docx`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      }
    } catch (err) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось скачать документ.',
        variant: 'error',
      })
    }
  }

  return {
    handleDownload,
  }
}
