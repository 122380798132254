import { DocumentLanguages } from '@/entities/document/types'
import { DocumentPanelSkeleton } from '@/entities/panel/ui/DocumentPanelSkeleton'
import { RawHtmlMainRenderer } from '@/entities/panel/ui/RawHtmlMainRenderer'
import { useGetDocumentByIdQuery } from '@/shared/api/documents'
import { Typography } from '@/shared/ui'
import { useEffect } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { WidgetDocumentMenuActions } from '../WidgetDocumentMenuActions'

export const WidgetDocumentContents = () => {
  const { id: documentId } = useParams()
  const [searchParams] = useSearchParams()
  const languageFromUrl = searchParams.get('language')
  const language: DocumentLanguages =
    (languageFromUrl as DocumentLanguages) || 'ru'

  const { data, error, isLoading, isFetching } =
    useGetDocumentByIdQuery({
      id: Number(documentId),
      language,
    })

  if (isLoading || isFetching) {
    return (
      <div
        className="relative mx-auto flex h-[100vh] w-full max-w-[1130px] flex-col
          items-stretch justify-start pb-8"
      >
        <DocumentPanelSkeleton />
      </div>
    )
  }

  if (error) {
    return <Typography>Error...</Typography>
  }

  return (
    <div
      className="relative mx-auto flex h-[calc(100svh-96px)] max-w-[1480px]
        items-stretch justify-start gap-6 pt-5 xs:px-0 md:px-0"
    >
      {/* TO DO: Add go back button here later so container will be at center */}
      <div className="flex w-full flex-col items-stretch justify-start gap-8 bg-white">
        <div
          className="border-b-[1px] border-base-100 px-[40px] py-3 text-center xs:mx-5
            xs:px-0"
        >
          <Typography
            variant={'label'}
            className="font-medium text-base-500"
          >
            {data?.document?.name}
          </Typography>
        </div>
        {data && data.document.content ? (
          <div
            className="relative rounded-[16px] border border-base-100 bg-white pb-[88px] pt-8
              shadow-shadow-modal-light"
            id="document-id-title"
          >
            <div
              className="border-b border-base-100 px-[40px] pb-8 text-center xs:px-5 xs:pb-5
                md:px-5"
            >
              <Typography
                variant={'heading5'}
                className="xs:text-[18px] xs:leading-[26px]"
              >
                {data?.document?.name}
              </Typography>
            </div>
            <div className="px-[40px] xs:px-5 xs:pt-8">
              <RawHtmlMainRenderer
                rawHtml={data?.document.content}
                index={0}
              />
            </div>
          </div>
        ) : (
          <DocumentPanelSkeleton />
        )}
      </div>
      {data && data.document.content && (
        <WidgetDocumentMenuActions
          languages={data.document.locale.available}
          documentId={documentId}
          versions={data.document.versions}
        />
      )}
    </div>
  )
}
