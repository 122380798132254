import { DocumentChapter } from '@/entities/document'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import IconExitLeft from '@/shared/assets/icons/icon_exit_left.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

interface WidgetDocumentChaptersProps {
  isOpen: boolean
  chapters?: DocumentChapter[]
  toggleDocumentChaptersOpen: () => void
  handleDocumentChapterClick: (chapterId: number) => void
}
export const WidgetDocumentChapters = ({
  isOpen,
  chapters,
  toggleDocumentChaptersOpen,
  handleDocumentChapterClick,
}: WidgetDocumentChaptersProps) => {
  if (!isOpen) {
    return null
  }
  // TODO: add accordion when data on backend will be fixed
  return (
    <div
      className="fixed left-6 top-[148px] flex max-h-[600px] w-[326px] flex-col
        items-center justify-start gap-4 overflow-hidden rounded-xl
        bg-bg-light-white-1"
      style={{
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
      }}
    >
      {/* header */}
      <div
        className="flex w-full items-center justify-between border-b
          border-b-stroke-light-gray-1 bg-white px-4 py-3"
      >
        <Typography
          variant="subtitle"
          className="font-semibold text-text-light-black-4"
        >
          Содержание
        </Typography>
        <IconButton onClick={toggleDocumentChaptersOpen}>
          <IconExitLeft />
        </IconButton>
      </div>
      {/* body */}
      <div className="-mt-4 w-full overflow-y-auto p-4 pt-4">
        {chapters?.map((chapter, index) => (
          <>
            <div
              key={chapter.paragraph_id + index}
              onClick={() =>
                handleDocumentChapterClick(chapter.paragraph_id)
              }
              className={cn(
                'flex items-center gap-2 border-x border-base-100 p-2',
                index === 0
                  ? 'rounded-t-[8px] border-t border-base-100'
                  : '',
                index === chapters.length - 1
                  ? 'rounded-b-[8px] border-b'
                  : ''
              )}
            >
              <Typography variant={'body'}>•</Typography>
              <Typography variant={'body'}>
                {chapter.title}
              </Typography>
            </div>
            {index < chapters.length - 1 && (
              <div className="border-b border-base-100" />
            )}
          </>
        ))}
      </div>
      {/* <Accordion type="single" collapsible className="w-full p-4">
        {chapters.map((chapter, index) => (
          <AccordionItem
            key={chapter.paragraph_id + index}
            value={index.toString()}
          >
            <AccordionTrigger
              iconPosition="left"
              iconSvg={<IconChevronRight />}
            >
              {chapter.title}
            </AccordionTrigger>
            <AccordionContent>
              <Typography variant={'body'}>
                {chapter.title}
              </Typography>
            </AccordionContent>
          </AccordionItem>
        ))}
       </Accordion> */}
    </div>
  )
}
