// WidgetDocumentsSearchInput Component
import { SearchModeFilterMenuDesktop } from '@/features/filters'
import IconSearch from '@/shared/assets/icons/icon_search_dark.svg?react'
import { IconButton } from '@/shared/ui'
import { useToast } from '@/shared/ui/Toast/useToast'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import React, {
  ChangeEvent,
  ForwardedRef,
  InputHTMLAttributes,
  KeyboardEvent,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

interface WidgetCourtBaseSearchInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputType?: string
  onValueChange?: (value: string) => void
  innerRef?: ForwardedRef<HTMLInputElement>
}

export const WidgetCourtBaseSearchInput: React.FC<
  WidgetCourtBaseSearchInputProps
> = ({
  inputType = 'text',
  onChange,
  onValueChange,
  innerRef,
  className,
  ...props
}) => {
  const [value, setValue] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams()
  const { toast } = useToast()
  const searchMode = searchParams.get('search_mode')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setValue(newValue)
    if (onChange) onChange(e)
    if (onValueChange) onValueChange(newValue)
  }

  const handleSubmit = () => {
    if (!value.trim()) {
      toast({
        title: 'Ошибка',
        description: 'Заполните поле поиска',
        variant: 'error',
      })
    } else {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        search_query: value,
        search_mode: searchMode ?? 'CONTEXT',
        page: '1',
      })
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <div className="relative flex w-full items-start gap-2 rounded-t-3xl px-1">
      <div
        className="absolute left-2 top-1 flex min-w-[216px] flex-col items-start
          gap-[3px]"
      >
        <SearchModeFilterMenuDesktop />
      </div>
      <input
        type={inputType}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={innerRef}
        placeholder="Поиск по названию документа..."
        className={cn(
          `border-input placeholder:font-inter flex h-[42px] w-full
          rounded-[20px] border bg-[#F1F2F4] pl-[264px] pr-6 text-sm shadow-sm
          transition-colors file:border-0 file:bg-transparent file:text-sm
          file:font-medium placeholder:text-sm placeholder:font-medium
          placeholder:leading-5 placeholder:text-[#67707E]
          focus-visible:outline-none focus-visible:ring-1
          focus-visible:ring-base-900 disabled:cursor-not-allowed
          disabled:opacity-50`,
          {
            'ring-1 ring-base-900': value,
          },
          className
        )}
        {...props}
      />
      <IconButton
        onClick={handleSubmit}
        className="absolute right-3 h-8 w-8 translate-y-[5px] rounded-[16px]
          hover:scale-100"
      >
        <IconSearch className="h-5 w-5" />
      </IconButton>
    </div>
  )
}
