export const smoothScrollTo = (
  element: HTMLElement,
  target: number,
  duration: number
) => {
  const start = element.scrollTop
  const change = target - start
  const startTime = performance.now()

  function animateScroll(currentTime: number) {
    const elapsed = currentTime - startTime
    const t = Math.min(elapsed / duration, 1)
    element.scrollTop = start + change * t
    if (t < 1) {
      requestAnimationFrame(animateScroll)
    }
  }

  requestAnimationFrame(animateScroll)
}
