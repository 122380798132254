import { useToast } from '@/shared/ui/Toast/useToast'
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

export const useDocumentsSearchInput = (
  onValueChange?: (value: string) => void
) => {
  const [value, setValue] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams()
  const searchMode = searchParams.get('search_mode')

  useEffect(() => {
    const searchQuery = searchParams.get('search_query')
    if (searchQuery) setValue(searchQuery)
  }, [searchParams])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setValue(newValue)
    if (onValueChange) onValueChange(newValue)
  }

  const handleSubmit = () => {
    if (!value.trim()) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete('search_query')
      newSearchParams.set('page', '1') // Reset page to 1 if applicable
      setSearchParams(newSearchParams)
    } else {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        search_query: value,
        search_mode: searchMode ?? 'IN_TITLE',
        page: '1',
      })
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return {
    value,
    handleChange,
    handleKeyDown,
    handleSubmit,
  }
}
