import { DocumentContentsProvider } from '@/features/document/contexts/DocumentContentContext'
import { WidgetDocumentVersionsContents } from '@/widgets/DocumentByIdPage/WidgetDocumentVersionsContents'

export const DocumentByIdVersionsPage = () => {
  return (
    <DocumentContentsProvider>
      <div
        className="relative flex h-[calc(100svh-96px)] w-full items-stretch justify-start
          gap-8 px-[70px] xs:px-0 md:px-0"
      >
        <WidgetDocumentVersionsContents />
      </div>
    </DocumentContentsProvider>
  )
}
