import IconLogo from '@/shared/assets/icons/icon_ai_filled_blue.svg?react'
import { openChatPanel } from '@/shared/store/slices/chat/documentPreviewSlice'
import { Typography } from '@/shared/ui'
import { IconButton } from '@/shared/ui/IconButton'
import { cn } from '@/shared/utils/common'

import { useChatPanel } from '../../models/useChatPanel'

export const ChatPanelButton = () => {
  const { isVisibleSecondDocument, documents, isChatOpen, dispatch } =
    useChatPanel()

  return (
    <div
      className={cn(
        `fixed -bottom-1/2 right-[-220px] z-50 flex h-[calc(100svh-49px)]
        -translate-y-1/2 border-[1px] border-base-100 shadow-shadow10`,
        'transform items-start justify-start rounded-tl-[12px]',
        'w-[250px] flex-col bg-white',
        'transition-[opacity] duration-[500ms] ease-in-out',
        (documents.length === 0 || isChatOpen) && 'opacity-0'
      )}
    >
      <div
        className="flex h-[40px] min-h-[40px] items-center justify-between self-stretch
          border-b-[1px] border-base-100 py-2 pl-4"
      >
        <div className="flex cursor-pointer items-center justify-between gap-3">
          <IconLogo />
          <Typography className="font-[600]">AI-ассистент</Typography>
        </div>
        <IconButton className="cursor-pointer"></IconButton>
      </div>
      <IconButton
        className="absolute top-1/2 h-[38px] w-[38px] -translate-x-1/2 rounded-[20px]
          border-[1px] border-base-200 bg-white px-[5.8px]
          disabled:cursor-default"
        onClick={() => dispatch(openChatPanel())}
        disabled={documents.length === 0 || isChatOpen}
      >
        <IconLogo width={'24px'} height={'24px'} />
      </IconButton>
    </div>
  )
}
