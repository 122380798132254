import IconBookmarkBlack from '@/shared/assets/icons/icon_bookmark_back.svg?react'
import { IconButton } from '@/shared/ui'
import { useParams } from 'react-router-dom'

export const ActionDocumentBookmark = () => {
  const { id: documentId } = useParams()
  // TODO: Implement bookmark action
  return (
    <IconButton className="h-10 w-10 p-2 hover:bg-base-100" disabled>
      <IconBookmarkBlack className="h-6 w-6" />
    </IconButton>
  )
}
