// src/store/slices/signinCompanySlice.ts
import { ISigninCompanyDTO } from '@/entities/signin/types'
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

interface SigninCompanyState {
  data: ISigninCompanyDTO
  loading: boolean
  error: string | null
}

const initialState: SigninCompanyState = {
  data: {
    email: '',
    password: '',
  },
  loading: false,
  error: null,
}

const signinCompanySlice = createSlice({
  name: 'signinCompany',
  initialState,
  reducers: {
    resetCompanySigninState: (state) => {
      state.data = initialState.data
    },
    updateSigninData: (
      state,
      action: PayloadAction<Partial<ISigninCompanyDTO>>
    ) => {
      state.data = { ...state.data, ...action.payload }
    },
  },
})

export const { resetCompanySigninState, updateSigninData } =
  signinCompanySlice.actions
export default signinCompanySlice.reducer
