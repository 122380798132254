// ChatByIdPage.tsx
import { useChat } from '@/entities/chat/models/useChat'
import { useFavoriteMessages } from '@/entities/chat/models/useFavoriteMessages'
import { DocumentPreviewContainer } from '@/features/document'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { ChatContainerSkeleton } from '@/widgets/Chat/ChatContainer/ChatContainerSkeleton'
import { FavoriteChatContainer } from '@/widgets/Chat/FavoriteChatContainer'
import { FavoriteChatContainerMobile } from '@/widgets/Chat/FavoriteChatContainerMobile'
import { useSidebar } from '@/widgets/Sidebar'

export const ChatFavoritesPage = () => {
  useChat()
  const isMobile = useMediaQuery('(max-width: 960px)')
  const {
    messages,
    isLoading,
    isFetching,
    bottomRef,
    isBottomVisible,
  } = useFavoriteMessages()
  const { isSidebarOpen } = useSidebar()

  if (isLoading || isFetching) {
    return (
      <ChatContainerSkeleton
        isSidebarOpen={isSidebarOpen}
        isMobile={isMobile}
      />
    )
  }

  if (!messages || messages.length === 0) {
    return <div>У вас не избранных сообщений</div>
  }

  return (
    <>
      {isMobile ? (
        <FavoriteChatContainerMobile
          bottomRef={bottomRef}
          messages={messages ?? []}
          isBottomVisible={isBottomVisible}
        />
      ) : (
        <>
          <FavoriteChatContainer messages={messages ?? []} />
          <DocumentPreviewContainer />
        </>
      )}
    </>
  )
}
