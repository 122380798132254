import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { useEffect, useState } from 'react'

export const useChatPanel = () => {
  const dispatch = useAppDispatch()
  const { isChangedSecondPanel, documents, isChatOpen } =
    useTypedSelector((state) => state.documentPreview)
  const [isVisibleSecondDocument, setIsVisibleSecondDocument] =
    useState(true)

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (isChangedSecondPanel) {
      setIsVisibleSecondDocument(false)
      timeout = setTimeout(() => {
        setIsVisibleSecondDocument(true)
      }, 500)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isChangedSecondPanel, dispatch])

  return {
    isVisibleSecondDocument,
    documents,
    isChatOpen,
    dispatch,
  }
}
