import { ISigninCompanyDTO } from '@/entities/signin/types'
import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'

import { config } from '..'
import { IAuthResponse } from '../auth'

export const signinCompanyApi = createApi({
  reducerPath: 'signinCompanyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE,
    prepareHeaders: (headers) => {
      return headers
    },
  }),
  endpoints: (builder) => ({
    signinCompany: builder.mutation<IAuthResponse, ISigninCompanyDTO>(
      {
        query: (data) => ({
          url: '/authentication/company',
          method: 'POST',
          body: data,
        }),
      }
    ),
  }),
})

export const { useSigninCompanyMutation } = signinCompanyApi
