import { Version } from '@/entities/document'
import { DocumentData } from '@/entities/document/types'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { setDocumentsVersions } from '@/shared/store/slices/documents/documentsSlice'
import { useEffect, useState } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

export const useDocumentVersionSelect = (
  data: DocumentData | undefined
) => {
  const { versions, allVersions, citationsMap } = useTypedSelector(
    (state) => state.documents
  )
  const { id: documentId } = useParams()
  const documentCitiationParagraps = citationsMap[Number(documentId)]
  const [searchParams, setSearchParams] = useSearchParams()

  const language = searchParams.get('language')
  const languages = searchParams.get('languages')
  const highlightParagraphs = searchParams.get('highlight_paragraphs')

  const updatedLanguage = language ? language : 'RU'
  const updatedLanguages = languages ? languages : 'RU,RU'

  const versionIds = searchParams
    .get('ids')
    ?.split(',')
    .map(Number) || [0, 0]
  const navigate = useNavigate()
  const firstVersionId = versions[0].id
    ? versions[0].id
    : versionIds[0]
  const secondVersionId = versions[1].id
    ? versions[1].id
    : versionIds[1]
  const dispatch = useAppDispatch()
  const [firstVersionSelect, setFirstVersionSelect] = useState(
    data && data.versions.length ? data.versions : allVersions
  )
  const [secondVersionSelect, setSecondVersionSelect] = useState(
    data && data.versions.length ? data.versions : allVersions
  )

  const [isOpenFirstMenu, setIsOpenFirstMenu] = useState(false)
  const [isOpenSecondMenu, setIsOpenSecondMenu] = useState(false)

  const [isOpenVersionSelect, setIsOpenVersionSelect] =
    useState(false)
  const openVersionSelect = () => {
    setIsOpenVersionSelect(true)
    setIsOpenFirstMenu(false)
  }

  const openVersionSelectSecond = () => {
    setIsOpenVersionSelect(true)
    setIsOpenSecondMenu(false)
  }
  const closeVersionSelect = () => setIsOpenVersionSelect(false)

  const openFirstMenu = () => setIsOpenFirstMenu(!isOpenFirstMenu)
  const openSecondMenu = () => setIsOpenSecondMenu(!isOpenSecondMenu)

  useEffect(() => {
    setFirstVersionSelect(allVersions)
    setSecondVersionSelect(allVersions)
  }, [allVersions])

  useEffect(() => {
    if (isOpenFirstMenu) {
      setIsOpenSecondMenu(false)
    }
  }, [isOpenFirstMenu])

  useEffect(() => {
    if (isOpenSecondMenu) {
      setIsOpenFirstMenu(false)
    }
  }, [isOpenSecondMenu])

  const buildSearchParams = (
    language: string | null,
    updatedLanguages: string | null,
    documentCitiationParagraps: string[] | null,
    highlightParagraphs: string | null
  ): URLSearchParams => {
    const searchParams = new URLSearchParams()
    searchParams.set('language', language || 'RU')
    if (updatedLanguages) {
      searchParams.set('languages', updatedLanguages)
    }

    if (
      documentCitiationParagraps &&
      documentCitiationParagraps.length
    ) {
      searchParams.set(
        'highlight_paragraphs',
        documentCitiationParagraps.join(',')
      )
    } else if (highlightParagraphs) {
      searchParams.set('highlight_paragraphs', highlightParagraphs)
    }

    return searchParams
  }

  const updateVersionSelection = (
    allVersions: Version[],
    selectedVersionId: number,
    isFirstVersion: boolean
  ): [Version | null, Version | null] => {
    const updatedVersions = allVersions.filter(
      (v) => v.id !== selectedVersionId
    )
    const foundVersion = allVersions.find(
      (v) => v.id === selectedVersionId
    )

    return isFirstVersion
      ? [foundVersion || null, updatedVersions[0] || null]
      : [updatedVersions[0] || null, foundVersion || null]
  }

  const onValueChange = (
    value: string,
    isFirstVersion: boolean,
    language: string | null,
    updatedLanguages: string,
    highlightParagraphs: string | null,
    allVersions: Version[],
    versionIds: number[],
    versions: [Version, Version],
    setFirstVersionSelect: (versions: Version[]) => void,
    setSecondVersionSelect: (versions: Version[]) => void
  ) => {
    const currentSearchParams = buildSearchParams(
      language,
      updatedLanguages,
      documentCitiationParagraps,
      highlightParagraphs
    )

    const updatedIds = [...versionIds]
    const selectedIndex = isFirstVersion ? 0 : 1
    updatedIds[selectedIndex] = +value

    const [firstVersion, secondVersion] = updateVersionSelection(
      allVersions,
      Number(value),
      isFirstVersion
    )

    const updatedAvailableVersions = allVersions.filter(
      (v) => v.id !== Number(value)
    )
    if (isFirstVersion) {
      setSecondVersionSelect(updatedAvailableVersions)
    } else {
      setFirstVersionSelect(updatedAvailableVersions)
    }

    if (firstVersion && secondVersion) {
      const updatedVersions: [Version, Version] = isFirstVersion
        ? [firstVersion, versions[1]]
        : [versions[0], secondVersion]
      dispatch(setDocumentsVersions(updatedVersions))
    }

    currentSearchParams.set('ids', updatedIds.join(','))

    navigate(
      `/documents/${documentId}/versions?${currentSearchParams.toString()}`
    )
  }

  const onFirstValueChange = (value: string) => {
    onValueChange(
      value,
      true,
      language,
      updatedLanguages,
      highlightParagraphs,
      allVersions,
      versionIds,
      versions,
      setFirstVersionSelect,
      setSecondVersionSelect
    )
  }

  const onSecondValueChange = (value: string) => {
    onValueChange(
      value,
      false,
      language,
      updatedLanguages,
      highlightParagraphs,
      allVersions,
      versionIds,
      versions,
      setFirstVersionSelect,
      setSecondVersionSelect
    )
  }

  const handleCloseVersion = () => {
    const currentSearchParams = buildSearchParams(
      language,
      null,
      documentCitiationParagraps,
      highlightParagraphs
    )

    navigate(
      `/documents/${documentId}?${currentSearchParams.toString()}`
    )
  }

  return {
    handleCloseVersion,
    openFirstMenu,
    openVersionSelect,
    firstVersionId,
    firstVersionSelect,
    versions,
    onFirstValueChange,
    secondVersionSelect,
    onSecondValueChange,
    openSecondMenu,
    openVersionSelectSecond,
    isOpenVersionSelect,
    closeVersionSelect,
    secondVersionId,
  }
}
