import { DocumentInfoDialog } from '@/entities/panel/ui/DocumentInfoDialog'
import IconInfoItallicDark from '@/shared/assets/icons/icon_info_itallic_dark.svg?react'
import { IconButton } from '@/shared/ui'
import React from 'react'
import { useParams } from 'react-router-dom'

import { useDocumentInfoAction } from '../../hooks/useDocumentInfoAction'

export const ActionDocumentInfo = () => {
  const { id: documentId } = useParams()

  const { handleInfoClick, handleInfoClose, isInfoDialogOpen } =
    useDocumentInfoAction()
  return (
    <>
      <DocumentInfoDialog
        open={isInfoDialogOpen}
        documentId={Number(documentId)}
        onClose={handleInfoClose}
      />
      <IconButton
        className="h-10 w-10 p-2 hover:bg-base-100"
        onClick={handleInfoClick}
      >
        <IconInfoItallicDark className="h-6 w-6" />
      </IconButton>
    </>
  )
}
