import { toast } from '@/shared/ui/Toast/useToast'

import { IChatAiFile } from '../types'

export const parseDocuments = (
  documents?: IChatAiFile[]
): IChatAiFile[] => {
  if (documents && typeof documents === 'string') {
    try {
      return JSON.parse(documents)
    } catch (error: any) {
      toast({
        variant: 'error',
        title: 'Error',
        description: error,
      })
      return []
    }
  }
  return documents || []
}
