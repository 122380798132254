import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ISortMode } from '../types'

export const useSortModeFilter = (defaultSortMode: ISortMode) => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)

  const [selectedSortMode, setSelectedSortMode] = useState<ISortMode>(
    (searchParams.get('sort_mode') as ISortMode) || defaultSortMode
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const newSortMode = searchParams.get('sort_mode') as ISortMode
    setSelectedSortMode(newSortMode || defaultSortMode)
  }, [location.search, defaultSortMode])

  const handleSortModeChange = (sortMode: ISortMode) => {
    setSelectedSortMode(sortMode)
  }

  const handleApply = () => {
    const searchParams = new URLSearchParams(location.search)
    if (selectedSortMode)
      searchParams.set('sort_mode', selectedSortMode)
    navigate({ search: searchParams.toString() })
  }

  const handleReset = () => {
    setSelectedSortMode(defaultSortMode)
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('sort_mode')
    navigate({ search: searchParams.toString() })
  }

  return {
    selectedSortMode,
    handleSortModeChange,
    handleApply,
    handleReset,
  }
}
