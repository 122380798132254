import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useDocumentOpenVersionsSelectAction = () => {
  const [isOpenVersionSelect, setIsOpenVersionSelect] =
    useState(false)
  const [searchParams] = useSearchParams()

  const versionIds = searchParams.get('ids')
  const toggleOpenVersionsSelect = () => setIsOpenVersionSelect(true)
  const toggleCloseVersionsSelect = () =>
    setIsOpenVersionSelect(false)

  return {
    isOpenVersionSelect,
    toggleOpenVersionsSelect,
    toggleCloseVersionsSelect,
  }
}
