import { usePostToggleFavoriteDocumentMutation } from '@/shared/api/documents'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { useState } from 'react'

interface useChatAiFileDrawerProps {
  document: {
    id: number
    name: string
    body: string
  }
}

export const useChatAiFileDrawer = ({
  document,
}: useChatAiFileDrawerProps) => {
  const isMobile = useMediaQuery('(max-width: 960px)')
  const dispatch = useAppDispatch()
  const { mobileLanguage } = useTypedSelector(
    (state) => state.documents
  )

  const [toggleFavoriteDocument] =
    usePostToggleFavoriteDocumentMutation({})

  const handleOpenInNewTab = () => {
    const newTab = window.open('', '_blank')
    if (newTab) {
      newTab.document.write(`
        <!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${document.name}</title>
            <style>
              body {
                font-family: 'Inter', sans-serif;
                margin: 0;
                padding: 20px;
                color: #000;
              }
              h1 {
                text-align: center;
                font-size: 2em;
                font-weight: bold;
                margin-bottom: 20px;
              }
              p {
                font-size: 1em;
                line-height: 1.5;
              }
            </style>
          </head>
          <body>
            <h1>${document.name}</h1>
            <p>${document.body}</p>
          </body>
        </html>
      `)
      newTab.document.close()
    }
  }
  const [isInfoDialogOpen, setIsInfoDialogOpen] =
    useState<boolean>(false)

  const handleToggleFavoriteClick = () => {
    toggleFavoriteDocument({ document_id: document.id })
  }

  const handleInfoClick = () => {
    setIsInfoDialogOpen((prev) => !prev)
  }
  const handleInfoClose = () => {
    setIsInfoDialogOpen(false)
  }

  const handleChangeMobileDocLanguage = (value: string) => {
    // dispatch(setMobilesLanguage(value))
  }

  return {
    isInfoDialogOpen,
    handleToggleFavoriteClick,
    handleInfoClick,
    handleInfoClose,
    handleOpenInNewTab,
    isMobile,
    handleChangeMobileDocLanguage,
  }
}
