import { ISigninPersonalDTO } from '@/entities/signin/types'
import { IAuthResponse } from '@/shared/api/auth'
import { unformatPhone } from '@/shared/utils/unformatPhone'
import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'

import { config } from '..'

export const signinPersonalApi = createApi({
  reducerPath: 'signinPersonalApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE,
    prepareHeaders: (headers) => {
      return headers
    },
  }),
  endpoints: (builder) => ({
    sendOtp: builder.mutation<void, string>({
      query: (phone) => ({
        url: '/otps/phone',
        method: 'POST',
        body: { phone: `${unformatPhone(phone)}` },
      }),
    }),
    signinPersonal: builder.mutation<
      IAuthResponse,
      ISigninPersonalDTO
    >({
      query: (data) => ({
        url: '/authentication/personal',
        method: 'POST',
        body: {
          ...data,
          phone: `${unformatPhone(data.phone)}`,
        },
      }),
    }),
  }),
})

export const { useSendOtpMutation, useSigninPersonalMutation } =
  signinPersonalApi
