// shared/hooks/usePaginationMobile.ts
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

interface UsePaginationMobileProps {
  currentPage: number
  totalPages: number
  perPage: number
  totalItems: number
}

function getPageRange(
  currentPage: number,
  totalPages: number,
  pageCount = 4
): number[] {
  let startPage = currentPage - 1

  if (startPage < 1) {
    startPage = 1
  }
  if (startPage + pageCount - 1 > totalPages) {
    startPage = Math.max(1, totalPages - (pageCount - 1))
  }

  const pages: number[] = []
  for (let i = 0; i < pageCount; i++) {
    pages.push(startPage + i)
  }
  return pages
}

export function usePaginationMobile({
  currentPage,
  totalPages,
  perPage,
  totalItems,
}: UsePaginationMobileProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  const handlePageChange = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: page.toString(),
    })
  }

  // Compute the 4 pages to display (or adjust if you want another number)
  const visiblePages = useMemo(
    () => getPageRange(currentPage, totalPages, 4),
    [currentPage, totalPages]
  )

  const startItem = (currentPage - 1) * perPage + 1
  const endItem = Math.min(currentPage * perPage, totalItems)

  return {
    visiblePages,
    handlePageChange,
    startItem,
    endItem,
  }
}
