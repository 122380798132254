/**
 * Smoothly scroll to the bottom of a container over a given duration =) 🚀
 *
 * @param container - The container element to scroll.
 * @param duration - Total animation time in milliseconds.
 */
export function scrollToBottom(
  container: HTMLDivElement,
  duration = 500
) {
  const start = container.scrollTop
  const end = container.scrollHeight
  let startTime: number | null = null

  const easeInOutQuad = (t: number) =>
    t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t

  const animateScroll = (timestamp: number) => {
    if (!startTime) startTime = timestamp
    const progress = timestamp - startTime
    const easedProgress = easeInOutQuad(progress / duration)
    container.scrollTop = start + (end - start) * easedProgress

    if (progress < duration) {
      requestAnimationFrame(animateScroll)
    } else {
      // Ensure we end exactly at the bottom
      container.scrollTop = end
    }
  }

  requestAnimationFrame(animateScroll)
}
