import { RefObject } from 'react'

const SCROLL_UP_ANIMATION_DURATION = 500

/**
 * Smoothly scrolls the given containerRef to the top.
 */
export function containerScrollToTop(
  containerRef: RefObject<HTMLElement>
) {
  const container = containerRef.current
  if (!container) return

  const start = container.scrollTop

  let startTimestamp: number | null = null

  const animateUp = (timestamp: number) => {
    if (startTimestamp === null) {
      startTimestamp = timestamp
    }
    const elapsed = timestamp - startTimestamp
    const progress = Math.min(
      elapsed / SCROLL_UP_ANIMATION_DURATION,
      1
    )

    container.scrollTop = start - start * progress

    if (progress < 1) {
      requestAnimationFrame(animateUp)
    }
  }

  requestAnimationFrame(animateUp)
}
