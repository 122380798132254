import IconMagnifyDark from '@/shared/assets/icons/icon_magnify_dark.svg?react'
import { IconButton } from '@/shared/ui'

export const ActionDocumentMagnify = () => {
  return (
    <IconButton className="h-10 w-10 p-1 hover:bg-base-100" disabled>
      <IconMagnifyDark className="h-6 w-6" />
    </IconButton>
  )
}
