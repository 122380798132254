import { useTypedSelector } from '@/shared/store'
import { useEffect, useMemo, useRef, useState } from 'react'

export const useRawHtmlRenderer = (rawHtml: string) => {
  const rawRef = useRef<HTMLDivElement>(null)
  const [tableWidth, setTableWidth] = useState(0)
  const {
    documents,
    isChatOpen,
    isChangedFirstPanel,
    isChangedSecondPanel,
  } = useTypedSelector((state) => state.documentPreview)

  const isAnyPanelOpen =
    isChatOpen ||
    documents.length === 2 ||
    isChangedFirstPanel ||
    isChangedSecondPanel

  const processedHtml = useMemo(() => {
    return rawHtml
      .replace(/<i\s*\/?>/gi, '')
      .replace(/Статья \d+/g, (match) => `<strong>${match}</strong>`)
      .replace(/Р\sА\sЗ\sД\sЕ\sЛ/g, 'РАЗДЕЛ')
      .replace(/Сноска\./g, '')
  }, [rawHtml])

  useEffect(() => {
    if (rawRef.current) {
      const isThereATable =
        rawRef.current.querySelectorAll('.table-wrapper')
      if (isThereATable.length) {
        const { width } = rawRef.current.getBoundingClientRect()
        setTableWidth(Number(width.toFixed(0)))
        isThereATable.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.width = `${tableWidth - 30}px`
          }
        })
      }
    }
  }, [
    rawRef.current,
    isChatOpen,
    documents,
    isChangedFirstPanel,
    isAnyPanelOpen,
  ])

  const resizePanelDocument = () => {
    const panelWrapper = document.getElementById('document-panel-0')
    if (panelWrapper && rawRef.current) {
      const { width } = panelWrapper.getBoundingClientRect()
      rawRef.current.style.width = `${width - 88}px`
    }
  }

  useEffect(() => {
    const handleResize = () => {
      resizePanelDocument()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    processedHtml,
    rawRef,
  }
}
