import IconAiEmpty from '@/shared/assets/icons/icon_ai_empty.svg?react'
import IconAiFilledDark from '@/shared/assets/icons/icon_ai_filled_dark.svg?react'
import IconProfile from '@/shared/assets/icons/icon_avatar_gray.svg?react'
import IconDocumentsDark from '@/shared/assets/icons/icon_documents_dark.svg?react'
import IconDocumentsFilled from '@/shared/assets/icons/icon_documents_filled.svg?react'
import IconMoreDark from '@/shared/assets/icons/icon_more_dark.svg?react'
import IconWeightsDark from '@/shared/assets/icons/icon_weights_dark.svg?react'
import IconWeightsFilled from '@/shared/assets/icons/icon_weights_filled.svg?react'
import { useTypedSelector } from '@/shared/store'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface IBottomNavigationItem {
  icon: ReactNode
  iconActive: ReactNode
  title: string
  pages: string[]
  disabled?: boolean
}

const navItems: IBottomNavigationItem[] = [
  {
    icon: <IconDocumentsDark height={24} width={24} />,
    iconActive: <IconDocumentsFilled height={24} width={24} />,
    title: 'НПА',
    pages: ['documents'],
    disabled: false,
  },
  {
    icon: <IconWeightsDark height={24} width={24} />,
    iconActive: <IconWeightsFilled height={24} width={24} />,
    title: 'Суд.база',
    pages: ['court-base'],
    disabled: false,
  },
  {
    icon: <IconAiEmpty height={24} width={24} />,
    iconActive: <IconAiFilledDark height={24} width={24} />,
    title: 'AI',
    pages: ['', 'chats'],
    disabled: false,
  },
  {
    icon: <IconMoreDark stroke="#18233661" height={24} width={24} />,
    iconActive: (
      <IconMoreDark stroke="#18233661" height={24} width={24} />
    ),
    title: 'Еще',
    pages: ['more'],
    disabled: true,
  },
  {
    icon: <IconProfile stroke="#18233661" height={24} width={24} />,
    iconActive: (
      <IconProfile stroke="#18233661" height={24} width={24} />
    ),
    title: 'Аккаунт',
    pages: ['profile'],
    disabled: true,
  },
]

export const BottomNavigationBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPage = location.pathname.split('/')[1] || ''

  // Access returnUrls from the store
  const chatReturnUrl = useTypedSelector(
    (state) => state.chats.returnUrl
  )
  const documentsReturnUrl = useTypedSelector(
    (state) => state.documents.returnUrl
  )

  const handleNavigation = (pages: string[]) => {
    // Check if user is navigating to AI pages
    if (pages.includes('') || pages.includes('chats')) {
      if (chatReturnUrl) {
        navigate(chatReturnUrl)
        return
      }
    }

    // Check if user is navigating to documents pages
    if (pages.includes('documents')) {
      if (documentsReturnUrl) {
        navigate(documentsReturnUrl)
        return
      }
    }

    // Fallback: navigate to the first page in the array
    const targetPage = pages[0]
    navigate(`/${targetPage}`)
  }

  return (
    <div
      className="fixed bottom-0 z-[60] flex w-full items-center self-stretch bg-white
        pb-7"
    >
      {navItems.map((item, index) => {
        const isActive = item.pages.includes(currentPage)

        return (
          <button
            key={index}
            disabled={item.disabled || isActive}
            className={cn(
              `box-border flex flex-1 shrink-0 cursor-pointer flex-col items-center
              gap-1`,
              'px-0 pb-[2px]',
              isActive
                ? 'border-t-[2px] border-t-base-950 pt-2'
                : 'border-t pt-[9px]'
            )}
            onClick={() => handleNavigation(item.pages)}
          >
            {isActive ? item.iconActive : item.icon}
            <Typography
              variant={'label'}
              className={cn(
                isActive ? 'text-base-950' : 'text-base-400'
              )}
            >
              {item.title}
            </Typography>
          </button>
        )
      })}
    </div>
  )
}
