import IconArrowRightWhite from '@/shared/assets/icons/icon_arrow_right_white.svg?react'
import IconCalendarOutlinedDark from '@/shared/assets/icons/icon_calendar_outlined_dark.svg?react'
import IconChevronLeftDark from '@/shared/assets/icons/icon_chevron_left_dark.svg?react'
import IconChevronRightDark from '@/shared/assets/icons/icon_chevron_right_dark.svg?react'
import { Button } from '@/shared/ui/Button'
import { IconButton } from '@/shared/ui/IconButton'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import {
  isCurrentMonth,
  isMonthInRange,
  isStartOrEndMonth,
} from '@/shared/utils/dates'
import { parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useLocation, useNavigate } from 'react-router-dom'

import './index.css'

interface CustomInputProps {
  value?: string
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  className?: string
}

const CustomInput = React.forwardRef<
  HTMLButtonElement,
  CustomInputProps
>(({ value, onClick, className }, ref) => {
  // Helper function to format date range values
  const formatDateValue = (value: string): string => {
    if (!value) return 'Дата ратификации'

    const parts = value.split('-')
    if (parts.length === 1) {
      // Single date scenario
      const [year, month] = parts[0].trim().split(' ')
      return `${month?.slice(0, 3).toUpperCase()} ${year}`
    } else {
      // Two date scenario
      const [start, end] = parts.map((part) => part.trim())
      const formatPart = (part: string): string => {
        const [month, year] = part.split(' ')
        return `${month.slice(0, 3).toUpperCase()} ${year}`
      }
      const startDate = formatPart(start)
      const endDate = end ? formatPart(end) : ''
      return endDate ? `${startDate} - ${endDate}` : startDate
    }
  }

  return (
    <button
      className={`${className} font-Inter flex h-8 w-[160px] items-center
        justify-between gap-3 self-stretch whitespace-nowrap rounded-lg border
        border-[#E3E5E8] bg-white px-4 py-[6px] text-sm font-medium
        leading-[20px]`}
      onClick={onClick}
      ref={ref}
    >
      {value ? formatDateValue(value) : 'Дата принятия'}
      <IconCalendarOutlinedDark className="min-h-4 min-w-4" />
    </button>
  )
})

CustomInput.displayName = 'CustomInput'

interface SelectedDate {
  year: number
  month: number
}

// Helper to parse YYYY-MM-01 into a Date object
const parseYearMonthDay = (yearMonthDay: string): Date | null => {
  // Expecting format YYYY-MM-01
  const [year, month] = yearMonthDay.split('-').map(Number)
  if (!year || !month) return null
  return new Date(year, month - 1, 1)
}

const renderMonthContent = (
  m: number,
  _shortMonthText: string,
  fullMonthText: string,
  day: Date,
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  const fullYear = day.getFullYear()
  const isCurrentMonthAndNoDatesSet =
    isCurrentMonth({ year: fullYear, month: m }) &&
    !startDate &&
    !endDate

  return (
    <div
      title={`Tooltip for month: ${fullMonthText} ${fullYear}`}
      className={cn(
        `box-border inline-flex grow items-center justify-center self-stretch
        rounded-[8px] text-center hover:bg-base-900 hover:text-white`,
        isCurrentMonthAndNoDatesSet &&
          'border border-black bg-white text-base-900',
        isMonthInRange(m, fullYear, startDate, endDate) &&
          'bg-[#F0F0F0] text-black',
        isStartOrEndMonth(m, fullYear, startDate, endDate) &&
          'bg-base-900 text-white',
        !startDate && !endDate && 'bg-white text-base-900'
      )}
    >
      {fullMonthText.charAt(0).toUpperCase() + fullMonthText.slice(1)}
    </div>
  )
}

type customDesktopRangePickerProps = {
  totalDocuments?: string
}
export const DesktopRangePicker = ({
  totalDocuments,
}: customDesktopRangePickerProps) => {
  const [startDate, setStartDate] = useState<Date | undefined>(
    undefined
  )
  const [endDate, setEndDate] = useState<Date | undefined>(undefined)
  const navigate = useNavigate()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)

  const handleClickOutside = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const created_at = searchParams.get('created_at')
    const created_at_from = searchParams.get('created_at_from')
    const created_at_until = searchParams.get('created_at_until')

    if (created_at) {
      // Single date scenario
      // Format expected: YYYY-MM-01
      const parsedDate = parseYearMonthDay(created_at)
      setStartDate(parsedDate || undefined)
      setEndDate(undefined)
    } else if (created_at_from && created_at_until) {
      // Two date scenario
      const fromDate = parseYearMonthDay(created_at_from)
      const untilDate = parseYearMonthDay(created_at_until)

      if (fromDate && untilDate) {
        // Ensure fromDate is before untilDate
        if (fromDate > untilDate) {
          setStartDate(untilDate)
          setEndDate(fromDate)
        } else {
          setStartDate(fromDate)
          setEndDate(untilDate)
        }
      } else {
        setStartDate(undefined)
        setEndDate(undefined)
      }
    } else {
      // No date params
      setStartDate(undefined)
      setEndDate(undefined)
    }
  }, [location.search])

  const handleChange = (
    date: Date | [Date | null, Date | null] | null,
    event?:
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLElement>
  ) => {
    if (event) {
      event.preventDefault()
    }

    if (Array.isArray(date)) {
      const [start, end] = date
      setStartDate(start || undefined)
      setEndDate(end || undefined)
    } else {
      setStartDate(date || undefined)
      setEndDate(undefined)
    }
  }

  const clearDates = () => {
    setStartDate(undefined)
    setEndDate(undefined)
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('created_at')
    searchParams.delete('created_at_from')
    searchParams.delete('created_at_until')
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  const handleApply = () => {
    const searchParams = new URLSearchParams(location.search)

    // Remove all old params first
    searchParams.delete('created_at')
    searchParams.delete('created_at_from')
    searchParams.delete('created_at_until')

    if (startDate && !endDate) {
      // Only one date: use created_at=YYYY-MM-01
      const singleDate = `${startDate.getFullYear()}-${(
        startDate.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}-01`
      searchParams.set('created_at', singleDate)
    } else if (startDate && endDate) {
      // Two dates: ensure the correct order
      let from = startDate
      let until = endDate
      if (from > until) {
        const temp = from
        from = until
        until = temp
      }

      const fromDate = `${from.getFullYear()}-${(from.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-01`
      const untilDate = `${until.getFullYear()}-${(
        until.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}-01`

      searchParams.set('created_at_from', fromDate)
      searchParams.set('created_at_until', untilDate)
    }

    navigate({
      pathname: '/documents',
      search: searchParams.toString(),
    })
  }

  return (
    <DatePicker
      selected={startDate}
      enableTabLoop={false}
      shouldCloseOnSelect={false}
      renderMonthContent={(m, shortMonthText, fullMonthText, day) =>
        renderMonthContent(
          m,
          shortMonthText,
          fullMonthText,
          day,
          startDate,
          endDate
        )
      }
      renderCustomHeader={({
        date,
        decreaseYear,
        increaseYear,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
      }: ReactDatePickerCustomHeaderProps) => (
        <div className="z-[100] mx-2 my-0 mr-2 flex flex-wrap justify-start gap-3 bg-white">
          <div className="flex shrink-0 grow basis-full items-center justify-end self-stretch">
            <button
              className="rounded-full px-3 py-1 text-black"
              onClick={clearDates}
            >
              <Typography
                variant={'subtitle'}
                className="text-black underline"
              >
                Очистить
              </Typography>
            </button>
          </div>
          <div
            className="flex shrink-0 grow basis-full items-center justify-between
              self-stretch"
          >
            <IconButton
              onClick={decreaseYear}
              disabled={prevYearButtonDisabled}
              className="h-7 w-7 rounded-md border-[1px] border-[#E4E4E7] border-opacity-[0.59]
                bg-white p-1 hover:bg-[#F0F0F0]"
            >
              <IconChevronLeftDark className="h-full w-full" />
            </IconButton>

            <span className="font-[Inter] text-sm font-semibold leading-[20px] text-[#000]">
              {new Date(date).getFullYear()}
            </span>

            <IconButton
              onClick={increaseYear}
              disabled={nextYearButtonDisabled}
              className="h-7 w-7 rounded-md border-[1px] border-[#E4E4E7] border-opacity-[0.59]
                bg-white p-1 hover:bg-[#F0F0F0]"
            >
              <IconChevronRightDark className="h-full w-full" />
            </IconButton>
          </div>
        </div>
      )}
      startDate={startDate}
      endDate={endDate}
      dateFormat="LLLL yyyy"
      locale={ru}
      onClickOutside={handleClickOutside}
      showMonthYearPicker
      selectsRange
      onChange={handleChange}
      open={isOpen}
      onInputClick={() => {
        setIsOpen((prev) => !prev)
      }}
      customInput={<CustomInput />}
    >
      <div
        className="absolute bottom-0 -ml-2 flex w-full items-center justify-between gap-8
          rounded-b-2xl border-t border-t-[#F0F0F0] bg-white px-5 py-2"
      >
        <Typography
          variant={'label'}
          className="whitespace-nowrap text-[14px] font-semibold"
        >
          {totalDocuments && `Найдено документов: ${totalDocuments}`}
        </Typography>
        <div>
          <Button
            onClick={handleApply}
            variant={'secondary'}
            leftIcon={<IconArrowRightWhite />}
            className="grow py-1"
          >
            Показать
          </Button>
        </div>
      </div>
    </DatePicker>
  )
}
