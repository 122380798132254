import { useTypedSelector } from '@/shared/store'
import { smoothScrollTo } from '@/shared/utils/animations'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { IChatMessage } from '../types'

type UseChatMobileProps = {
  messages: IChatMessage[]
}

export const useChatMobile = ({ messages }: UseChatMobileProps) => {
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const [isBottomVisible, setIsBottomVisible] = useState(true)

  const { chat_id } = useParams<{ chat_id: string }>()
  const isAwaitingResponse = useTypedSelector(
    (state) =>
      state.chats.chatsData[chat_id!]?.isAwaitingResponse ?? false
  )

  // After initial mount or after messages update, scroll to bottom of container
  useEffect(() => {
    const container = document.getElementById(
      'chat_messages_container'
    ) as HTMLElement | null
    if (container) {
      requestAnimationFrame(() => {
        smoothScrollTo(container, container.scrollHeight, 300) // Adjust duration as needed
      })
    }
  }, [messages, isAwaitingResponse])

  // Intersection Observer to detect if bottomRef is visible
  useEffect(() => {
    const observerCallback = (
      entries: IntersectionObserverEntry[]
    ) => {
      const entry = entries[0]
      setIsBottomVisible(entry.isIntersecting)
    }

    const observerOptions = {
      root: null, // Use the viewport
      threshold: 0.1, // Trigger when at least 10% is visible
    }

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    )

    if (bottomRef.current) {
      observer.observe(bottomRef.current)
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current)
      }
      observer.disconnect()
    }
  }, [])

  return { bottomRef, isBottomVisible }
}
