import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { Typography } from '@/shared/ui/Typography'

import { mockDocumentStatusData } from '../../consts'
import { useDocumentStatusFilter } from '../../hooks/useDocumentStatusFilter'
import { DocumentStatusFilterCustomInput } from '../DocumentStatusFilterCustomInput'
import { DocumentStatusFilterItems } from '../DocumentStatusFilterItems'

export const DocumentStatusFilterDrawer = () => {
  const {
    selectedIndices,
    handleCheckboxChange,
    handleApply,
    handleReset,
  } = useDocumentStatusFilter()

  return (
    <Drawer direction="bottom">
      <DrawerTrigger asChild>
        <DocumentStatusFilterCustomInput />
      </DrawerTrigger>
      <DrawerContent className="z-[70] h-[92svh] overflow-y-hidden">
        <DrawerClose className="absolute right-6 top-10">
          <IconClose className="h-6 w-6" />
        </DrawerClose>
        <DrawerHeader className="flex flex-col items-stretch justify-start gap-8 p-0">
          <DrawerTitle>
            <Typography className="self-start text-[28px] font-bold leading-[34px] text-black">
              Статус документа
            </Typography>
          </DrawerTitle>
        </DrawerHeader>
        <DrawerDescription
          className="-mx-5 mt-8 flex flex-col items-stretch justify-start gap-6
            overflow-y-scroll px-5"
        >
          <DocumentStatusFilterItems
            items={mockDocumentStatusData}
            selectedIndices={selectedIndices}
            handleCheckboxChange={handleCheckboxChange}
          />
        </DrawerDescription>
        <DrawerFooter className="flex flex-row flex-nowrap justify-between gap-5 p-0 pb-5">
          <DrawerClose asChild className="shrink-0 grow">
            <Button
              variant={'tonal'}
              className="w-full"
              onClick={handleApply}
            >
              Применить
            </Button>
          </DrawerClose>
          <DrawerClose asChild className="shrink-0 grow">
            <Button
              variant={'outlined'}
              className="w-full"
              onClick={handleReset}
            >
              Сбросить
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
