import React from 'react'

import { useDocumentContents } from './useDocumentContent'

export const useShowDocumentChunks = () => {
  const {
    toggleIsOpen: toggleIsDocumentContentsOpen,
    isShownChunks: isShowMainDocumentChunks,
    toggleShowChunks: toggleShowMainDocumentChunks,
  } = useDocumentContents()

  return {
    toggleShowMainDocumentChunks,
    isShowMainDocumentChunks,
    toggleIsDocumentContentsOpen,
  }
}
