import { IChatHistoryItem } from '@/entities/chat/types'
import { ChatHistoryItem } from '@/entities/chatHistory'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { Skeleton } from '@/shared/ui'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'

type Props = {
  isLoading: boolean
  todayChats: IChatHistoryItem[]
  yesterdayChats: IChatHistoryItem[]
  otherChatsByDate: Record<string, IChatHistoryItem[]>
}

export const WidgetChatHistory = ({
  isLoading,
  todayChats,
  yesterdayChats,
  otherChatsByDate,
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  if (isLoading) {
    return <ChatHistorySkeleton />
  }

  return (
    <div
      className={cn(
        'flex max-h-full shrink grow flex-col items-start gap-4 self-stretch',
        'overflow-y-auto px-3',
        isMobile ? '-mx-6 pb-4' : 'pb-[160px]'
      )}
    >
      {todayChats.length > 0 && (
        <ChatHistoryList label="Сегодня" items={todayChats} />
      )}
      <div className="w-full border-t border-t-stroke-light-gray-1" />
      {yesterdayChats.length > 0 && (
        <ChatHistoryList label="Вчера" items={yesterdayChats} />
      )}
      <div className="w-full border-t border-t-stroke-light-gray-1" />

      {Object.keys(otherChatsByDate).map((date, index) => (
        <>
          <ChatHistoryList
            key={date}
            label={new Date(date).toLocaleDateString('ru-RU')}
            items={otherChatsByDate[date]}
          />
          {index !== Object.keys(otherChatsByDate).length - 1 ? (
            <div className="w-full border-t border-t-stroke-light-gray-1" />
          ) : null}
        </>
      ))}
    </div>
  )
}

interface ChatHistoryListProps {
  label: string
  items: IChatHistoryItem[]
}

export const ChatHistoryList = ({
  label,
  items,
}: ChatHistoryListProps) => {
  return (
    <div className="flex flex-col items-start gap-[6px] self-stretch">
      <Typography
        variant={'subtitle'}
        className="w-full px-3 py-[6px] font-semibold text-base-700"
      >
        {label}
      </Typography>
      <div className="flex flex-col items-start self-stretch">
        {items.map((item) => (
          <ChatHistoryItem key={item.id} item={item} />
        ))}
      </div>
    </div>
  )
}
const ChatHistorySkeleton = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  return (
    <div
      className={cn(
        'flex max-h-full shrink grow flex-col items-start gap-4 self-stretch',
        'overflow-y-auto px-4 pt-2',
        isMobile ? '-mx-6 pb-4' : 'pb-[160px]'
      )}
    >
      {[...Array(4)].map((_, index) => (
        <div
          key={index}
          className="flex flex-col items-start gap-2 self-stretch"
        >
          <Skeleton className="h-4 w-14 bg-base-200"></Skeleton>
          {[...Array(index === 0 ? 3 : 4)].map((_, subIndex) => (
            <Skeleton
              key={subIndex}
              className="relative flex h-6 w-full flex-col items-start gap-2 self-stretch
                bg-base-300"
            />
          ))}
        </div>
      ))}
    </div>
  )
}
