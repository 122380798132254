import { DocumentQueryParams, IDocument } from '@/entities/document'
import {
  DocumentChapter,
  DocumentData,
  DocumentLanguages,
  FavoriteDocumentQueryParams,
} from '@/entities/document/types'
import { IDocumentInfo } from '@/entities/panel/types'
import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

import { config, Meta } from '..'

interface DocumentsResponse {
  success: boolean
  meta: Meta
  documents: IDocument[]
}

interface DocumentByIdResponse {
  success: boolean
  document: DocumentData
  meta: Meta
}

//TODO: paragraphs changed to content.Also need to add requisites and status

interface DocumentInfoResponse {
  success: boolean
  info: IDocumentInfo
}

interface DocumentChaptersResponse {
  success: boolean
  chapters: DocumentChapter[]
}

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE,
    prepareHeaders: (headers) => {
      const token = Cookies.get('access_token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Documents', 'DocumentInfo'],
  endpoints: (builder) => ({
    getDocuments: builder.query<
      DocumentsResponse,
      DocumentQueryParams
    >({
      query: (params) => {
        return {
          url: '/documents',
          params: {
            page: params.page ?? 1,
            ...params,
          },
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.documents.map(({ id }) => ({
                type: 'Documents' as const,
                id,
              })),
              { type: 'Documents', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Documents', id: 'PARTIAL-LIST' }],
      keepUnusedDataFor: 86400,

      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}?${JSON.stringify(queryArgs)}`
      },

      forceRefetch: ({ currentArg, previousArg }) => {
        return false
      },
      merge: (_currentCache, newData) => {
        return newData
      },
    }),
    getDocumentById: builder.query<
      DocumentByIdResponse,
      { id: number; language?: DocumentLanguages }
    >({
      query: ({ id, language = 'ru' }) => ({
        url: `/documents/${id}`,
        headers: {
          'Accept-Language': language,
        },
      }),
      providesTags: (result, error, arg) => [
        { type: 'Documents', id: arg.id },
      ],
      keepUnusedDataFor: 0,
    }),
    getDocumentInfo: builder.query<
      DocumentInfoResponse,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/documents/${id}/info`,
      }),

      providesTags: (result, error, arg) => [
        { type: 'DocumentInfo', id: arg.id },
      ],
      keepUnusedDataFor: 86400,
    }),
    getDownloadDocumentById: builder.query<Blob, { id: number }>({
      query: ({ id }) => ({
        url: `/documents/${id}/download`,
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          Authorization: `Bearer ${Cookies.get('access_token')}`,
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
    getFavorites: builder.query<
      DocumentsResponse,
      FavoriteDocumentQueryParams
    >({
      query: (params) => {
        // Extract page or default to 1
        const { page = 1, ...rest } = params
        return {
          url: `/documents/favorite`,
          method: 'GET',
          params: {
            page,
            ...rest,
          },
          headers: {
            Authorization: `Bearer ${Cookies.get('access_token')}`,
          },
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.documents.map(({ id }) => ({
                type: 'Documents' as const,
                id,
              })),
              { type: 'Documents', id: 'Favorites' },
            ]
          : [{ type: 'Documents', id: 'Favorites' }],
    }),
    getDocumentChapters: builder.query<
      DocumentChaptersResponse,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/documents/${id}/chapters`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get('access_token')}`,
        },
      }),
    }),

    postToggleFavoriteDocument: builder.mutation<
      { status: boolean },
      { document_id: number }
    >({
      query: ({ document_id }) => ({
        url: `/documents/${document_id}/favorite`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${Cookies.get('access_token')}`,
        },
      }),
      invalidatesTags: (result, error, { document_id }) => [
        { type: 'Documents', id: document_id },
        { type: 'Documents', id: 'Favorites' },
      ],
      async onQueryStarted(
        { document_id },
        { dispatch, queryFulfilled }
      ) {
        // Patch for isMobile: false queries
        const patchForIsMobileFalse = dispatch(
          documentsApi.util.updateQueryData(
            'getDocuments',
            {},
            (draft) => {
              const document = draft.documents.find(
                (doc) => doc.id === document_id
              )
              if (document) {
                document.is_favorite = !document.is_favorite
              }
            }
          )
        )

        // Patch for isMobile: true queries
        const patchForIsMobileTrue = dispatch(
          documentsApi.util.updateQueryData(
            'getDocuments',
            {},
            (draft) => {
              const document = draft.documents.find(
                (doc) => doc.id === document_id
              )
              if (document) {
                document.is_favorite = !document.is_favorite
              }
            }
          )
        )

        try {
          await queryFulfilled
        } catch {
          // Undo both patches if the request fails
          patchForIsMobileFalse.undo()
          patchForIsMobileTrue.undo()
        }
      },
    }),
  }),
})

export const {
  useGetFavoritesQuery,
  useGetDocumentsQuery,
  useGetDocumentInfoQuery,
  useGetDocumentByIdQuery,
  useGetDocumentChaptersQuery,
  useGetDownloadDocumentByIdQuery,
  useLazyGetDownloadDocumentByIdQuery,
  usePostToggleFavoriteDocumentMutation,
} = documentsApi
