import { Version } from '@/entities/document'
import { DocumentLanguages } from '@/entities/document/types'
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { useGetDocumentByIdQuery } from '../api/documents'
import { useAppDispatch, useTypedSelector } from '../store'
import {
  setDocumentsAllVersions,
  setDocumentsVersions,
} from '../store/slices/documents/documentsSlice'

export const useCompareTwoVersions = () => {
  const { id: documentId } = useParams()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const versionIds = searchParams.get('ids')?.split(',') || []
  const { versions, allVersions } = useTypedSelector(
    (state) => state.documents
  )
  const language: DocumentLanguages =
    (searchParams.get('language') as DocumentLanguages) || 'ru'
  const languages = searchParams.get('languages')
  const updatedLanguages: DocumentLanguages[] = (
    languages
      ? languages.split(',').map((el) => el.toLowerCase())
      : ['ru', 'ru']
  ) as DocumentLanguages[]

  const { data, isLoading, isFetching } = useGetDocumentByIdQuery({
    id: Number(documentId),
    language,
  })
  const firstVersionId = versions[0]?.id
    ? versions[0]?.id
    : Number(versionIds[0])
  const secondVersionId = versions[1]?.id
    ? versions[1]?.id
    : Number(versionIds[1])
  const {
    data: firstVerData,
    error: firstVerError,
    isLoading: isFirstVerLoading,
    isFetching: isFirstVerFetching,
  } = useGetDocumentByIdQuery(
    { id: firstVersionId, language: updatedLanguages[0] },
    { skip: !firstVersionId }
  )

  const {
    data: secondVerData,
    error: secondVerError,
    isLoading: isSecondVerLoading,
    isFetching: isSecondVerFetching,
  } = useGetDocumentByIdQuery(
    { id: secondVersionId, language: updatedLanguages[1] },
    { skip: !secondVersionId }
  )

  useEffect(() => {
    if (data) {
      dispatch(setDocumentsAllVersions(data.document.versions))
    }
  }, [data, dispatch, isLoading])

  useEffect(() => {
    if (
      versions &&
      versions.length &&
      +versionIds[0] !== 0 &&
      +versionIds[1] !== 0
    ) {
      const firstVersions = allVersions.find(
        (v) => v.id === +versionIds[0]
      )
      const secondVersions = allVersions.find(
        (v) => v.id === +versionIds[1]
      )
      if (firstVersions && secondVersions) {
        const updatedVersions: [Version, Version] = [
          firstVersions,
          secondVersions,
        ]
        dispatch(setDocumentsVersions(updatedVersions))
      }
    }
  }, [allVersions, dispatch])

  return {
    firstVerError,
    secondVerError,
    data,
    firstVerData,
    isFirstVerLoading,
    isFirstVerFetching,
    secondVerData,
    isSecondVerLoading,
    isSecondVerFetching,
    isLoading,
    isFetching,
  }
}
