import { IAiAssistPayload } from '@/entities/chat/types'
import { chatApi, useGetChatByIdQuery } from '@/shared/api/chat'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  clearChatAwaitingResponse,
  clearChatResponding,
  setChatError,
  setChatResponding,
} from '@/shared/store/slices/chat/chatSlice'
import { ToastAction } from '@/shared/ui/Toast/toast'
import { toast } from '@/shared/ui/Toast/useToast'
import { scrollToBottom } from '@/shared/utils/scrollToBottom'
import { useSocket } from '@/socketHook'
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { parseDocuments } from '../lib/parseDocuments'
import { IChat } from '../types'

interface UseChatMessagesReturnType {
  data: {
    chat: IChat['chat']
    messages: IChat['messages']
  } | null
  error: string | null
  typedText: string
  isLoading: boolean
  isFetching: boolean
  scrollContainerRef?: React.RefObject<HTMLDivElement>
  isAwaitingResponse: boolean
}

export const useChatMessages = (
  chatId: string,
  userId: number
): UseChatMessagesReturnType => {
  const dispatch = useAppDispatch()

  const { data, error, isLoading, isFetching, isError } =
    useGetChatByIdQuery(chatId)

  const [typedText, setTypedText] = useState('')

  /**
   * Selectors
   */
  const isAwaitingResponse = useTypedSelector(
    (state) =>
      state.chats.chatsData[chatId!]?.isAwaitingResponse ?? false
  )

  /*
   * Ref to the scroll container, so we can scroll to the bottom
   */
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const container = scrollContainerRef.current
    if (!container) return

    const timeoutId = setTimeout(() => {
      scrollToBottom(container)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [data?.messages, isAwaitingResponse])

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Что-то пошло не так',
        description:
          'Перезагрузите страницу, чтобы все заработало. Если ошибка повторяется, обратитесь в поддержку.',
        variant: 'error',
        action: (
          <ToastAction
            altText="Нотификация об ошибке"
            className="cursor-pointer"
            onClick={() => window.location.reload()}
          >
            Перезагрузить
          </ToastAction>
        ),
      })
    }
  }, [isError])

  /**
   * 1) Normal "responded" event callback
   *    - Happens once the server has the final message for the chat.
   */
  const handleChatResponse = useCallback(
    (payload: IAiAssistPayload) => {
      const documents = parseDocuments(payload.documents)

      dispatch(clearChatAwaitingResponse({ chatId: payload.chat_id }))
      dispatch(clearChatResponding({ chatId: payload.chat_id }))

      dispatch(
        chatApi.util.updateQueryData(
          'getChatById',
          payload.chat_id,
          (draft) => {
            if (draft && draft.messages) {
              const exists = draft.messages.find(
                (msg) => msg.id === payload.message_id
              )
              if (!exists) {
                draft.messages.push({
                  id: payload.message_id,
                  from: 'ASSISTANT',
                  is_favorite: false,
                  message: payload.message,
                  documents,
                  created_at: new Date().toISOString(),
                })
              }
            }
          }
        )
      )
    },
    [dispatch]
  )

  /**
   * 2) "failure" event callback
   *    - Happens if the server encountered an error generating the response
   */
  const handleChatFailure = useCallback(
    (payload: { chat_id: string; message: string }) => {
      dispatch(
        setChatError({
          chatId: payload.chat_id,
          error: payload.message,
        })
      )
    },
    [dispatch]
  )

  /**
   * 3) "responded.stream" event callback
   *    - Happens when the server is sending partial (streamed) chunks of a message
   */
  const handleChatStream = useCallback(
    (payload: {
      chat_id: string
      message: string
      message_id: string
      chunk: string
    }) => {
      if (payload.chat_id !== chatId) {
        return
      }

      dispatch(setChatResponding({ chatId: payload.chat_id }))
      setTypedText((prev) => payload.message)
    },
    [chatId, dispatch]
  )

  const socketSubscriptions = useMemo(
    () => [
      { event: '.ai-assist.failure', callback: handleChatFailure },
      {
        event: '.ai-assist.responded.stream',
        callback: handleChatStream,
      },
      { event: '.ai-assist.responded', callback: handleChatResponse },
    ],
    [handleChatResponse, handleChatFailure, handleChatStream]
  )

  /**
   * 4) Subscribing to WS events
   */
  useSocket({
    channel: `chat.${userId}`,
    subscriptions: socketSubscriptions,
  })

  return {
    data: data ? { chat: data.chat, messages: data.messages } : null,
    error: error ? 'Failed to fetch chat messages' : null,
    isLoading,
    isFetching,
    typedText,
    scrollContainerRef,
    isAwaitingResponse,
  }
}
