import {
  DocumentItem,
  DocumentItemSkeleton,
  IDocument,
} from '@/entities/document'
import { useFavoriteDocuments } from '@/entities/document/models/useFavoriteDocuments'
import IconNotFound from '@/shared/assets/icons/logo_not_found.svg?react'
import { Typography } from '@/shared/ui'
import { CustomPagination } from '@/widgets/CustomPagination'
import React from 'react'

const LoadingSkeletons = () => (
  <div
    className="mb-20 flex flex-col items-stretch justify-start rounded-b-3xl
      border-x-[1px] border-b-[1px] border-t-0 bg-transparent"
    style={{
      boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
      overflow: 'visible',
    }}
  >
    {Array.from({ length: 12 }).map((_, index) => (
      <DocumentItemSkeleton key={index} isLastItem={index === 11} />
    ))}
  </div>
)

const ErrorState = () => (
  <div
    className="mb-20 flex flex-1 flex-col items-stretch justify-start rounded-b-3xl
      border-x-[1px] border-b-[1px] border-t-0 bg-transparent py-20"
    style={{
      boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
    }}
  >
    <div className="my-20 flex flex-col items-stretch justify-center gap-8">
      <IconNotFound className="self-center drop-shadow-lg" />
      <div className="flex flex-col items-center justify-center">
        <Typography>
          По запросу не найдено ни одного документа.
        </Typography>
        <Typography>
          Попробуйте написать запрос иначе и повторить поиск.
        </Typography>
      </div>
    </div>
  </div>
)

const EmptyState = () => (
  <div
    className="flex shrink-0 grow flex-col items-center justify-center self-stretch
      py-20"
  >
    <div className="flex flex-col items-stretch justify-center gap-8">
      <IconNotFound className="self-center drop-shadow-lg" />
      <div className="flex flex-col items-center justify-center">
        <Typography>
          По запросу не найдено ни одного документа.
        </Typography>
        <Typography>
          Попробуйте написать запрос иначе и повторить поиск.
        </Typography>
      </div>
    </div>
  </div>
)

interface DocumentsListProps {
  documents: IDocument[]
  currentPage: number
  totalPages: number
  perPage: number
  totalItems: number
}

const DocumentsList: React.FC<DocumentsListProps> = ({
  documents,
  currentPage,
  totalPages,
  perPage,
  totalItems,
}) => (
  <div
    className="mb-20 flex flex-1 flex-col items-stretch justify-start rounded-b-3xl
      border-x-[1px] border-b-[1px] border-t-0 bg-transparent"
    style={{
      boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
      overflow: 'visible',
    }}
  >
    <div className="flex-1">
      {documents.map((item, index) => {
        const isLastItem = index === documents.length - 1
        return (
          <DocumentItem
            key={item.id}
            documentItem={item}
            isLastDocumentItem={isLastItem}
          />
        )
      })}
    </div>
    <CustomPagination
      currentPage={currentPage}
      totalPages={totalPages}
      perPage={perPage}
      totalItems={totalItems}
    />
  </div>
)

export const DocumentBaseFavoritesContainer = () => {
  const { data, error, isLoading, isFetching, currentPage } =
    useFavoriteDocuments({
      isMobile: false,
    })

  if (isLoading || isFetching) {
    return <LoadingSkeletons />
  }

  if (error) {
    return <ErrorState />
  }

  if (!data?.documents || data.documents.length === 0) {
    return (
      <div
        className="mb-6 flex flex-col items-stretch justify-start rounded-b-3xl
          border-x-[1px] border-b-[1px] border-t-0 bg-transparent"
        style={{
          boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
          overflow: 'visible',
        }}
      >
        <EmptyState />
      </div>
    )
  }

  return (
    <DocumentsList
      documents={data.documents}
      currentPage={currentPage}
      totalPages={data.meta.last_page}
      perPage={data.meta.per_page}
      totalItems={data.meta.total}
    />
  )
}
