import { Version } from '@/entities/document'
import { DocumentLanguageMenu } from '@/features/document/ui/DocumentLanguageMenu'
import IconBurger from '@/shared/assets/icons/icon_burger_gray.svg?react'
import { IconButton } from '@/shared/ui'
import { motion } from 'framer-motion'

import { useDocumentMenuActions } from '../../../features/document/hooks/useDocumentMenuActions'
import { ActionDocumentBookmark } from '../../../features/document/ui/ActionDocumentBookmark'
import { ActionDocumentContents } from '../../../features/document/ui/ActionDocumentContents'
import { ActionDocumentDownload } from '../../../features/document/ui/ActionDocumentDownload'
import { ActionDocumentInfo } from '../../../features/document/ui/ActionDocumentInfo'
import { ActionDocumentMagnify } from '../../../features/document/ui/ActionDocumentMagnify'
import { ActionDocumentOpenVersionsSelect } from '../../../features/document/ui/ActionDocumentOpenVersionsSelect'
import { ActionDocumentToLowerCase } from '../../../features/document/ui/ActionDocumentToLowerCase'
import { ActionShowDocumentChunks } from '../../../features/document/ui/ActionShowDocumentChunks'

interface DocumentMenuActionsProps {
  documentId: string | undefined
  versions: Version[]
  languages: string[]
}

export const WidgetDocumentMenuActions = ({
  documentId,
  versions,
  languages,
}: DocumentMenuActionsProps) => {
  const {
    isOpen,
    toggleOpen,
    handleChangeMainDocumentLanguage,
    language,
  } = useDocumentMenuActions()

  return (
    <>
      <div className="flex w-auto flex-col items-center justify-start gap-3">
        <IconButton
          className="h-10 w-10 rounded-full border-[1px] border-base-100 bg-white p-[10px]"
          style={{
            boxShadow:
              '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
          }}
          onClick={(e) => toggleOpen(e)}
          aria-expanded={isOpen}
        >
          <IconBurger className="h-5 w-5" />
        </IconButton>

        <motion.div
          className="shadow-modal-light flex flex-col items-center justify-start gap-4
            overflow-hidden rounded-[16px] border-[1px] border-base-100 p-2"
          initial={{ opacity: 0, height: 0 }}
          animate={
            isOpen
              ? { opacity: 1, height: 'auto' }
              : { opacity: 0, height: 0 }
          }
          transition={{
            height: { duration: 0.3, ease: 'easeInOut' },
            opacity: { duration: 0.2 },
          }}
        >
          <ActionDocumentMagnify />
          <ActionDocumentContents />
          <ActionShowDocumentChunks />
          {languages.length && (
            <DocumentLanguageMenu
              currentLanguage={
                language ? language.toUpperCase() : languages[0]
              }
              languages={languages}
              handleChange={handleChangeMainDocumentLanguage}
            />
          )}
          <ActionDocumentToLowerCase />
          <ActionDocumentOpenVersionsSelect versions={versions} />
          <ActionDocumentInfo />
          <ActionDocumentBookmark />
          <ActionDocumentDownload />
        </motion.div>
      </div>
    </>
  )
}
