import { useChat } from '@/entities/chat/models/useChat'
import { ChatPanelButton } from '@/entities/chat/ui/ChatPanelButton'
import { IUser } from '@/entities/user/models'
import { DocumentPreviewContainer } from '@/features/document'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { ChatContainer } from '@/widgets/Chat/ChatContainer'
import { ChatContainerMobile } from '@/widgets/Chat/ChatContainerMobile'
import { useSidebar } from '@/widgets/Sidebar'
import { useLoaderData, useParams } from 'react-router-dom'

export const ChatByIdPage = () => {
  useChat()
  const { chat_id } = useParams<{ chat_id: string }>()
  const { user } = useLoaderData() as {
    user: IUser
  }

  const isMobile = useMediaQuery('(max-width: 960px)')
  const { isSidebarOpen } = useSidebar()

  if (isMobile)
    return (
      <div
        className={`relative mx-5 flex min-h-0 flex-1 flex-col items-stretch justify-start
          overflow-y-auto pt-16`}
      >
        <ChatContainerMobile
          userId={Number(user.id)}
          chatId={chat_id}
          isSidebarOpen={isSidebarOpen}
        />
      </div>
    )

  return (
    <>
      <ChatContainer
        userId={Number(user.id)}
        chatId={chat_id}
        isSidebarOpen={isSidebarOpen}
      />
      <DocumentPreviewContainer />
      <ChatPanelButton />
    </>
  )
}
