import { Typography } from '@/shared/ui'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { cn } from '@/shared/utils/common'
import { Arrow } from '@radix-ui/react-menubar'

interface DocumentLanguageMenuProps {
  languages: string[]
  currentLanguage: string
  handleChange: (value: string) => void
  isVersions?: boolean
}

export const DocumentLanguageMenu = ({
  languages,
  currentLanguage,
  handleChange,
  isVersions,
}: DocumentLanguageMenuProps) => {
  return (
    <Menubar>
      <MenubarMenu>
        <MenubarTrigger
          className={cn(
            `h-10 w-10 cursor-pointer rounded-lg px-2 py-2 hover:scale-[1.1]
            hover:bg-base-100 data-[state=open]:bg-base-100`
          )}
        >
          <Typography variant={'label'}>
            {currentLanguage === 'KK'
              ? 'KZ'
              : currentLanguage.toUpperCase()}
          </Typography>
        </MenubarTrigger>
        <MenubarContent
          side={isVersions ? 'bottom' : 'left'}
          collisionPadding={{ right: -20, top: 10, bottom: 15 }}
          className="max-w-[52px !important] shadow-shadow4-popover-libra
            pointer-events-auto z-50 flex min-w-0 flex-col items-stretch
            rounded-[16px] bg-white p-2"
          align="start"
          alignOffset={isVersions ? -8 : -20}
          sideOffset={-5}
        >
          <Arrow fill="#fff" className="h-2 w-4" />
          {languages.map((language) => {
            return (
              <MenubarItem
                key={language}
                className="w-[40px] cursor-pointer rounded-lg hover:bg-base-100"
                onClick={() => handleChange(language)}
              >
                <Typography variant={'label'}>
                  {language === 'KK' ? 'KZ' : language.toUpperCase()}
                </Typography>
              </MenubarItem>
            )
          })}
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}
