// src/context/SigninContext.tsx
import {
  ISigninCompanyDTO,
  ISigninPersonalDTO,
} from '@/entities/signin/types'
import { useSigninCompanyMutation } from '@/shared/api/signinCompany'
import {
  useSendOtpMutation,
  useSigninPersonalMutation,
} from '@/shared/api/signinPersonal'
import { useToast } from '@/shared/ui/Toast/useToast'
import Cookies from 'js-cookie'
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'

interface SigninContextProps {
  // Company
  judicialData: ISigninCompanyDTO
  setCompanyData: React.Dispatch<
    React.SetStateAction<ISigninCompanyDTO>
  >
  handleCompanySignin: (data: ISigninCompanyDTO) => Promise<void>

  // Personal
  individualData: ISigninPersonalDTO
  setPersonalData: React.Dispatch<
    React.SetStateAction<ISigninPersonalDTO>
  >
  handlePersonalSignin: (data: ISigninPersonalDTO) => Promise<void>
  handleSendOtp: (phone: string) => Promise<void>

  // Common states
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  codeSent: boolean
  setCodeSent: React.Dispatch<React.SetStateAction<boolean>>
  canResend: boolean
  timeLeft: number
}

const SigninContext = createContext<SigninContextProps | undefined>(
  undefined
)

export const SigninProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [judicialData, setCompanyData] = useState<ISigninCompanyDTO>({
    email: '',
    password: '',
  })
  const [individualData, setPersonalData] =
    useState<ISigninPersonalDTO>({
      phone: '',
      verification_code: '',
    })
  const [loading, setLoading] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [canResend, setCanResend] = useState(true)

  const { toast } = useToast()
  const navigate = useNavigate()
  const [signinCompany] = useSigninCompanyMutation()
  const [signinPersonal] = useSigninPersonalMutation()
  const [sendOtp] = useSendOtpMutation()

  // Timer hook for 30-second countdown
  const { seconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => setCanResend(true), // Enable resend when timer expires
  })

  const handleCompanySignin = useCallback(
    async (data: ISigninCompanyDTO) => {
      setLoading(true)
      try {
        const response = await signinCompany(data).unwrap()
        Cookies.set('access_token', response.access_token, {
          expires: 30,
          secure: true,
          sameSite: 'Strict',
        })
        setCompanyData({ email: '', password: '' })
        navigate('/')
      } catch (error) {
        console.error('Company sign-in failed:', error)
      } finally {
        setLoading(false)
      }
    },
    [signinCompany, navigate]
  )

  const handlePersonalSignin = useCallback(
    async (data: ISigninPersonalDTO) => {
      setLoading(true)
      try {
        const response = await signinPersonal(data).unwrap()
        Cookies.set('access_token', response.access_token, {
          expires: 30,
          secure: true,
          sameSite: 'Strict',
        })
        setPersonalData({ phone: '', verification_code: '' })
        navigate('/')
      } catch (error: any) {
        if (error?.data?.message) {
          toast({
            variant: 'error',
            title: 'Ошибка авторизации',
            description: error.data.message,
          })
        } else
          toast({
            variant: 'error',
            title: 'Что-то пошло не так',
            description: error.data.message,
          })
      } finally {
        setLoading(false)
      }
    },
    [signinPersonal, navigate]
  )

  const handleSendOtp = useCallback(
    async (phone: string) => {
      if (!canResend) return

      setLoading(true)
      try {
        await sendOtp(phone).unwrap()
        setCodeSent(true)
        setCanResend(false) // Disable resend until cooldown completes

        // Restart the timer for a 30-second countdown
        const expiryTime = new Date()
        expiryTime.setSeconds(expiryTime.getSeconds() + 30)
        restart(expiryTime)
      } catch (error: any) {
        if (error?.data?.message) {
          toast({
            title: 'Ошибка авторизации',
            description: error.data.message,
          })
        } else
          toast({
            title: 'Что-то пошло не так',
            description: error.data.message,
          })
      } finally {
        setLoading(false)
      }
    },
    [sendOtp, canResend, restart]
  )

  return (
    <SigninContext.Provider
      value={{
        judicialData,
        setCompanyData,
        handleCompanySignin,
        individualData,
        setPersonalData,
        handlePersonalSignin,
        handleSendOtp,
        loading,
        setLoading,
        codeSent,
        setCodeSent,
        canResend,
        timeLeft: seconds, // Remaining time for resend availability
      }}
    >
      {children}
    </SigninContext.Provider>
  )
}

export const useSignin = (): SigninContextProps => {
  const context = useContext(SigninContext)
  if (!context) {
    throw new Error('useSignin must be used within an SigninProvider')
  }
  return context
}
