import IconPlus from '@/shared/assets/icons/icon_plus.svg?react'
import { cn } from '@/shared/utils/common'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import * as React from 'react'
import { ReactNode } from 'react'

import { Typography } from '../Typography'

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={className}
    {...props}
  />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<
    typeof AccordionPrimitive.Trigger
  > & {
    iconSvg?: ReactNode
    iconPosition?: 'left' | 'right'
  }
>(
  (
    {
      className,
      children,
      iconSvg,
      iconPosition = 'right',
      ...props
    },
    ref
  ) => (
    <AccordionPrimitive.Header className="flex w-full">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          // '[&[data-state=open]>div]:text-blue-600',
          'flex flex-1 items-center justify-between gap-2',
          '[&[data-state=open]>div>div]:rotate-90',
          '[&[data-state=open]>svg]:rotate-90',
          className
        )}
        {...props}
      >
        {iconSvg && iconPosition === 'left' ? iconSvg : null}
        {/* Display item number here */}
        <Typography className="flex flex-1 flex-row gap-2 text-start">
          {children}
        </Typography>
        {iconSvg && iconPosition === 'right' ? iconSvg : null}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
)
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'overflow-auto text-sm transition-all',
      'data-[state=closed]:animate-accordion-up',
      'data-[state=open]:animate-accordion-down'
    )}
    {...props}
  >
    <div className={cn('py-3', className)}>{children}</div>
  </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

export {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
}
