import { cn } from '@/shared/utils/common'
import { Cross2Icon } from '@radix-ui/react-icons'
import * as ToastPrimitives from '@radix-ui/react-toast'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const ToastProvider = ToastPrimitives.Provider

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      `fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse
      sm:bottom-[116px] sm:right-4 sm:top-auto sm:flex-col md:max-w-[490px]`,
      className
    )}
    {...props}
  />
))
ToastViewport.displayName = ToastPrimitives.Viewport.displayName

const toastVariants = cva(
  `
  group pointer-events-auto
  max-w-[490px]
  relative flex w-full items-center justify-between space-x-1 overflow-hidden 
  rounded-[16px] border-[1px] p-4 pl-[46px] shadow-btn-shadow
  transition-all

  data-[swipe=cancel]:translate-x-0 
  data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] 
  data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] 
  data-[swipe=move]:transition-none 

  data-[state=open]:animate-in 
  data-[state=closed]:animate-out 
  data-[swipe=end]:animate-out 

  data-[state=closed]:fade-out-80 
  data-[state=closed]:slide-out-to-right-full 
  data-[state=open]:slide-in-from-top-full 
  data-[state=open]:sm:slide-in-from-bottom-full`,
  {
    variants: {
      variant: {
        default: 'border bg-background text-foreground',
        error:
          'bg-[#FFFBFB] !pl-[46px] border-[#FBC3C3] shadow-shadow7',
        success:
          'border-additional-green-2 bg-[#FFFFFF] text-base-950',
        destructive:
          'destructive group border-destructive bg-destructive text-destructive-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)
const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants> & {
      icon?: React.ReactNode
    }
>(({ className, variant = 'default', icon, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className={cn(
        toastVariants({ variant }),
        className,
        'flex items-start justify-start gap-2 pl-3'
      )}
      {...props}
    >
      {icon && icon}
      {props.children ?? null}
    </ToastPrimitives.Root>
  )
})
Toast.displayName = ToastPrimitives.Root.displayName

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      `hover:bg-secondary focus:ring-ring
      group-[.destructive]:border-muted/40
      group-[.destructive]:hover:border-destructive/30
      group-[.destructive]:hover:bg-destructive
      group-[.destructive]:hover:text-destructive-foreground
      group-[.destructive]:focus:ring-destructive inline-flex h-8 shrink-0
      grow items-center justify-center self-center rounded-md border
      bg-transparent px-3 text-sm font-medium transition-colors
      focus:outline-none focus:ring-1 disabled:pointer-events-none
      disabled:opacity-50`,
      className
    )}
    {...props}
  />
))
ToastAction.displayName = ToastPrimitives.Action.displayName

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      `text-foreground/50 hover:text-foreground absolute right-1 top-1
      rounded-md p-1 opacity-0 transition-opacity focus:opacity-100
      focus:outline-none focus:ring-1 group-hover:opacity-100
      group-[.destructive]:text-red-300
      group-[.destructive]:hover:text-red-50
      group-[.destructive]:focus:ring-red-400
      group-[.destructive]:focus:ring-offset-red-600`,
      className
    )}
    toast-close=""
    {...props}
  >
    <Cross2Icon className="h-4 w-4" />
  </ToastPrimitives.Close>
))
ToastClose.displayName = ToastPrimitives.Close.displayName

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cn(
      'text-[14px] font-semibold leading-[20px]',
      className
    )}
    {...props}
  />
))
ToastTitle.displayName = ToastPrimitives.Title.displayName

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn(
      'text-[14px] leading-[20px] text-base-950',
      className
    )}
    {...props}
  />
))
ToastDescription.displayName = ToastPrimitives.Description.displayName

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>

type ToastActionElement = React.ReactElement<typeof ToastAction>

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
}
